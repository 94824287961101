import { PlusIcon, RemoveIcon } from '../../assets/svg';
import { FieldValidator } from 'final-form';
import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import styled, { CSSProp } from 'styled-components';
import { DEVICE, TYPOGRAPHY } from '../../assets/styles';

export function FieldArrayWrapper<T>({
  className,
  fieldsArrayName,
  validate,
  renderItem,
  itemCSS,
  addBtnTitle,
  minFieldsCount = 1,
  maxFieldsCount,
  closeButtonCSS,
  isAddButtonAlwaysRendered = false,
  defaultValue,
}: FieldsArrayWrapperProps<T>) {
  const { mutators } = useForm();
  const { values } = useFormState();
  const fieldsCount = values[fieldsArrayName]?.length || 0;
  const hasAddButton = (maxFieldsCount && maxFieldsCount > fieldsCount) || isAddButtonAlwaysRendered;

  return (
    <Wrapper className={className}>
      <FieldsWrapper>
        <FieldArray name={fieldsArrayName} validate={validate}>
          {({ fields }) =>
            fields.map((fieldName, fieldIndex) => (
              <FieldsArrayItem key={fieldName} $CSS={itemCSS}>
                {renderItem(fieldName, fieldIndex)}
                {fieldsCount > minFieldsCount && (
                  <DeleteFieldButton onClick={() => fields.remove(fieldIndex)} type="button" $CSS={closeButtonCSS}>
                    <RemoveIcon fill="var(--color12)" />
                  </DeleteFieldButton>
                )}
              </FieldsArrayItem>
            ))
          }
        </FieldArray>
      </FieldsWrapper>

      {hasAddButton && (
        <AddFieldButton type="button" onClick={() => mutators.push(fieldsArrayName, defaultValue)}>
          <StyledPlusIcon />
          {addBtnTitle || 'Add'}
        </AddFieldButton>
      )}
    </Wrapper>
  );
}

export interface FieldsArrayWrapperProps<T = any> {
  className?: string;
  fieldsArrayName: string;
  validate?: FieldValidator<T[]>;
  renderItem: (fieldName: string, fieldIndex: number) => JSX.Element;
  itemCSS?: CSSProp;
  addBtnTitle?: string;
  maxFieldsCount?: number;
  minFieldsCount?: number;
  canBeEmpty?: boolean;
  isAddButtonAlwaysRendered?: boolean;
  closeButtonCSS?: CSSProp;
  defaultValue?: T;
}

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FieldsWrapper = styled.div`
  margin-bottom: 24px;
`;

const FieldsArrayItem = styled.div<{ $CSS?: CSSProp }>`
  position: relative;
  display: flex;
  align-items: flex-start;

  &:not(:last-child) {
    margin-bottom: 50px;
  }
  ${({ $CSS }) => $CSS}
`;

const DeleteFieldButton = styled.button<{ $CSS?: CSSProp }>`
  width: 24px;
  height: 24px;
  margin-left: 16px;
  flex-shrink: 0;
  margin-top: 50px;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;
  }

  @media ${DEVICE.laptop} {
    position: absolute;
    left: calc(100% + 16px);
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-top: 45px;
  }

  ${({ $CSS }) => $CSS}
`;

const AddFieldButton = styled.button`
  ${TYPOGRAPHY.bodyMedium16}
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--color1);
  cursor: pointer;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 8px;
  stroke: var(--color1);
`;
