import * as React from 'react';
import { SVGProps } from 'react';

const SvgGoodIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.667 16c0 7.364 5.97 13.334 13.333 13.334 7.364 0 13.333-5.97 13.333-13.334 0-7.363-5.97-13.333-13.333-13.333C8.636 2.667 2.667 8.637 2.667 16ZM11.667 16.167H20"
      stroke="#F17979"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.833 12 20 16.167l-4.167 4.166"
      stroke="#F17979"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgGoodIcon;
