import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeCycleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      clipPath="url(#large-cycle-icon_svg__a)"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M45 35a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15v0Zm-22.5 7.5a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z" />
      <path d="M32.5 10h3a2.5 2.5 0 0 1 2.5 1.95l7 30.55" />
      <path d="M10 22.5h17.5l12.225-2.825M15 42.5l5-20M17.5 32.8l11.45 2.05a2.501 2.501 0 0 0 2-.5l10.25-8.3" />
    </g>
    <defs>
      <clipPath id="large-cycle-icon_svg__a">
        <path fill="#fff" transform="matrix(-1 0 0 1 60 0)" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgLargeCycleIcon;
