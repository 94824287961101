import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useEffect, useState } from 'react';
import { useFetchAnswer } from '../MainPage/hooks';
import { Co2FootprintVO } from 'vo';
import { TipsToReduce, TopContainer } from 'pages/MainPage/components/Result/components';
import { MainLayout } from 'layouts';
import { Co2FootprintsVOs } from 'common/types';
import { Loader } from '@net0-calc/common/components/ui';
import { DEVICE } from '@net0-calc/common/assets/styles';

export const ResultsPage = () => {
  const { id } = useParams<{ id: string }>();

  const [answer, { isLoading }] = useFetchAnswer(id ?? null);

  const unit = 'kg';

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState<Co2FootprintsVOs>({
    gettingToOffice: Co2FootprintVO.create(0, unit),
    workFromHome: Co2FootprintVO.create(0, unit),
    businessTravel: Co2FootprintVO.create(0, unit),
  });
  const co2FootprintSumVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs || {}));

  useEffect(() => {
    if (answer) {
      const { co2Footprint } = answer.answers;
      const { businessTravelKg, gettingToOfficeKg, workFromHomeKg } = co2Footprint;

      setCo2FootprintsVOs({
        businessTravel: Co2FootprintVO.create(businessTravelKg, unit),
        gettingToOffice: Co2FootprintVO.create(gettingToOfficeKg, unit),
        workFromHome: Co2FootprintVO.create(workFromHomeKg, unit),
      });
    }
  }, [answer]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader size={80} color="var(--color2)" />
      </LoaderWrapper>
    );
  }

  return (
    <MainLayout isBackButtonVisible={false}>
      <Root>
        <TopContainer
          reportYear={answer?.answers.formValues.userReportYear || 0}
          co2FootprintSumVO={co2FootprintSumVO}
          co2FootprintsVOs={co2FootprintsVOs}
        />
        {/*<StyledTipsToReduce />*/}
      </Root>
    </MainLayout>
  );
};
const Root = styled.div`
  width: 100%;
  @media ${DEVICE.laptop} {
    padding-bottom: 25px;
  }
`;

const StyledTipsToReduce = styled(TipsToReduce)`
  margin-bottom: 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
`;
