import * as React from 'react';
import { SVGProps } from 'react';
const SvgRomaniaFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#romania-flag-icon_svg__a)">
      <path fill="#FFDA2C" d="M0 2.857h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16 2.857h8v17h-8v-17Z" fill="#F93939" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.857h8v17H0v-17Z" fill="#1A47B8" />
    </g>
    <defs>
      <clipPath id="romania-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 2.857)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRomaniaFlagIcon;
