import { WizardPageProps } from 'pages/MainPage/components/WizardForm/WizardForm';
import { ReactElement, ReactNode } from 'react';
import { Co2FootprintVO } from 'vo';

export type MapPoint = {
  lat: number;
  lng: number;
};

export enum MainTransport {
  WALKING = 'WAlKING',
  BIKING = 'BIKING',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  CARPOOLING = 'CARPOOLING',
  DRIVING_ALONE = 'DRIVING_ALONE',
}

export enum TravelTransportType {
  AIRPLANE = 'AIRPLANE',
  TRAIN = 'TRAIN',
  CAR = 'CAR',
  OTHER_TRANSPORT = 'OTHER_TRANSPORT',
}

export enum FuelKind {
  GASOLINE = 'GASOLINE',
  DIESEL = 'DIESEL',
  HYBRID = 'HYBRID',
  ELECTRIC_VEHICLE = 'ELECTRIC_VEHICLE',
}

export enum DistanceUnit {
  MILES = 'MILES',
  KILOMETERS = 'KILOMETERS',
}

export enum VehicleFuelEfficiencyKInd {
  MILES_PER_GALLON = 'MILES_PER_GALLON',
  KILOMETERS_PER_LITTER = 'KILOMETERS_PER_LITTER',
}

export enum RenewableAtHomeAnswer {
  YES = 'YES',
  YES_PARTIALLY = 'YES_PARTIALLY',
  NO = 'NO',
  DONT_NOW = 'DONT_NOW',
}

export enum Device {
  LAPTOP = 'LAPTOP',
  MONITOR = 'MONITOR',
  PRINTER = 'PRINTER',
  LAMP = 'LAMP',
  SPACE_HEATER = 'SPACE_HEATER',
  OTHER = 'OTHER',
}

export enum AirlineTicketClass {
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
  FIRST_CLASS = 'FIRST_CLASS',
  PRIVATE_JET = 'PRIVATE_JET',
}

export enum FlightDurationKind {
  SHORT = 'SHORT',
  MID_RANGE = 'MID_RANGE',
  LONG_HAUL = 'LONG_HAUL',
}

export enum TypeOfTrip {
  ONE_WAY = 'ONE_WAY',
  RETURN = 'RETURN',
}
export interface Location {
  mainAddress: string;
  fullAddress: string;
  mapPoint: MapPoint;
}
export interface DevicesFieldArrayValues {
  device?: Device;
  numberOfDevices?: number;
  usageTimePerWeek?: number;
}

export interface BusinessTravelSimpleFiledArrayValues {
  businessTravelTransportType?: TravelTransportType;
  numberOfSingleShortFlights?: number;
  numberOfSingleMidRangeFlights?: number;
  numberOfSingleLongHaulFlight?: number;
  numberOfBusinessTrip?: number;
  averageBusinessTripDistance?: number;
  flightServiceClass?: AirlineTicketClass;
}

export interface BusinessTravelAdvancedFiledArrayValues {
  businessTravelTransportType?: TravelTransportType;
  flightServiceClass?: AirlineTicketClass;
  placeOfDeparture?: Location;
  placeOfArrival?: Location;
  typeOfTrip?: TypeOfTrip;
}

export interface CalculatorFormValues {
  userEmail?: string;
  userLocation?: Location;
  userReportYear?: number;
  numberOfAnnuallyVacationDays?: number;

  gettingToOfficeTransportType?: MainTransport;
  transportFuelKind?: FuelKind;
  numberOfCompanions?: number;
  isUsingLKQFuel?: boolean;

  numberOfDaysOfWeekWhenAnEmployeeCommuteToWork?: number;
  distanceFromHomeToOffice?: number;
  distanceFromHomeToOfficeUnit?: DistanceUnit;
  averageFuelEfficiencyOfYourVehicle?: number;
  averageFuelEfficiencyOfYourVehicleKind?: VehicleFuelEfficiencyKInd;

  daysPerWeekWorkFromHome?: number;
  haveRenewableElectricityAtYourHome?: RenewableAtHomeAnswer;
  percentageOfRenewableElectricityAtYourHome?: number;

  devicesForYourWorkFromHome?: (DevicesFieldArrayValues | undefined)[];
  additionalDevicesForYourWorkFromHome?: (DevicesFieldArrayValues | undefined)[];

  isDetailedBusinessTravel?: boolean;

  useSAPConcur?: UseSAPConcurAnswer;

  simpleBusinessTravelValues?: (BusinessTravelSimpleFiledArrayValues | undefined)[];
  advancedBusinessTravelValues?: (BusinessTravelAdvancedFiledArrayValues | undefined)[];
}

export enum StepName {
  WELCOME = 'WELCOME',
  INFORMATION = 'INFORMATION',
  GETTING_TO_OFFICE = 'GETTING_TO_OFFICE',
  WORK_WAY_INFO = 'WORK_WAY_INFO',
  WORK_FROM_HOME = 'WORK_FROM_HOME',
  DEVICES = 'DEVICES',
  USE_OF_SAP_CONCUR = 'USE_OF_SAP_CONCUR',
  BUSINESS_TRAVEL = 'BUSINESS_TRAVEL',
}

export interface StepItemProps<T> {
  name: StepName;
  title: string;
  icon: ReactNode;
  page: ReactElement<WizardPageProps<T>>;
}

export enum LoadingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export enum Language {
  ENGLISH = 'en',
  DEUTSCH = 'de',
  FRANÇAIS = 'fr',
  FRENCH_CA = 'fr_CA',
  ITALIANO = 'it',
  BOSNIAN = 'bs_BA',
  CZECK = 'cs',
  CHINE_CN = 'zh_CN',
  CHINE_TW = 'zh_TW',
  DUTCH = 'nl',
  HUNGARIAN = 'hu',
  POLISH = 'pl',
  PORTUGUESE = 'pt',
  ROMANIAN = 'ro',
  SLOVAK = 'sk',
  SLOVENIAN = 'sl',
  SPANISH_419 = 'es_419',
  SWEDISH = 'sv',
  UKRAINIAN = 'uk',
}

export type Co2FootprintsVOs = {
  gettingToOffice: Co2FootprintVO;
  workFromHome: Co2FootprintVO;
  businessTravel: Co2FootprintVO;
};

export enum UseSAPConcurAnswer {
  YES = 'YES',
  PARTIALLY = 'PARTIALLY',
  NO = 'NO',
}
