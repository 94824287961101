import { CalculatorFormValues, Co2FootprintsVOs, StepName } from 'common/types';
import { useState } from 'react';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { co2FootprintCalculator } from 'co2-footprint-calculator';
import calculationBusinessTravels from 'co2-footprint-calculator/calculation-business-travels';

export const useStepCalculationHandlers = ({ formValues }: { formValues: CalculatorFormValues }) => {
  /**
   * We rely on that fields of values to be defined in at each step,
   * because this calculation will be execute after filling inputs on step
   */

  const [co2FootprintsVOs, setCo2FootprintsVOs] = useState<Co2FootprintsVOs>({
    gettingToOffice: Co2FootprintVO.create(0, 'kg'),
    workFromHome: Co2FootprintVO.create(0, 'kg'),
    businessTravel: Co2FootprintVO.create(0, 'kg'),
  });
  const updateCo2FootprintsVOs = (statePart: Partial<typeof co2FootprintsVOs>) => {
    setCo2FootprintsVOs(currentState => ({
      ...currentState,
      ...statePart,
    }));
  };

  const stepCalculationHandlers: Record<StepName, () => void> = {
    [StepName.WELCOME]: utils.noop,
    [StepName.INFORMATION]: utils.noop,
    [StepName.GETTING_TO_OFFICE]: utils.noop,
    [StepName.WORK_WAY_INFO]: () => {
      const gettingToOfficeFootprintVO = co2FootprintCalculator.calculateGettingToOffice({
        averageFuelEfficiencyOfYourVehicle: formValues?.averageFuelEfficiencyOfYourVehicle,
        averageFuelEfficiencyOfYourVehicleKind: formValues?.averageFuelEfficiencyOfYourVehicleKind,
        distanceFromHomeToOffice: formValues?.distanceFromHomeToOffice,
        distanceFromHomeToOfficeUnit: formValues?.distanceFromHomeToOfficeUnit,
        gettingToOfficeTransportType: formValues?.gettingToOfficeTransportType,
        numberOfCompanions: formValues?.numberOfCompanions,
        numberOfDaysOfWeekWhenAnEmployeeCommuteToWork: formValues?.numberOfDaysOfWeekWhenAnEmployeeCommuteToWork,
        transportFuelKind: formValues?.transportFuelKind,
        numberOfAnnuallyVacationDays: formValues?.numberOfAnnuallyVacationDays,
        userReportYear: formValues?.userReportYear,
      });

      updateCo2FootprintsVOs({ gettingToOffice: gettingToOfficeFootprintVO });
    },
    [StepName.WORK_FROM_HOME]: utils.noop,
    [StepName.DEVICES]: () => {
      const workFromHomeFootprintVO = co2FootprintCalculator.calculateWorkFromHome({
        additionalDevicesForYourWorkFromHome: formValues?.additionalDevicesForYourWorkFromHome,
        daysPerWeekWorkFromHome: formValues?.daysPerWeekWorkFromHome,
        devicesForYourWorkFromHome: formValues?.devicesForYourWorkFromHome,
        haveRenewableElectricityAtYourHome: formValues?.haveRenewableElectricityAtYourHome,
        percentageOfRenewableElectricityAtYourHome: formValues?.percentageOfRenewableElectricityAtYourHome,
        numberOfAnnuallyVacationDays: formValues?.numberOfAnnuallyVacationDays,
        userReportYear: formValues?.userReportYear,
      });

      updateCo2FootprintsVOs({ workFromHome: workFromHomeFootprintVO });
    },
    [StepName.USE_OF_SAP_CONCUR]: utils.noop,
    [StepName.BUSINESS_TRAVEL]: () => {
      const businessTravelFootprintVO = calculationBusinessTravels({
        advancedBusinessTravelValues: formValues?.advancedBusinessTravelValues,
        simpleBusinessTravelValues: formValues?.simpleBusinessTravelValues,
        isDetailedBusinessTravel: formValues?.isDetailedBusinessTravel,
      });

      updateCo2FootprintsVOs({ businessTravel: businessTravelFootprintVO });
    },
  };

  const handleStepCalculations = (currentStepName: StepName) => {
    const handle = stepCalculationHandlers[currentStepName];
    handle();
  };

  return { co2FootprintsVOs, handleStepCalculations, updateCo2FootprintsVOs };
};
