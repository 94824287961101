import styled from 'styled-components/macro';
import { FC, useState } from 'react';
import { TYPOGRAPHY, DEVICE } from '../../../assets/styles';
import { FieldInputProps } from 'react-final-form';
import { CalendarModal } from './components';
import { CalendarIcon } from '../../../assets/svg';
import { TimeUtil } from '../../../utils';
import { SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER } from '../../../constants/date';
import { useTranslation } from 'react-i18next';

interface DoubleCalendarProps extends FieldInputProps<[Date, Date]> {
  className?: string;
  firstLabel: string;
  secondLabel: string;
  locale: string;
}

export const DoubleCalendar: FC<DoubleCalendarProps> = ({
  className,
  firstLabel,
  secondLabel,
  defaultValue,
  value,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedInputLabel, setSelectedInputLabel] = useState('');

  const [isVisible, setIsVisible] = useState(false);
  const closeCalendarModal = () => setIsVisible(false);
  const toggleCalendarModal = () => setIsVisible(currentValue => !currentValue);

  const handleClickOnInput = (selectedLabel: string) => () => {
    setSelectedInputLabel(selectedLabel);
    toggleCalendarModal();
  };

  const changeCalendarValue = (newValue: [Date, Date]) => {
    onChange(newValue);
    closeCalendarModal();
  };

  return (
    <Root className={className}>
      <InputsWrapper $isVisible={isVisible}>
        <InputContainer onClick={handleClickOnInput(firstLabel)}>
          <Label>{firstLabel}</Label>
          <InputWrap $isFocused={isVisible}>
            <CalendarIconWrap $isFocused={isVisible}>
              <CalendarIcon />
            </CalendarIconWrap>
            <InputInnerText $isPlaceholder={!value?.length}>
              {(value &&
                value.length > 0 &&
                TimeUtil.getFormatted(value[0], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
                (defaultValue &&
                  defaultValue.length > 1 &&
                  TimeUtil.getFormatted(defaultValue[0], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
                t('components.doubleCalendar.selectDate')}
            </InputInnerText>
          </InputWrap>
        </InputContainer>
        <InputContainer onClick={handleClickOnInput(secondLabel)}>
          <Label>{secondLabel}</Label>
          <InputWrap $isFocused={isVisible}>
            <CalendarIconWrap $isFocused={isVisible}>
              <CalendarIcon />
            </CalendarIconWrap>
            <InputInnerText $isPlaceholder={!value?.length}>
              {(value &&
                value.length > 1 &&
                TimeUtil.getFormatted(value[1], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
                (defaultValue &&
                  defaultValue.length > 1 &&
                  TimeUtil.getFormatted(defaultValue[1], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
                t('components.doubleCalendar.selectDate')}
            </InputInnerText>
          </InputWrap>
        </InputContainer>
      </InputsWrapper>
      <CalendarModal
        isVisible={isVisible}
        onCloseModal={closeCalendarModal}
        selectedInputLabel={selectedInputLabel}
        {...props}
        defaultValue={defaultValue}
        value={value}
        onChange={changeCalendarValue}
      />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const InputsWrapper = styled.div<{ $isVisible: boolean }>`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InputContainer = styled.div`
  width: 100%;

  @media ${DEVICE.desktop} {
    max-width: 302px;
  }

  &:first-child {
    margin-bottom: 20px;

    @media ${DEVICE.desktop} {
      margin-bottom: 0px;
    }
  }
`;

const Label = styled.label`
  ${TYPOGRAPHY.captionRegular14}
  display: block;
  color: var(--color9);
  margin-bottom: 8px;
`;

const InputWrap = styled.div<{ $isFocused?: boolean }>`
  width: 100%;
  height: 64px;
  padding: 18px;

  display: flex;
  align-items: center;

  border: 2px solid ${({ $isFocused }) => ($isFocused ? 'var(--color2)' : 'var(--color10)')};
  border-radius: 15px;

  cursor: pointer;
  transition: all 0.2s;
`;

const InputInnerText = styled.span<{ $isPlaceholder?: boolean }>`
  ${TYPOGRAPHY.bodyRegular16}
  width: 80%;
  color: ${({ $isPlaceholder }) => ($isPlaceholder ? 'var(--color9)' : 'var(--color6)')};
`;

const CalendarIconWrap = styled.div<{ $isFocused?: boolean }>`
  margin-right: 10px;
  & svg path {
    stroke: ${({ $isFocused }) => ($isFocused ? 'var(--color2)' : 'var(--color6)')};
  }
`;
