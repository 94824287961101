import { combineReducers } from '@reduxjs/toolkit';
import * as dictionaryDuck from './dictionary';

export const reducer = combineReducers({
  dictionary: dictionaryDuck.reducer,
});

export const actions = {
  dictionary: dictionaryDuck.actions,
};

export const selectors = {
  dictionary: dictionaryDuck.selectors,
};
