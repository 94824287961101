import * as React from 'react';
import { SVGProps } from 'react';
const SvgHungaryFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#hungary-flag-icon_svg__a)">
      <path fill="#fff" d="M0 3h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 14.333h24V20H0v-5.667Z" fill="#249F58" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3h24v5.667H0V3Z" fill="#F93939" />
    </g>
    <defs>
      <clipPath id="hungary-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgHungaryFlagIcon;
