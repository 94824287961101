import { CalculatorFormValues } from 'common/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { StepTitle } from '@net0-calc/common/components/ui';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { ClockIcon } from '@net0-calc/common/assets/svg';
import { WizardPageProps } from '@net0-calc/common/types';

const translationPrefix = 'net0-commute.pages.welcome';

export const WelcomePage: React.FC<WizardPageProps<CalculatorFormValues>> = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <StepTitle>{t(translationPrefix + '.title')}</StepTitle>
      <div>
        <Description>{t(translationPrefix + '.description1')}</Description>
        <Description>{t(translationPrefix + '.description2')}</Description>
      </div>
      <EstimationSection>
        <StyledClockIcon />
        <Text>{t(translationPrefix + '.estimation')}</Text>
      </EstimationSection>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
    height: auto;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const Text = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
`;

const Description = styled(Text)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 16px;
  }
`;

const EstimationSection = styled.div`
  display: flex;
  align-items: center;
`;

const StyledClockIcon = styled(ClockIcon)`
  margin-right: 10px;
  stroke: var(--color2);
`;
