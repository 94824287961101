type Options = {
  tLabel: string; // label for ton
  kgLabel: string; // label for kg
  gLabel: string; // label for gram
};

export const formatWeightValue = (valueKg: number, options?: Options) => {
  const { tLabel = 't', kgLabel = 'kg', gLabel = 'g' } = options ?? {};

  const valueAbs = Math.abs(valueKg);
  if (valueAbs > 999) {
    return `${+(Math.sign(valueKg) * +(Math.abs(valueKg) / 1000).toFixed(1)).toFixed(2)} ${tLabel}`;
  }
  if (valueAbs < 1) {
    return `${(Math.sign(valueKg) * +(Math.abs(valueKg) * 1000).toFixed(1)).toFixed(2)} ${gLabel}`;
  }
  return `${+(Math.sign(valueKg) * Math.abs(valueKg)).toFixed(2)} ${kgLabel}`;
};
