import { TYPOGRAPHY } from '../../../assets/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  hints: string[];
};

export const Hints: React.FC<Props> = ({ className, hints }) => {
  if (!hints.length) {
    return null;
  }
  return (
    <Root className={className}>
      {hints.map(hint => {
        return (
          <HintContainer key={hint}>
            <HintText dangerouslySetInnerHTML={{ __html: hint }}></HintText>
          </HintContainer>
        );
      })}
    </Root>
  );
};

const Root = styled.ul`
  width: 100%;
`;

const HintContainer = styled.li`
  transform-origin: top;
  overflow: hidden;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  @keyframes scaleAnimation {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  animation: scaleAnimation 0.5s ease-in;
`;
const HintText = styled.p`
  height: auto;

  ${TYPOGRAPHY.captionRegular14}
  color: var(--color11);
`;
