import * as React from 'react';
import { SVGProps } from 'react';
const SvgChinaFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#china-flag-icon_svg__a)">
      <path fill="#F93939" d="M0 3h24v17H0z" />
      <path
        d="M21.714 3H2.286A2.276 2.276 0 0 0 0 5.267v12.466A2.276 2.276 0 0 0 2.286 20h19.428A2.276 2.276 0 0 0 24 17.733V5.267A2.276 2.276 0 0 0 21.714 3Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.284 11.194-1.68.876.32-1.855L3.566 8.9l1.878-.268.84-1.69.838 1.69L9 8.9l-1.36 1.315.322 1.854-1.678-.875ZM10.286 6.4h1.143v1.133h-1.143V6.4Zm1.143 2.267h1.143V9.8h-1.143V8.667Zm0 2.266h1.143v1.134h-1.143v-1.134ZM10.286 13.2h1.143v1.133h-1.143V13.2Z"
        fill="#FFDA2C"
      />
    </g>
    <defs>
      <clipPath id="china-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgChinaFlagIcon;
