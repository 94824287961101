import styled, { CSSProp } from 'styled-components/macro';
import { TYPOGRAPHY } from '../../../assets/styles';
import { FC, SVGProps, useEffect } from 'react';
import { gsap } from 'gsap';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text: string;
  className?: string;
  icon?: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  textCSS?: CSSProp;
}

export const RadioButton: FC<RadioButtonProps> = ({ id, text, className, icon: Icon, textCSS, ...props }) => {
  const radioBulletId = 'radio-bullet' + props?.value ? '__' + props.value : '';

  useEffect(() => {
    if (props.checked) {
      gsap.fromTo(
        `#${radioBulletId}`,
        {
          scaleX: 0,
          scaleY: 0,
          opacity: 0,
        },
        {
          scaleX: 1,
          scaleY: 1,
          opacity: 1,
          duration: 0.35,
        },
      );
    }
  }, [props.checked]);

  return (
    <Root className={className}>
      <Label tabIndex={0}>
        <StyledInput tabIndex={-1} id={id} type="radio" {...props} />
        <RadioButtonWrap>
          {props.checked && (Icon ? <Icon stroke="var(--color2)" /> : <RadioButtonBullet id={radioBulletId} />)}
        </RadioButtonWrap>
        <Title $CSS={textCSS}>{text}</Title>
      </Label>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledInput = styled.input`
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
  outline: none;
`;

const Label = styled.label`
  display: flex;
  cursor: pointer;
  outline: none;
`;

const RadioButtonWrap = styled.div`
  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 12px;

  border: 1px solid var(--color2);
  border-radius: 8px;

  background-color: var(--color5);
`;

const RadioButtonBullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: var(--color2);
`;

const Title = styled.span<{ $CSS?: CSSProp }>`
  ${TYPOGRAPHY.bodyMedium16}
  color: var(--color6);

  ${({ $CSS }) => $CSS}
`;
