import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { Language } from 'common/types';
import { StorageService } from 'services';
import { RootState } from 'store';

interface DictionaryState {
  isLoadingDictionary: boolean;
  language: Language;
}
const language = (StorageService.getItem('language') || Language.ENGLISH) as Language;

const initialState: DictionaryState = { isLoadingDictionary: false, language };

const slice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoadingDictionary = action.payload;
    },
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
  },
});

const { reducer, actions } = slice;

const selectors = {
  selectIsDictionaryLoading: createSelector(
    [(state: RootState) => state.dictionary.isLoadingDictionary],
    isLoading => isLoading,
  ),
  selectLanguage: createSelector([(state: RootState) => state.dictionary.language], lang => lang),
};

export { reducer, selectors, actions };
