import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { StepItemProps } from '@net0-calc/common/types';
import { Fragment } from 'react';
import styled, { css } from 'styled-components/macro';
import { useAnimateStepBar } from './helpers/useAnimateStepBar';

interface StepBarProps<T, K> {
  steps: StepItemProps<T, K>[];
  currentStepIndex: number;
  onStepClick: (stepIndex: number) => void;
}

export const StepBar = <T, K>({ steps, currentStepIndex, onStepClick }: StepBarProps<T, K>) => {
  useAnimateStepBar<T, K>(steps);
  const stepWithIconCount = steps.filter(step => step.icon).length;

  const handleItemClick = (index: number) => () => {
    if (index < currentStepIndex) {
      onStepClick(index);
    }
  };

  return (
    <Root>
      {steps.map((step, index) => {
        if (step.icon) {
          return (
            <Fragment key={index}>
              <StepItem
                id={'step-' + (index - 1)}
                $isComplete={currentStepIndex > index}
                $isLast={steps.length === index + 1}
                onClick={handleItemClick(index)}>
                <StepIconWrap
                  id={'step-icon-' + (index - 1)}
                  $isCurrent={index === currentStepIndex}
                  $isCompleted={index < currentStepIndex}>
                  {step.icon}
                </StepIconWrap>
                <StepLine $isComplete={currentStepIndex > index} $isLast={stepWithIconCount === index} />
                <StepTitle id={'step-text-' + index}>{step.title}</StepTitle>
              </StepItem>
            </Fragment>
          );
        }
      })}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const StepItem = styled.div<{ $isLast: boolean; $isComplete: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;

  ${({ $isLast }) =>
    $isLast
      ? css`
          flex: 0;

          @media ${DEVICE.laptop} {
            padding-bottom: 40px;
          }
        `
      : ''};

  @media ${DEVICE.laptop} {
    position: absolute;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      &::after {
        transition: all 1s;
        content: '';
        position: absolute;
        top: 17px;
        left: 34px;
        width: 10px;
        height: 2px;
        background-color: ${({ $isComplete }) => ($isComplete ? 'var(--color13)' : 'var(--color8)')};

        @media ${DEVICE.laptop} {
          top: 60px;
          left: 29px;
          width: 2px;
          height: 40px;
        }
      }
    }
  }
`;

const StepIconWrap = styled.div<{ $isCurrent: boolean; $isCompleted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;

  transition: all 1s;

  & svg {
    transform: scale(0.6);
  }
  border: 2px solid transparent;
  background-color: ${({ $isCompleted, $isCurrent }) =>
    $isCompleted ? 'var(--color13)' : $isCurrent ? 'var(--color5)' : 'var(--color8)'};
  ${({ $isCurrent, $isCompleted }) =>
    $isCurrent
      ? `
    border: 2px solid var(--color13);
    z-index: 10;
    & svg path {
      stroke: var(--color13);
    }`
      : $isCompleted &&
        `
    & svg path {
      stroke: var(--color5);
    }
    `}

  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media ${DEVICE.laptop} {
    width: 60px;
    height: 60px;
    margin-right: 12px;
    & svg {
      transform: scale(1);
    }
  }
`;

const StepLine = styled.div<{ $isComplete: boolean; $isLast: boolean }>`
  height: 2px;
  background-color: ${({ $isComplete }) => ($isComplete ? 'var(--color13)' : 'var(--color8)')};
  flex: 1;
  transition: all 1s;
  display: ${({ $isLast }) => ($isLast ? 'none' : 'block')};
  @media ${DEVICE.laptop} {
    display: none;
  }
`;

const StepTitle = styled.div`
  ${TYPOGRAPHY.bodyMedium16}
  display: none;
  color: var(--color9);
  white-space: pre-line;
  overflow-x: hidden;

  @media ${DEVICE.laptop} {
    display: block;
  }
`;
