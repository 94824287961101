import calculateGettingToOffice from './calculate-getting-to-office';
import calculateWorkFromHome from './calculate-work-from-home';
import calculationBusinessTravels from './calculation-business-travels';
/**
 * Calculations Documentation
 * https://docs.google.com/document/d/1vRW0wE66YF7N3S3nZKhcyoETl8eW3oNTRniCE3YGGR4/edit#
 */

const co2FootprintCalculator = {
  calculateGettingToOffice,
  calculateWorkFromHome,
  calculationBusinessTravels,
};

export { co2FootprintCalculator };
