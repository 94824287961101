import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import thunk from 'redux-thunk';

import { actions, reducer, selectors } from './ducks';

export { actions, selectors };

type State = ReturnType<typeof reducer>;

export const rootReducer = (state: State, action: Action) => {
  const nextState = state as State | undefined;

  // if (action.type === actions.auth.signOut.fulfilled.type) {
  //   How to reset store https://twitter.com/dan_abramov/status/703035591831773184
  //   eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //   @ts-ignore
  //   nextState = undefined;
  // }

  return reducer(nextState, action);
};

export const store = configureStore({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reducer: rootReducer as typeof reducer,
  devTools: true,
  middleware: [thunk],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
