import * as React from 'react';
import { SVGProps } from 'react';
const SvgSpanishFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#spanish-flag-icon_svg__a)">
      <path fill="#F93939" d="M0 3h24v17H0z" />
      <path
        d="M21.714 3H2.286A2.276 2.276 0 0 0 0 5.267v12.466A2.276 2.276 0 0 0 2.286 20h19.428A2.276 2.276 0 0 0 24 17.733V5.267A2.276 2.276 0 0 0 21.714 3Z"
        fill="#F93939"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 7.533h24v7.934H0V7.533Z" fill="#FFDA2C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 10.052v2.856c0 .793-.768 1.428-1.715 1.428H6.286c-.944-.003-1.715-.64-1.715-1.43v-2.857c0-.648.512-1.19 1.216-1.365.213-.59.867-.062 1.642-.062.779 0 1.428-.524 1.64.063.702.18 1.217.723 1.217 1.367Z"
        fill="#D4AF2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 10.933h1.143v3.4h-1.143v-3.4Zm-6.857 0H4.57v3.4H3.43v-3.4Z"
        fill="#CBCBCB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 13.2h1.143v1.133h-1.143V13.2Zm-6.857 0H4.57v1.133H3.43V13.2Z"
        fill="#1A47B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 9.8h1.143v1.133h-1.143V9.8Zm-6.857 0H4.57v1.133H3.43V9.8Z"
        fill="#D4AF2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.714 9.8h1.143v1.7H5.714V9.8ZM8 12.067h1.143v1.7H8v-1.7Z"
        fill="#AF010D"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M8 9.8h1.143v1.7H8V9.8Z" fill="#AE6A3E" />
      <path fillRule="evenodd" clipRule="evenodd" d="M5.714 12.067h1.143v1.7H5.714v-1.7Z" fill="#FFDA2C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.857 9.8 5.714 8.667h3.429L8 9.8H6.857Z" fill="#AF010D" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.857 7.533H8v1.134H6.857V7.533Z" fill="#D4AF2C" />
    </g>
    <defs>
      <clipPath id="spanish-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSpanishFlagIcon;
