import { Toast } from '@net0-calc/common/components/ui';
import React from 'react';
import { Bounce, ToastOptions, TypeOptions, toast } from 'react-toastify';
import { i18n } from 'services';

const toastOptions: ToastOptions = {
  position: 'bottom-left',
  autoClose: 4000,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  closeButton: true,
  transition: Bounce,
  icon: false,
};

const getToast = (type: TypeOptions, content: string, title?: string, onClick?: () => void) => {
  return <Toast type={type} title={title} message={content} onClick={onClick} />;
};

class ToastService {
  success = (content: string, title = i18n.t('toasterTitles.success')) =>
    toast.success(getToast('success', content, title), toastOptions);

  info = (content: string, title = i18n.t('toasterTitles.info')) =>
    toast.info(getToast('info', content, title), toastOptions);

  error = (content = i18n.t('toasterTitles.content'), title = i18n.t('toasterTitles.error')) =>
    toast.error(getToast('error', content, title), toastOptions);

  warn = (content: string, title = i18n.t('toasterTitles.warning')) =>
    toast.warn(getToast('warning', content, title), toastOptions);

  message = (content: string, title?: string, onClick?: () => void) =>
    toast.success(getToast('default', content, title, onClick), toastOptions);
}

export default new ToastService();
