import { DEVICE, TYPOGRAPHY } from '../../../assets/styles';
import { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Loader } from '../Loader';
import { ButtonStyles, buttonStyles } from './styles';

type ButtonVariant = 'primary' | 'secondary' | 'ternary';
type ButtonType = 'button' | 'reset' | 'submit';

interface ButtonProps {
  title: string;
  type: ButtonType;
  variant?: ButtonVariant;
  customVariants?: ButtonStyles;
  onClick?: () => void;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
}

export const Button: FC<ButtonProps> = ({
  title,
  type,
  variant = 'primary',
  customVariants,
  onClick,
  className,
  isDisabled = false,
  isLoading,
  icon,
}) => {
  return (
    <StyledButton
      $variant={variant}
      $customVariants={customVariants}
      disabled={isDisabled || isLoading}
      $isLoading={isLoading}
      className={className}
      type={type}
      onClick={onClick}>
      {isLoading ? (
        <Loader color="var(--color2)" />
      ) : (
        <>
          {icon}
          <span>{title}</span>
        </>
      )}
    </StyledButton>
  );
};

const StyledButton = styled.button<{ $variant: ButtonVariant; $isLoading?: boolean; $customVariants?: ButtonStyles }>`
  ${TYPOGRAPHY.bodyMedium16}
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border-radius: 15px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $variant, $isLoading, $customVariants }) =>
    $customVariants ? $customVariants[$variant]($isLoading) : buttonStyles[$variant]($isLoading)};
  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyMedium20}

    height: 70px;
  }
  & svg {
    flex-shrink: 0;
  }
`;
