import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Hints, Input, SelectField, StepDescription, StepTitle } from '@net0-calc/common/components/ui';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';
import * as validators from 'validators';
import { CalculatorFormValues, DistanceUnit, MainTransport, VehicleFuelEfficiencyKInd } from 'common/types';
import { WizardPageProps } from '@net0-calc/common/types';

const pageTranslationPrefix = 'net0-commute.pages.workWayInfo';

const useOptions = () => {
  const { t } = useTranslation();

  const distanceUnitOptions = [
    {
      value: DistanceUnit.MILES,
      label: t(pageTranslationPrefix + '.fields.distanceFromHomeToOffice.units.miles'),
    },
    {
      value: DistanceUnit.KILOMETERS,
      label: t(pageTranslationPrefix + '.fields.distanceFromHomeToOffice.units.kilometers'),
    },
  ];

  const vehicleFuelEfficiencyOptions = [
    {
      value: VehicleFuelEfficiencyKInd.MILES_PER_GALLON,
      label: t(pageTranslationPrefix + '.fields.averageFuelEfficiencyOfYourVehicle.units.milesPerLiter'),
    },
    {
      value: VehicleFuelEfficiencyKInd.KILOMETERS_PER_LITTER,
      label: t(pageTranslationPrefix + '.fields.averageFuelEfficiencyOfYourVehicle.units.kilometersPerLiter'),
    },
  ];

  return {
    distanceUnitOptions,
    vehicleFuelEfficiencyOptions,
  };
};

const averageFuelEfficiencyOfYourVehicleKindValue = {
  [DistanceUnit.MILES]: VehicleFuelEfficiencyKInd.MILES_PER_GALLON,
  [DistanceUnit.KILOMETERS]: VehicleFuelEfficiencyKInd.KILOMETERS_PER_LITTER,
};

export const WorkWayInfo: FC<WizardPageProps<CalculatorFormValues>> = ({ form, values }) => {
  const { t } = useTranslation();
  const { distanceUnitOptions, vehicleFuelEfficiencyOptions } = useOptions();

  const isDrivingAloneOrCarpooling =
    values?.gettingToOfficeTransportType === MainTransport.DRIVING_ALONE ||
    values?.gettingToOfficeTransportType === MainTransport.CARPOOLING;

  return (
    <Root>
      <StepTitle>{t(`${pageTranslationPrefix}.title`)}</StepTitle>
      <StepDescription>{t(`${pageTranslationPrefix}.description`)}</StepDescription>
      <Field
        name="numberOfDaysOfWeekWhenAnEmployeeCommuteToWork"
        type="text"
        parse={formParsers.toNumber}
        validate={combineValidators(validators.isNumber, validators.isNegativeNumber, validators.isMaxValue(7))}>
        {field => (
          <Input
            label={t(pageTranslationPrefix + '.fields.numberOfDaysOfWeekWhenAnEmployeeCommuteToWork.label')}
            placeholder="0"
            {...field}
          />
        )}
      </Field>
      <FieldRowLabel>{t(pageTranslationPrefix + '.fields.distanceFromHomeToOffice.label')}</FieldRowLabel>
      <FieldsRow>
        <Field
          name="distanceFromHomeToOffice"
          type="text"
          parse={formParsers.toNumber}
          validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
          {field => <StyledInput placeholder="0" {...field} />}
        </Field>
        <Field
          name="distanceFromHomeToOfficeUnit"
          options={distanceUnitOptions}
          render={field => (
            <StyledSelectField
              {...field}
              input={{
                ...field.input,
                onChange: (value: DistanceUnit) => {
                  form?.change(
                    'averageFuelEfficiencyOfYourVehicleKind',
                    averageFuelEfficiencyOfYourVehicleKindValue[value],
                  );
                  field.input.onChange(value);
                },
              }}
            />
          )}
        />
      </FieldsRow>

      {isDrivingAloneOrCarpooling && (
        <>
          <FieldRowLabel>{t(pageTranslationPrefix + '.fields.averageFuelEfficiencyOfYourVehicle.label')}</FieldRowLabel>
          <FieldsRow>
            <Field
              name="averageFuelEfficiencyOfYourVehicle"
              type="text"
              parse={formParsers.toNumber}
              validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
              {field => <StyledInput placeholder="0" {...field} />}
            </Field>
            <Field
              name="averageFuelEfficiencyOfYourVehicleKind"
              disabled
              withDropdownIndicator={false}
              options={vehicleFuelEfficiencyOptions}
              component={StyledSelectField}
            />
          </FieldsRow>
        </>
      )}
      <Hints hints={[t(pageTranslationPrefix + '.hint')]} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;

const FieldsRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 50px;
  }
`;

const FieldRowLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
  white-space: pre-line;
`;

const StyledInput = styled(Input)`
  max-height: 64px;
  margin-right: 16px;
  white-space: nowrap;

  & > div {
    margin-bottom: 0;
  }
`;

const StyledSelectField = styled(SelectField)`
  max-width: 218px;
  [class$='container'] {
    max-height: 64px;
  }
`;
