import React, { FC } from 'react';
import { ToastContainer, TypeOptions } from 'react-toastify';
import styled, { css } from 'styled-components/macro';
import { DEVICE, TYPOGRAPHY } from '../../../assets/styles';

interface ToastProps {
  title?: string;
  message: string;
  type: TypeOptions;
  onClick?: () => void;
}

const colorsTypeStatus: Record<TypeOptions, string> = {
  error: 'var(--color12)',
  success: 'var(--color2)',
  info: '',
  warning: '',
  default: '',
};

export const Toast: FC<ToastProps> = ({ type, title, message, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <ToastMessageContainer>
        <MessageBlock>
          <StatusText $type={type}>{title}</StatusText>
          <MessageText>{message}</MessageText>
        </MessageBlock>
      </ToastMessageContainer>
    </Wrapper>
  );
};

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  bottom: 10px;
  left: 10px;
  @media ${DEVICE.laptop} {
    bottom: 24px;
    left: 24px;
  }
  z-index: 99999;
  word-break: break-word;

  padding: 0;
  .toast {
    width: 355px;
    padding: 20px;
    background-color: var(--color5);
    box-shadow: 0px 0px 10px 0px var(--color9);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: visible;
    color: var(--black);

    &:last-child {
      margin-bottom: 0;
    }
  }
  .Toastify__close-button {
    color: var(--color9);
  }
  .Toastify__toast {
    font-family: 'Poppins', sans-serif;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const MessageBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageText = styled.p`
  ${TYPOGRAPHY.captionRegular14}
`;

const StatusText = styled.p<{ $type: TypeOptions }>`
  ${TYPOGRAPHY.bodySemibold18}

  ${({ $type }) =>
    css`
      color: ${colorsTypeStatus[$type]};
    `}
  margin-bottom: 8px;
  text-align: left;
`;

const ToastMessageContainer = styled.div`
  display: flex;
  color: var(--color6);
`;
