import styled from 'styled-components';
import { TYPOGRAPHY } from '../../../assets/styles';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// Attention: to use Tooltip in your app, add dependencies to your package.json and make import of css in App.tsx
//import 'react-tooltip/dist/react-tooltip.css' into usage app

export const Tooltip = styled(ReactTooltip)`
  ${TYPOGRAPHY.captionRegular10}
  max-width: 308px;
  z-index: 10000;
  border-top-color: #ffffff;
  border-radius: 10px;
  color: #444444;
  opacity: 1;
  padding: 10px 12px;
  filter: drop-shadow(0px 5px 16px rgba(0, 0, 0, 0.12));
  background-color: #fff;
`;
