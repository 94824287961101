import * as React from 'react';
import { SVGProps } from 'react';
const SvgUkrainFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#ukrain-flag-icon_svg__a)">
      <g clipPath="url(#ukrain-flag-icon_svg__b)">
        <path fill="#FFDA2C" d="M.2 3h24v17H.2z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M.2 3h24v9.067H.2V3Z" fill="#3A99FF" />
      </g>
    </g>
    <defs>
      <clipPath id="ukrain-flag-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="ukrain-flag-icon_svg__b">
        <path fill="#fff" transform="translate(.2 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUkrainFlagIcon;
