import * as React from 'react';
import { SVGProps } from 'react';

const SvgGreenHospital = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#green-hospital_svg__a)">
      <circle cx={20} cy={20} r={20} fill="#62C962" />
      <path
        d="M13 9v23M25 9v23M13 15H8v2M25 14h9v18M15 12h3v3h-3zM12 7h14v2H12zM15 17h3v3h-3z"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M8 19h3M8 21h3M10 23h1" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
      <path
        stroke="#fff"
        strokeLinejoin="round"
        d="M15 22h3v3h-3zM20 12h3v3h-3zM20 17h3v3h-3zM20 22h3v3h-3zM17 28h4v4h-4z"
      />
      <path d="M13 17H6v3M5 32h30" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.753 28.3c1.663-1.546 1.663-4.054 0-5.6L6 22l-.753.7c-1.663 1.546-1.663 4.054 0 5.6L6 29l.753-.7Z"
        fill="#62C962"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M6 32v-7" stroke="#fff" strokeLinecap="round" />
      <path
        d="M29.753 29.3c1.663-1.546 1.663-4.054 0-5.6L29 23l-.753.7c-1.663 1.546-1.663 4.054 0 5.6L29 30l.753-.7Z"
        fill="#62C962"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M29 32v-6M19 32v-4M27 16h5M27 18h5M27 20h5" stroke="#fff" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="green-hospital_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGreenHospital;
