import { TimeUtil } from '@net0-calc/common/utils';
import { DAYS_OF_WEEK } from 'common/constants/date';

interface Args {
  year: number;
  annualVacationDaysCount: number;
}

export const calculateWeeksInYear = ({ annualVacationDaysCount, year }: Args) => {
  const numberOfDaysInYear = TimeUtil.getDaysCountInYear(year);
  return Number(((numberOfDaysInYear - annualVacationDaysCount) / DAYS_OF_WEEK).toFixed());
};
