import { DEVICE } from '@net0-calc/common/assets/styles';
import { DetailedFormSwitch } from '@net0-calc/common/components';
import { Hints, QuestionTitle, StepDescription } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { CalculatorFormValues, UseSAPConcurAnswer } from 'common/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AdvancedForm, SimpleForm } from './components';

const translationPrefix = 'net0-commute.pages.businessTravel';

export const BusinessTravel: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const isDetailedForm = values?.isDetailedBusinessTravel;
  const isPartiallyUsingSAPConcur = values?.useSAPConcur === UseSAPConcurAnswer.PARTIALLY;

  return (
    <Root>
      <DetailedFormSwitch isDetailedForm={!!isDetailedForm} name="isDetailedBusinessTravel" />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <Description>{t(translationPrefix + '.description')}</Description>
      {isPartiallyUsingSAPConcur && (
        <StyledHints hints={[t('net0-commute.pages.businessTravel.partiallySAPUsersHint')]} />
      )}
      {isDetailedForm ? (
        <AdvancedForm advancedBusinessTravelValues={values?.advancedBusinessTravelValues} />
      ) : (
        <SimpleForm simpleBusinessTravelValues={values?.simpleBusinessTravelValues} />
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const Description = styled(StepDescription)`
  margin-bottom: 20px;
`;

const StyledHints = styled(Hints)`
  margin-bottom: 50px;
`;
