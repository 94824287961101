import * as React from 'react';
import { SVGProps } from 'react';
const SvgBosniaFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#bosnia-flag-icon_svg__a)">
      <path fill="#FFDA2C" d="M0 3h24v17H0z" />
      <path
        d="M21.714 3H2.286A2.276 2.276 0 0 0 0 5.267v12.466A2.276 2.276 0 0 0 2.286 20h19.428A2.276 2.276 0 0 0 24 17.733V5.267A2.276 2.276 0 0 0 21.714 3Z"
        fill="#FFDA2C"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="m0 3 24 15.867-.14 1.246L0 20V3Z" fill="#1A47B8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.143 6.4h1.143v1.133H1.143V6.4ZM4.57 8.667h1.143V9.8H4.571V8.667ZM8 10.933h1.143v1.134H8v-1.134Zm3.429 2.267h1.142v1.133H11.43V13.2Zm3.428 2.267H16V16.6h-1.143v-1.133Zm3.429 2.266h1.143v1.134h-1.143v-1.134Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="bosnia-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBosniaFlagIcon;
