import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Bar, Rectangle, Cell, ResponsiveContainer, XAxis, YAxis, BarChart, Tooltip } from 'recharts';
import { PlaneIcon, HomeIcon, CarIcon } from '@net0-calc/common/assets/svg';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { Co2FootprintsVOs } from 'common/types';
import { formatWeightValue } from 'utils';

const CustomBar = (props: any) => {
  const isMinimum = props.height < 1 && props.height > 0;
  return (
    <Rectangle
      {...props}
      y={isMinimum ? props.y - 4 : props.y}
      height={isMinimum ? 4 : props.height}
      radius={[10, 10, 0, 0]}
    />
  );
};

interface ChartSectionProps {
  className?: string;
  co2FootprintsVOs: Co2FootprintsVOs;
}

const colors = ['var(--chart-color1)', 'var(--chart-color3)', 'var(--chart-color5)'];

const pageTranslationPrefix = 'net0-commute.pages.result';

export const ChartSection: FC<ChartSectionProps> = ({ className, co2FootprintsVOs }) => {
  const { t } = useTranslation();

  const data = [
    { name: 'Page A', CO2: co2FootprintsVOs.gettingToOffice.formattedKg },
    { name: 'Page B', CO2: co2FootprintsVOs.workFromHome.formattedKg },
    { name: 'Page C', CO2: co2FootprintsVOs.businessTravel.formattedKg },
  ];

  const renderCustomAxisTick = ({ x, y, payload }: any) => {
    switch (payload.value) {
      case 'Page A':
        return <CarIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page B':
        return <HomeIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      case 'Page C':
        return <PlaneIcon x={x - 9} y={y - 4} viewBox="0 0 24 24" width={16} height={16} />;
      default:
        return <PlaneIcon />;
    }
  };

  const co2FootprintPerAttendeeVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs));
  const isFootprintZero = Number(co2FootprintPerAttendeeVO.ton) === 0;

  return (
    <Root className={className}>
      <ResponsiveContainer>
        <BarChart data={data} maxBarSize={90} margin={{ top: 0, bottom: 60, left: 12 }}>
          <XAxis
            dataKey="name"
            tick={props => renderCustomAxisTick(props)}
            tickLine={false}
            stroke={'var(--color10)'}
            tickMargin={12}
          />
          <YAxis
            tickLine={false}
            tick={{ fontFamily: 'Poppins', fontSize: 12, fill: 'var(--color9)', dx: -10 }}
            domain={[0, isFootprintZero ? 'dataMax + 10' : 'dataMax']}
            tickMargin={12}
            type="number"
            tickFormatter={val => formatWeightValue(val)}
            axisLine={{ stroke: 'var(--color10)' }}
          />
          <Tooltip<string, string>
            cursor={{ fill: 'transparent' }}
            labelStyle={{ fontFamily: 'Poppins', fontSize: 12 }}
            itemStyle={{ fontFamily: 'Poppins', fontSize: 12, textAlign: 'center' }}
            contentStyle={{ borderRadius: 10, background: 'var(--color5)' }}
            formatter={(value: string) => utils.formatWeightValue(Number(value))}
            labelFormatter={label => {
              switch (label) {
                case 'Page A':
                  return t(pageTranslationPrefix + '.summary.values.gettingToOffice');
                case 'Page B':
                  return t(pageTranslationPrefix + '.summary.values.homeSetUp');
                case 'Page C':
                  return t(pageTranslationPrefix + '.summary.values.businessTravel');
                default:
                  return '';
              }
            }}
          />
          <Bar dataKey="CO2" shape={<CustomBar />}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={colors[index]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Root>
  );
};

export default ChartSection;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  flex: 1;

  @media ${DEVICE.tablet} {
    height: 346px;
    min-width: 325px;
    max-width: 850px;
    border-radius: 20px;
  }

  & .recharts-yAxis .recharts-cartesian-axis-tick:first-child {
    display: none;
  }
`;
