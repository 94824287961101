import { i18n } from 'services';

export const isRequired = (value: unknown) => {
  if (typeof value === 'number') {
    if (value === 0) {
      return undefined;
    }
  }
  return value ? undefined : i18n.t('formValidationErrors.isRequired');
};
