import { TYPOGRAPHY } from '../../../common/assets/styles';
import { FileDownloadIcon } from '../../..//common/assets/svg';
import { Button } from '../../components/ui';
import { METHODOLOGY_PDF_LINK } from '../../../common/constants/file-links';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
  className?: string;
  link?: string;
};

export const DownloadMethodology: FC<Props> = ({ className, link }) => {
  const { t } = useTranslation();
  return (
    <Root
      type="button"
      variant="secondary"
      title={t('common.downloadPDF')}
      icon={<StyledFileDownloadIcon />}
      onClick={() => window.open(link || METHODOLOGY_PDF_LINK)}
      className={className}
    />
  );
};

const Root = styled(Button)`
  ${TYPOGRAPHY.bodyMedium16}

  max-height: 56px;
  padding: 10px 16px;
`;

const StyledFileDownloadIcon = styled(FileDownloadIcon)`
  margin-right: 8px;
`;
