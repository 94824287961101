import { TYPOGRAPHY, DEVICE } from '../../assets/styles';
import React, { FC, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { Language, LanguageOption } from '../../types';
import { ArrowIcon } from '../../assets/svg';
import { CSSProp } from 'styled-components/macro';

interface Props<T> {
  onSwitchLanguage: (item: LanguageOption<T>) => void;
  currentLanguage: LanguageOption<T>;
  languagesOptions: LanguageOption<T>[];
  className?: string;
  rootCSS?: CSSProp;
}

export function LanguageDropdownLayout<T extends string = Language>({
  currentLanguage,
  languagesOptions,
  onSwitchLanguage,
  className,
  rootCSS,
}: Props<T>) {
  const [isOpened, setIsOpened] = useState(false);

  const parseLanguageName = (str: string) => {
    const splittedStr = str.split('_');

    if (splittedStr[1]) {
      if (isNaN(Number(splittedStr[1]))) {
        return splittedStr[1];
      }
    }

    return splittedStr[0];
  };

  return (
    <Root $CSS={rootCSS} className={className} onClick={() => setIsOpened(!isOpened)}>
      <IconWrap>{currentLanguage.icon}</IconWrap>
      <LangCode className="langCode">{parseLanguageName(currentLanguage.name) || ''}</LangCode>
      <ArrowIconWrap className="arrowIcon" $isOpened={isOpened}>
        {<ArrowIcon />}
      </ArrowIconWrap>
      <DropdownContainer $isOpened={isOpened}>
        {languagesOptions.map(item => (
          <ListItem key={item.name} onClick={() => onSwitchLanguage(item)}>
            <IconWrap>{item.icon}</IconWrap>
            <LangCode>{parseLanguageName(item.name) || ''}</LangCode>
          </ListItem>
        ))}
      </DropdownContainer>
    </Root>
  );
}

const Root = styled.div<{ $CSS?: CSSProp }>`
  position: relative;
  right: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ $CSS }) => $CSS}
`;

const IconWrap = styled.div`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LangCode = styled.div`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  text-transform: uppercase;
  margin-right: 4px;
`;

const ArrowIconWrap = styled.div<{ $isOpened: boolean }>`
  display: flex;
  align-items: center;

  ${({ $isOpened }) => $isOpened && 'transform: rotate(180deg);'}

  & > svg {
    width: 16px;
    height: 16px;
  }

  @media ${DEVICE.laptop} {
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const DropdownContainer = styled.div<{ $isOpened: boolean }>`
  ${({ $isOpened }) => ($isOpened ? 'display: block;' : 'display: none;')}
  position: absolute;
  left: 0;
  top: calc(100% + 6px);
  border: 1px solid var(--color10);
  border-radius: 6px;
  background-color: var(--color5);
  box-shadow: 0px 2px 4xp var(--color6);
  padding: 10px 0;
`;

const ListItem = styled.button`
  display: flex;
  padding: 0 10px;
  align-items: center;

  &:hover {
    background-color: var(--color8);
    cursor: pointer;
  }
`;
