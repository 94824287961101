import * as React from 'react';
import { SVGProps } from 'react';

const SvgGreenEarth = ({ fill, ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#green-earth_svg__a)">
      <circle cx={20} cy={20} r={20} fill={fill || '#62C962'} />
      <path
        d="M18.622 18.067c-1.33-5.613 2.206-10.909 7.9-11.829l2.578-.416.603 2.54c1.33 5.613-2.206 10.909-7.9 11.829l-2.579.417-.602-2.541Z"
        fill={fill || '#62C962'}
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M18.48 24.523c1.118-6.097 2.662-8.91 6.548-13.18" stroke="#fff" strokeLinecap="round" />
      <path d="M31.766 14.472A13 13 0 1 1 20.114 7" stroke="#fff" strokeLinecap="round" />
      <path
        d="M7.5 17.5h1.25a3.75 3.75 0 0 1 3.75 3.75v2.25A1.5 1.5 0 0 0 14 25v0a1.5 1.5 0 0 1 1.5 1.5V32M26.5 31l-1.879-1.879a3 3 0 0 1 0-4.242l.258-.258a2.121 2.121 0 0 1 1.5-.621h.371a1.75 1.75 0 0 0 1.75-1.75v0c0-.966.784-1.75 1.75-1.75H33M8.5 14h3.296c.94 0 1.704-.763 1.704-1.704v0c0-.44.356-.796.796-.796H15a1.5 1.5 0 0 0 1.5-1.5V7.5"
        stroke="#fff"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="green-earth_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGreenEarth;
