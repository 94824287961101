import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Input, SelectField } from '@net0-calc/common/components/ui';
import { CalculatorFormValues, TravelTransportType } from 'common/types';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';
import * as validators from 'validators';
import { FlightServiceClassField } from '../common';
import { currencyRegexp } from '@net0-calc/common/constants/regex';
import { FieldArrayWrapper } from '@net0-calc/common/components';
import { css } from 'styled-components/macro';
import { AttentionIcon } from '@net0-calc/common/assets/svg';
import { Tooltip } from '@net0-calc/common/components/ui/Tooltip/Tooltip';

const fieldsTranslationPrefix = 'net0-commute.pages.businessTravel.simpleFrom.fields';

export const SimpleForm: FC<Pick<CalculatorFormValues, 'simpleBusinessTravelValues'>> = ({
  simpleBusinessTravelValues,
}) => {
  const { t } = useTranslation();

  const transportTypeOptions = [
    {
      value: TravelTransportType.AIRPLANE,
      label: t(fieldsTranslationPrefix + '.transportType.options.air'),
    },
    {
      value: TravelTransportType.TRAIN,
      label: t(fieldsTranslationPrefix + '.transportType.options.train'),
    },
    {
      value: TravelTransportType.CAR,
      label: t(fieldsTranslationPrefix + '.transportType.options.car'),
    },
  ];

  return (
    <FieldArrayWrapper
      closeButtonCSS={DeleteCSS}
      fieldsArrayName="simpleBusinessTravelValues"
      addBtnTitle={t('net0-commute.pages.businessTravel.advancedForm.addFieldsButton')}
      isAddButtonAlwaysRendered
      renderItem={(fieldsName, index) => {
        return (
          <FieldsItem key={index}>
            <FieldLabel>{t(fieldsTranslationPrefix + '.label')}</FieldLabel>
            <Field<string> name={`${fieldsName}.businessTravelTransportType`}>
              {field => (
                <StyledSelectField
                  options={transportTypeOptions}
                  label={t(fieldsTranslationPrefix + '.transportType.label')}
                  {...field}
                />
              )}
            </Field>
            {!simpleBusinessTravelValues?.[index]?.businessTravelTransportType ? (
              <></>
            ) : simpleBusinessTravelValues?.[index]?.businessTravelTransportType === TravelTransportType.AIRPLANE ? (
              <>
                <FLightTimeDurationsGroup>
                  <Field
                    name={`${fieldsName}.numberOfSingleShortFlights`}
                    type="text"
                    parse={formParsers.toNumber}
                    format={formFormatters.numberToString}
                    validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                    {field => (
                      <Input
                        label={t(fieldsTranslationPrefix + '.numberOfSingleShortFlights.label')}
                        placeholder="0"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field
                    name={`${fieldsName}.numberOfSingleMidRangeFlights`}
                    type="text"
                    parse={formParsers.toNumber}
                    format={formFormatters.numberToString}
                    validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                    {field => (
                      <Input
                        label={t(fieldsTranslationPrefix + '.numberOfSingleMidRangeFlights.label')}
                        placeholder="0"
                        {...field}
                      />
                    )}
                  </Field>
                  <Field
                    name={`${fieldsName}.numberOfSingleLongHaulFlight`}
                    type="text"
                    parse={formParsers.toNumber}
                    format={formFormatters.numberToString}
                    validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                    {field => (
                      <StyledInput
                        label={t(fieldsTranslationPrefix + '.numberOfSingleLongHaulFlight.label')}
                        placeholder="0"
                        {...field}
                      />
                    )}
                  </Field>
                </FLightTimeDurationsGroup>
                <StyledFlightServiceClassField
                  fieldName={`${fieldsName}.flightServiceClass`}
                  fieldIndex={index}
                  label={t(fieldsTranslationPrefix + '.flightServiceClass.label')}
                />
              </>
            ) : (
              <>
                <Field
                  name={`${fieldsName}.numberOfBusinessTrip`}
                  type="text"
                  parse={formParsers.toNumber}
                  format={formFormatters.numberToString}
                  validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                  {field => (
                    <Input
                      label={
                        <NumberOfTripField>
                          <span>{t(fieldsTranslationPrefix + '.numberOfBusinessTrip.label')}</span>
                          <StyledInfoIcon id="businessTripInfo" />
                          <Tooltip
                            anchorId="businessTripInfo"
                            content={t(fieldsTranslationPrefix + '.numberOfBusinessTrip.tooltip')}
                            place="top"
                          />
                        </NumberOfTripField>
                      }
                      placeholder="0"
                      {...field}
                    />
                  )}
                </Field>
                <Field
                  name={`${fieldsName}.averageBusinessTripDistance`}
                  type="number"
                  format={formFormatters.numberToString}
                  validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                  {field => (
                    <StyledInput
                      label={t(fieldsTranslationPrefix + '.averageBusinessTripDistance.label')}
                      placeholder="0"
                      pattern={currencyRegexp}
                      {...field}
                    />
                  )}
                </Field>
              </>
            )}
          </FieldsItem>
        );
      }}
    />
  );
};

const FLightTimeDurationsGroup = styled.div`
  margin-bottom: 50px;
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  & > div {
    margin-bottom: 0;
  }
`;

const StyledFlightServiceClassField = styled(FlightServiceClassField)`
  & > label {
    ${TYPOGRAPHY.bodyRegular16}

    color: var(--color6);
    margin-bottom: 24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 24px;
`;

const FieldsItem = styled.div`
  width: 100%;
`;

const FieldLabel = styled.label`
  ${TYPOGRAPHY.bodyRegular16}

  display: block;
  color: var(--color6);
  margin-bottom: 24px;
`;

const DeleteCSS = css`
  margin-top: 95px;
  @media screen and ${DEVICE.laptop} {
    margin-top: 95px;
  }
`;

const NumberOfTripField = styled.span`
  display: inline-flex;
  column-gap: 6px;
  align-items: center;
`;

const StyledInfoIcon = styled(AttentionIcon)`
  width: 20px;
  height: 20px;
  transform: translateY(3px);
`;
