import { DEVICE, TYPOGRAPHY } from '../../assets/styles';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Input } from '../ui';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { gsap } from 'gsap';
import { FormErrorValues, ReceiveResultByMailFormValues } from '../../types';
import { isEmail } from '../../utils';

interface Props {
  isSubmitting: boolean;
  onSubmit: (values: ReceiveResultByMailFormValues) => void;
}

export const ReceiveResultForm = ({ isSubmitting, onSubmit }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 700px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  const validateForm = (values: ReceiveResultByMailFormValues) => {
    const errors: Partial<FormErrorValues> = {};

    if (!values.email) {
      errors.email = t('common.receiveResults.form.errorMessages.email');
    } else if (!isEmail(values.email)) {
      errors.email = t('common.receiveResults.form.errorMessages.notEmail');
    } else {
      errors.email = undefined;
    }

    return errors;
  };

  return (
    <Form
      initialValues={{
        email: '',
        name: '',
      }}
      validate={validateForm}
      onSubmit={onSubmit}
      render={({ handleSubmit, hasValidationErrors }) => {
        return (
          <FormWrapper ref={containerRef} onSubmit={handleSubmit}>
            <FormContainer>
              <Title>{t('common.receiveResults.form.title')}</Title>
              <Field name="email" type="email">
                {(field: FieldRenderProps<string>) => (
                  <Input label={t('common.receiveResults.form.field.email.label')} {...field} />
                )}
              </Field>
              <Field name="name">
                {field => <NameInput label={t('common.receiveResults.form.field.name.label')} {...field} />}
              </Field>
              <Button
                title={
                  isSubmitting
                    ? t('common.receiveResults.form.submitButton.sending')
                    : t('common.receiveResults.form.submitButton.receive')
                }
                type="submit"
                isDisabled={isSubmitting || hasValidationErrors}
              />
            </FormContainer>
          </FormWrapper>
        );
      }}
    />
  );
};

const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  order: 3;
  margin-bottom: 27px;
  @media ${DEVICE.laptop} {
    order: 4;
    margin-bottom: 0;
  }
`;

const FormContainer = styled.form`
  width: 100%;
  max-width: 628px;
`;

const Title = styled.div`
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  text-align: center;
  margin-bottom: 20px;
  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;
const NameInput = styled(Input)`
  margin-bottom: 24px;
  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;
