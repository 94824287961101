import * as React from 'react';
import { SVGProps } from 'react';
const SvgPortugueseFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#portuguese-flag-icon_svg__a)">
      <path fill="#F93939" d="M0 2.857h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 2.857h8v17H0v-17Z" fill="#249F58" />
      <path
        d="M8 14.19c1.894 0 3.429-1.522 3.429-3.4 0-1.877-1.535-3.4-3.429-3.4s-3.429 1.523-3.429 3.4c0 1.878 1.535 3.4 3.429 3.4Z"
        fill="#FFDA2C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 11.924v-3.4H5.714v3.4c0 .625 1.023 1.133 2.286 1.133 1.263 0 2.286-.508 2.286-1.133Z"
        fill="#F93939"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.857 9.657h2.286v2.267H6.857V9.657Z" fill="#fff" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.857 9.657H8v1.133H6.857V9.657ZM8 10.79h1.143v1.134H8V10.79Z"
        fill="#1A47B8"
      />
    </g>
    <defs>
      <clipPath id="portuguese-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 2.857)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPortugueseFlagIcon;
