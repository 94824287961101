import { SelectField } from '@net0-calc/common/components/ui';
import { BusinessTravelAdvancedFiledArrayValues, TravelTransportType, TypeOfTrip } from 'common/types';
import React, { FC } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlightServiceClassField } from '../../../common';
import { SearchAddressInput, SearchAddressInputValue } from '@net0-calc/common/components/SearchAddressInput';
import { toast } from 'services';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { ChipRadioButton } from 'components';

type Props = {
  fieldsName: string;
  fieldIndex: number;
  locale: string;
  values?: BusinessTravelAdvancedFiledArrayValues;
};

const translationPrefix = 'net0-commute.pages.businessTravel.advancedForm.fields';

export const TripItem: FC<Props> = ({ fieldsName, fieldIndex, values }) => {
  const { t } = useTranslation();

  const transportTypeOptions = [
    { label: t(translationPrefix + '.transportType.options.air'), value: TravelTransportType.AIRPLANE },
    { label: t(translationPrefix + '.transportType.options.train'), value: TravelTransportType.TRAIN },
    { label: t(translationPrefix + '.transportType.options.car'), value: TravelTransportType.CAR },
  ];

  const typeOfTripOptions = [
    { label: t(translationPrefix + '.typeOfTrips.options.oneWayTrip'), value: TypeOfTrip.ONE_WAY },
    { label: t(translationPrefix + '.typeOfTrips.options.returnTrip'), value: TypeOfTrip.RETURN },
  ];

  return (
    <Root>
      <Label>{`${t(translationPrefix + '.label')} ${fieldIndex + 1}`}</Label>
      <Field<string> name={`${fieldsName}.businessTravelTransportType`}>
        {field => (
          <StyledSelectField
            options={transportTypeOptions}
            label={t(translationPrefix + '.transportType.label')}
            {...field}
          />
        )}
      </Field>
      {values?.businessTravelTransportType === TravelTransportType.AIRPLANE && (
        <StyledFlightServiceClassField
          fieldName={`${fieldsName}.flightServiceClass`}
          fieldIndex={fieldIndex}
          label={t(translationPrefix + '.flightServiceClass.label')}
        />
      )}
      <RowFieldsGroup>
        <Field<SearchAddressInputValue> name={`${fieldsName}.placeOfDeparture`}>
          {(field: FieldRenderProps<SearchAddressInputValue>) => (
            <SearchAddressInput
              label={t(translationPrefix + '.placeOfDeparture.label')}
              placeholder={t(translationPrefix + '.placeOfDeparture.placeholder')}
              toast={toast}
              {...field}
            />
          )}
        </Field>
        <Field<SearchAddressInputValue> name={`${fieldsName}.placeOfArrival`}>
          {(field: FieldRenderProps<SearchAddressInputValue>) => (
            <SearchAddressInput
              toast={toast}
              label={t(translationPrefix + '.placeOfArrival.label')}
              placeholder={t(translationPrefix + '.placeOfArrival.placeholder')}
              {...field}
            />
          )}
        </Field>
      </RowFieldsGroup>
      <ChipsGroupLabel>{t(translationPrefix + '.typeOfTrips.label')}</ChipsGroupLabel>
      <ChipsGroup>
        {typeOfTripOptions.map(({ label, value }) => {
          return (
            <Field key={value} name={`${fieldsName}.typeOfTrip`} type="radio" value={value}>
              {({ input }) => (
                <ChipRadioButton id={`${value + fieldIndex}-option`} {...input}>
                  {label}
                </ChipRadioButton>
              )}
            </Field>
          );
        })}
      </ChipsGroup>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const Label = styled.label`
  ${TYPOGRAPHY.bodyMedium16}
  display: block;
  margin-bottom: 24px;
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 24px;
`;

const StyledFlightServiceClassField = styled(FlightServiceClassField)`
  margin-bottom: 24px;
`;

const RowFieldsGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  margin-bottom: 20px;
  @media ${DEVICE.laptop} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    margin-bottom: 24px;
  }
`;

const ChipsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 20px;
`;

const ChipsGroupLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;
