import styled, { css, CSSProp } from 'styled-components/macro';
import { FC, ReactNode, useEffect } from 'react';
import { TYPOGRAPHY, DEVICE } from '../../../assets/styles';
import gsap from 'gsap';

interface CardRadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon: ReactNode;
  title: string;
  iconWrapperCSS?: CSSProp;
}

export const CardRadioButton: FC<CardRadioButtonProps> = ({ id, className, icon, title, iconWrapperCSS, ...props }) => {
  const { checked } = props;

  useEffect(() => {
    if (checked) {
      gsap.fromTo(
        '#card-radio-anim-element-' + id,
        {
          transform: 'translate(-10%, -25%)',
          scaleX: 1,
          scaleY: 1,
        },
        {
          transform: 'translate(-100%, -50%)',
          scaleX: 0,
          scaleY: 0,
          duration: 1.5,
        },
      );
    }
  }, [checked]);

  return (
    <RootLabel tabIndex={0} className={className} $isChecked={checked}>
      <AnimationElement id={'card-radio-anim-element-' + id} />
      <Container>
        <StyledInput tabIndex={-1} id={id} type="radio" {...props} />
        <IconWrap $isChecked={checked} $CSS={iconWrapperCSS} className="icon-wrap">
          {icon}
        </IconWrap>
        <Title $isChecked={checked}>{title}</Title>
      </Container>
    </RootLabel>
  );
};

const RootLabel = styled.label<{ $isChecked?: boolean }>`
  max-width: 195px;
  position: relative;
  z-index: 0;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid ${({ $isChecked }) => ($isChecked ? 'var(--color2)' : 'var(--color4)')};
  background-color: ${({ $isChecked }) => ($isChecked ? 'var(--color2)' : 'var(--color5)')};
  overflow: hidden;
  transition: border-color 1.5s;
  @media ${DEVICE.mobileL} {
    min-width: 165px;
    height: 130px;
  }
  @media ${DEVICE.laptop} {
    height: 150px;
  }
`;

const AnimationElement = styled.div`
  position: absolute;
  transform: translate(-25%, -25%);
  width: 500px;
  height: 500px;
  background-color: var(--color5);
  border-radius: 50%;
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 13px;

  @media ${DEVICE.laptop} {
    padding: 21px;
  }
`;

const StyledInput = styled.input`
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
  outline: none;
`;

const IconWrap = styled.div<{ $isChecked?: boolean; $CSS?: CSSProp }>`
  margin-bottom: 16px;

  @media ${DEVICE.mobileL} {
    margin-bottom: 20px;
  }

  ${({ $isChecked }) =>
    $isChecked
      ? css`
          & svg path {
            stroke: var(--color5);
          }
        `
      : css`
          & svg {
            width: 60px;
            height: 60px;

            & path {
              stroke: var(--color1);
              transition: stroke 1s;
            }
          }
        `}

  ${({ $CSS }) => ($CSS ? $CSS : '')}
`;

const Title = styled.div<{ $isChecked?: boolean }>`
  ${TYPOGRAPHY.bodyMedium16}
  color: ${({ $isChecked }) => ($isChecked ? 'var(--color5)' : 'var(--color1)')};
  transition: color 1s;
  text-align: center;
`;
