import * as React from 'react';
import { SVGProps } from 'react';
const SvgRemoveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#remove-icon_svg__a)">
      <rect opacity={0.15} width={32} height={32} rx={12} fill="#BD2A33" />
      <path
        d="M21 11 11 21M11 11l10 10"
        stroke="#BD2A33"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="remove-icon_svg__a">
        <rect width={32} height={32} rx={16} fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgRemoveIcon;
