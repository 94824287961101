import { TimeUtil } from '@net0-calc/common/utils';
import { CalculatorFormValues, Device, DevicesFieldArrayValues, RenewableAtHomeAnswer } from 'common/types';
import { Co2FootprintVO } from 'vo';
import { calculateWeeksInYear } from './calculate-weeks-in-year';

//Take from https://docs.google.com/document/d/1vRW0wE66YF7N3S3nZKhcyoETl8eW3oNTRniCE3YGGR4/edit#

const DEVICE_COEFFICIENT = {
  [Device.LAMP]: 0.04,
  [Device.LAPTOP]: 0.075,
  [Device.MONITOR]: 0.084,
  [Device.PRINTER]: 0.03,
  [Device.SPACE_HEATER]: 1.5,
  [Device.OTHER]: 0.063,
};

const ELECTRICITY_COEFFICIENT = {
  [RenewableAtHomeAnswer.YES]: 0,
  [RenewableAtHomeAnswer.YES_PARTIALLY]: 1,
  [RenewableAtHomeAnswer.NO]: 2.38757, //RFCW Emission factor,
  [RenewableAtHomeAnswer.DONT_NOW]: 1.193785,
};

type Props = Pick<
  CalculatorFormValues,
  | 'daysPerWeekWorkFromHome'
  | 'haveRenewableElectricityAtYourHome'
  | 'additionalDevicesForYourWorkFromHome'
  | 'devicesForYourWorkFromHome'
  | 'percentageOfRenewableElectricityAtYourHome'
  | 'numberOfAnnuallyVacationDays'
  | 'userReportYear'
>;

interface CalculateDevicesTotalElectricityArgs {
  devices: (DevicesFieldArrayValues | undefined)[];
  numberOfAnnuallyVacationDays?: number;
  reportingYear?: number;
}

const calculateDevicesTotalElectricity = ({
  devices,
  numberOfAnnuallyVacationDays = 0,
  reportingYear = 0,
}: CalculateDevicesTotalElectricityArgs) => {
  const total = devices.reduce((acc, curr) => {
    if (!curr?.device) return acc;

    const deviceFootprint =
      DEVICE_COEFFICIENT[curr.device] *
      (curr?.numberOfDevices || 0) *
      (curr?.usageTimePerWeek || 0) *
      calculateWeeksInYear({
        annualVacationDaysCount: numberOfAnnuallyVacationDays,
        year: reportingYear,
      });
    return acc + deviceFootprint;
  }, 0);

  return total;
};

type CalculatePrams = {
  daysPerWeekWorkFromHome: number;
  devicesTotalElectricity: number;
  haveRenewableElectricityAtYourHome?: RenewableAtHomeAnswer;
  percentageOfRenewableElectricityAtYourHome?: number;
};

const calculateFootprintByPartiallyRenewableElectricityPerDay = (
  totalElectricity: number,
  percentageOfRenewableElectricity: number,
) => {
  const noRenewableElectricityPercentage = (100 - percentageOfRenewableElectricity) / 100;

  return totalElectricity * ELECTRICITY_COEFFICIENT.NO * noRenewableElectricityPercentage;
};

const calculate = ({
  daysPerWeekWorkFromHome,
  devicesTotalElectricity,
  haveRenewableElectricityAtYourHome = RenewableAtHomeAnswer.NO,
  percentageOfRenewableElectricityAtYourHome = 0,
}: CalculatePrams) => {
  let total = 0;

  if (haveRenewableElectricityAtYourHome === RenewableAtHomeAnswer.YES_PARTIALLY) {
    total =
      daysPerWeekWorkFromHome *
      calculateFootprintByPartiallyRenewableElectricityPerDay(
        devicesTotalElectricity,
        percentageOfRenewableElectricityAtYourHome,
      );

    return Co2FootprintVO.create(total, 'kg');
  }

  total =
    daysPerWeekWorkFromHome * devicesTotalElectricity * ELECTRICITY_COEFFICIENT[haveRenewableElectricityAtYourHome];

  return Co2FootprintVO.create(total, 'kg');
};

export default ({
  daysPerWeekWorkFromHome,
  haveRenewableElectricityAtYourHome,
  percentageOfRenewableElectricityAtYourHome,
  additionalDevicesForYourWorkFromHome = [],
  devicesForYourWorkFromHome = [],
  numberOfAnnuallyVacationDays = 0,
  userReportYear = TimeUtil.getYear(TimeUtil.now().toDate()),
}: Props): Co2FootprintVO => {
  if (!daysPerWeekWorkFromHome || haveRenewableElectricityAtYourHome === RenewableAtHomeAnswer.YES)
    return Co2FootprintVO.create(0, 'kg');

  const devicesTotalElectricity = devicesForYourWorkFromHome
    ? calculateDevicesTotalElectricity({
        devices: [...devicesForYourWorkFromHome, ...additionalDevicesForYourWorkFromHome],
        numberOfAnnuallyVacationDays,
        reportingYear: userReportYear,
      })
    : 0;

  if (!devicesTotalElectricity) return Co2FootprintVO.create(0, 'kg');

  return calculate({
    daysPerWeekWorkFromHome,
    devicesTotalElectricity,
    haveRenewableElectricityAtYourHome,
    percentageOfRenewableElectricityAtYourHome,
  });
};
