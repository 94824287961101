import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Co2FootprintVO } from 'vo';
import { useTranslation } from 'react-i18next';
import { ChartDonutSection, TotalFootprintValue } from './components';
import { Co2FootprintsVOs } from 'common/types';
import { ChartSection } from './components/ChartSection';

gsap.registerPlugin(ScrollTrigger);

interface TopContainerProps {
  co2FootprintSumVO: Co2FootprintVO;
  co2FootprintsVOs: Co2FootprintsVOs;
  reportYear: number;
}

const pageTranslationPrefix = 'net0-commute.pages.result';
const summaryTranslationPrefix = pageTranslationPrefix + '.summary';

export const TopContainer: FC<TopContainerProps> = ({ co2FootprintSumVO, co2FootprintsVOs, reportYear }) => {
  const { t } = useTranslation();

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 600px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0.5,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  const results = [
    {
      markColor: 'var(--chart-color1)',
      text: t(summaryTranslationPrefix + '.values.gettingToOffice'),
      value: co2FootprintsVOs.gettingToOffice.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color3)',
      text: t(summaryTranslationPrefix + '.values.homeSetUp'),
      value: co2FootprintsVOs.workFromHome.stringifiedValue,
    },
    {
      markColor: 'var(--chart-color5)',
      text: t(summaryTranslationPrefix + '.values.businessTravel'),
      value: co2FootprintsVOs.businessTravel.stringifiedValue,
    },
  ];

  const renderResults = () => {
    return results.map(({ markColor, text, value }) => (
      <ResultLine key={text}>
        <ResultTitle>
          <Mark $color={markColor} />
          {text}
        </ResultTitle>
        <ResultValue>{value}</ResultValue>
      </ResultLine>
    ));
  };

  return (
    <Root>
      <SectionLeft>
        <Title>
          <span>{t(pageTranslationPrefix + '.title', { year: reportYear })}</span>{' '}
          <TotalFootprintValue value={co2FootprintSumVO.kg} />
        </Title>
        <MobileDonutSection co2FootprintsVOs={co2FootprintsVOs} />
        <ChartSection co2FootprintsVOs={co2FootprintsVOs} />
        <div ref={containerRef}>
          <Subtitle>{t(summaryTranslationPrefix + '.title')}</Subtitle>
          {renderResults()}
        </div>
      </SectionLeft>
      <SectionRight>
        <ChartDonutSection co2FootprintsVOs={co2FootprintsVOs} />
      </SectionRight>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 40px;
  @media ${DEVICE.laptop} {
    margin-bottom: 60px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const SectionLeft = styled.div`
  flex: 2;
  @media ${DEVICE.laptop} {
    margin-right: 32px;
  }
  @media ${DEVICE.desktop} {
    margin-right: 0;
  }
`;

const SectionRight = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: block;
  }
`;

const Title = styled.h1`
  ${TYPOGRAPHY.titleSemibold28}
  flex-direction: column;
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.mobileL} {
    flex-direction: row;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold44}
    margin-bottom: 44px;
  }
`;

const Subtitle = styled.h6`
  ${TYPOGRAPHY.bodyMedium20}

  display: flex;
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}
    margin-bottom: 32px;
  }
`;

const ResultLine = styled.div`
  display: flex;
  max-width: 845px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  @media ${DEVICE.laptop} {
    margin-bottom: 36px;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Mark = styled.span<{ $color: string }>`
  width: 24px;
  height: 24px;
  border-radius: 10px;
  background-color: ${({ $color }) => $color};
  margin-right: 12px;
`;

const ResultTitle = styled.div`
  ${TYPOGRAPHY.captionRegular14}
  color: var(--color6);
  display: flex;
  align-items: center;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyMedium20}
  }
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.captionRegular14}
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.boydRegular20}
  }
`;
const MobileDonutSection = styled(ChartDonutSection)`
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    display: none;
  }
`;
