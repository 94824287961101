import { CalculatorFormValues, FuelKind, MainTransport } from 'common/types';
import * as validators from 'validators';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { LargeBusIcon, LargeCarIcon, LargeCycleIcon, LargeTaxiIcon, LargeWalkIcon } from '@net0-calc/common/assets/svg';
import { DEVICE } from '@net0-calc/common/assets/styles';
import {
  CardRadioButton,
  Input,
  RadioButton,
  SelectField,
  StepDescription,
  StepTitle,
} from '@net0-calc/common/components/ui';
import { FC } from 'react';
import { WizardPageProps } from '@net0-calc/common/types';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';

const pageTranslationPrefix = 'net0-commute.pages.gettingToOffice';
const optionsTranslationPrefix = pageTranslationPrefix + '.fields.transportType';
const fuelTranslationPrefix = pageTranslationPrefix + '.fields';

const useOptions = () => {
  const { t } = useTranslation();

  const gettingToOfficeOptions = [
    { icon: <LargeWalkIcon />, title: t(`${optionsTranslationPrefix}.walking`), value: MainTransport.WALKING },
    { icon: <LargeCycleIcon />, title: t(`${optionsTranslationPrefix}.biking`), value: MainTransport.BIKING },
    {
      icon: <LargeBusIcon />,
      title: t(`${optionsTranslationPrefix}.publicTransport`),
      value: MainTransport.PUBLIC_TRANSPORT,
    },
    { icon: <LargeTaxiIcon />, title: t(`${optionsTranslationPrefix}.carpooling`), value: MainTransport.CARPOOLING },
    {
      icon: <LargeCarIcon />,
      title: t(`${optionsTranslationPrefix}.drivingAlone`),
      value: MainTransport.DRIVING_ALONE,
    },
  ];

  const fuelKindOptions = [
    {
      value: FuelKind.GASOLINE,
      label: t(fuelTranslationPrefix + '.fuel.types.gasoline'),
    },
    {
      value: FuelKind.DIESEL,
      label: t(fuelTranslationPrefix + '.fuel.types.diesel'),
    },
    {
      value: FuelKind.HYBRID,
      label: t(fuelTranslationPrefix + '.fuel.types.hybrid'),
    },
    {
      value: FuelKind.ELECTRIC_VEHICLE,
      label: t(fuelTranslationPrefix + '.fuel.types.electricVehicle'),
    },
  ];

  return {
    gettingToOfficeOptions,
    fuelKindOptions,
  };
};

export const GettingToOffice: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const options = useOptions();

  const isCarpooling = values?.gettingToOfficeTransportType === MainTransport.CARPOOLING;
  const isDrivingAlone = values?.gettingToOfficeTransportType === MainTransport.DRIVING_ALONE;

  return (
    <Root>
      <StepTitle>{t(`${pageTranslationPrefix}.title`)}</StepTitle>
      <StepDescription>{t(`${pageTranslationPrefix}.description`)}</StepDescription>
      <CardsGroup>
        {options.gettingToOfficeOptions.map(({ value, icon, title }) => (
          <Field
            key={value}
            name="gettingToOfficeTransportType"
            type="radio"
            value={value}
            validate={validators.isRequired}>
            {({ input }) => <CardRadioButton id={`${value}-option`} icon={icon} title={title} {...input} />}
          </Field>
        ))}
      </CardsGroup>
      {(isCarpooling || isDrivingAlone) && (
        <InputsGroup>
          <Field<string> name="transportFuelKind" validate={validators.isRequired}>
            {field => (
              <StyledSelectField
                options={options.fuelKindOptions}
                label={t(fuelTranslationPrefix + '.fuel.label')}
                {...field}
              />
            )}
          </Field>
          {isCarpooling && (
            <Field
              name="numberOfCompanions"
              type="text"
              parse={formParsers.toNumber}
              format={formFormatters.numberToString}
              validate={combineValidators(
                validators.isNumber,
                validators.isNegativeNumber,
                validators.isRequired,
                validators.isZero,
              )}>
              {field => (
                <StyledInput
                  label={t(fuelTranslationPrefix + '.numberOfCompanions.label')}
                  placeholder="0"
                  {...field}
                />
              )}
            </Field>
          )}
        </InputsGroup>
      )}
      {isDrivingAlone && (
        <Field type="checkbox" name="isUsingLKQFuel">
          {({ input }) => <RadioButton text={t(fuelTranslationPrefix + '.isUsingLKQFuel.label')} {...input} />}
        </Field>
      )}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;

const CardsGroup = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 13px;
  @media ${DEVICE.tablet} {
    justify-content: flex-start;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fit, 195px);
  }
`;

const InputsGroup = styled.div`
  padding-top: 24px;
  margin-bottom: 20px;
  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  & > div {
    margin-bottom: 0;
  }
`;
