import { TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { AirlineTicketClass } from 'common/types';
import { ChipRadioButton } from 'components';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const translationPrefix = 'net0-commute.pages.businessTravel';

type Props = {
  fieldName: string;
  fieldIndex?: number;
  label?: string;
  className?: string;
};

export const FlightServiceClassField: FC<Props> = ({ fieldName, fieldIndex = 0, label, className }) => {
  const { t } = useTranslation();

  const flightServiceClassOptions = [
    { value: AirlineTicketClass.ECONOMY, label: t(translationPrefix + '.options.flightServiceClassOptions.economy') },
    { value: AirlineTicketClass.BUSINESS, label: t(translationPrefix + '.options.flightServiceClassOptions.business') },
    {
      value: AirlineTicketClass.FIRST_CLASS,
      label: t(translationPrefix + '.options.flightServiceClassOptions.firstClass'),
    },
    {
      value: AirlineTicketClass.PRIVATE_JET,
      label: t(translationPrefix + '.options.flightServiceClassOptions.privateJet'),
    },
  ];

  return (
    <div className={className}>
      {label && <Label>{label}</Label>}
      <ChipsGroup>
        {flightServiceClassOptions.map(({ label, value }) => {
          return (
            <Field key={value} name={fieldName} type="radio" value={value}>
              {({ input }) => (
                <ChipRadioButton id={`${value + fieldIndex}-option`} {...input}>
                  {label}
                </ChipRadioButton>
              )}
            </Field>
          );
        })}
      </ChipsGroup>
    </div>
  );
};

const ChipsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 20px;
`;

const Label = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;
