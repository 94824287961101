import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Button } from '@net0-calc/common/components/ui';
import React, { FC } from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

type Props = {
  name: string;
  isDetailedForm: boolean;
  className?: string;
};

export const DetailedFormSwitch: FC<Props> = ({ name, isDetailedForm, className }) => {
  const { t } = useTranslation();
  return (
    <Root className={className}>
      <Field name={name}>
        {({ input }) => {
          const onClick = () => {
            input.onChange(false);
          };
          return (
            <StyledButton
              title={t('common.formTabBar.light')}
              type="button"
              variant={isDetailedForm ? 'ternary' : 'primary'}
              onClick={onClick}
            />
          );
        }}
      </Field>
      <Field name={name}>
        {({ input }) => {
          const onClick = () => {
            input.onChange(true);
          };
          return (
            <StyledButton
              title={t('common.formTabBar.detailed')}
              type="button"
              variant={isDetailedForm ? 'primary' : 'ternary'}
              onClick={onClick}
            />
          );
        }}
      </Field>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  background: var(--color8);
  gap: 12px;
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    width: 232px;
    position: absolute;
    right: max(-55%, -18vw);
    margin-bottom: 0;
  }

  @media ${DEVICE.desktop} {
    right: max(-55%, -23vw);
  }
`;

const StyledButton = styled(Button)`
  ${TYPOGRAPHY.captionRegular12}
  height: 32px;
  border-radius: 10px;
`;
