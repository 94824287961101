import React from 'react';
import styled from 'styled-components';
import { formatWeightValue } from 'utils';

type Props = {
  value: number;
};

export const TotalFootprintValue: React.FC<Props> = ({ value }) => {
  return <Root>{formatWeightValue(value)}</Root>;
};

const Root = styled.span`
  color: var(--color2);
  white-space: nowrap;
`;
