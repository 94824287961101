import * as React from 'react';
import { SVGProps } from 'react';

const SvgAttentionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#attention-icon_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.667 10A8.333 8.333 0 1 0 10 1.667 8.333 8.333 0 0 0 1.667 10Zm13.047 4.714a6.667 6.667 0 1 1-9.428-9.428 6.667 6.667 0 0 1 9.428 9.428Zm-3.88-.547v-5c0-1.25-1.667-1.25-1.667 0v5c0 1.25 1.666 1.25 1.666 0ZM10 7.5a.833.833 0 1 0 0-1.667.833.833 0 0 0 0 1.667Z"
        fill="#797979"
      />
    </g>
    <defs>
      <clipPath id="attention-icon_svg__a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgAttentionIcon;
