import { DEVICE } from '@net0-calc/common/assets/styles';
import { QuestionTitle, StepInStepItem } from '@net0-calc/common/components/ui';
import { WizardPageProps } from '@net0-calc/common/types';
import { CalculatorFormValues, Device } from 'common/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FieldsArrayBlock } from './components';

const translationPrefix = 'net0-commute.pages.devices';
const optionsTranslationPrefix = translationPrefix + '.fields.device.options';

export const Devices: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  const mainDevicesOptions = [
    { label: t(optionsTranslationPrefix + '.laptop'), value: Device.LAPTOP },
    { label: t(optionsTranslationPrefix + '.monitor'), value: Device.MONITOR },
    { label: t(optionsTranslationPrefix + '.printer'), value: Device.PRINTER },
    { label: t(optionsTranslationPrefix + '.other'), value: Device.OTHER },
  ];
  const additionalDevicesOptions = [
    { label: t(optionsTranslationPrefix + '.lamp'), value: Device.LAMP },
    { label: t(optionsTranslationPrefix + '.spaceHeater'), value: Device.SPACE_HEATER },
  ];

  return (
    <Root>
      <QuestionBlock>
        <StyledStepInStepItem stepNumber={1} />
        <QuestionTitle>{t(translationPrefix + '.devicesForYourWorkFromHome.title')}</QuestionTitle>
        <FieldsArrayBlock
          fieldName="devicesForYourWorkFromHome"
          deviceOptions={mainDevicesOptions}
          values={values?.devicesForYourWorkFromHome}
          maxFields={4}
        />
      </QuestionBlock>
      <SecondQuestionBlock>
        <StyledStepInStepItem stepNumber={2} />
        <QuestionTitle>{t(translationPrefix + '.additionalDevicesForYourWorkFromHome.title')}</QuestionTitle>
        <FieldsArrayBlock
          fieldName="additionalDevicesForYourWorkFromHome"
          deviceOptions={additionalDevicesOptions}
          values={values?.additionalDevicesForYourWorkFromHome}
          maxFields={2}
        />
      </SecondQuestionBlock>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;

const QuestionBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 12px);

  border-left: 2px solid var(--color14);

  padding: 0 0 40px 19px;
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    padding: 0 0 70px 37px;
    margin-left: 22px;
  }
`;

const SecondQuestionBlock = styled(QuestionBlock)`
  padding: 0 0 0 19px;
  border-left: none;

  @media ${DEVICE.laptop} {
    padding: 0 0 0 37px;
  }
`;

const StyledStepInStepItem = styled(StepInStepItem)`
  background-color: var(--color14);
`;
