export const TYPOGRAPHY = {
  titleSemibold44: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 44px;
    line-height: 66px;
  `,
  titleSemibold36: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 36px;
    line-height: 54px;
  `,
  titleSemibold32: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
  `,
  titleSemibold28: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
  `,
  titleRegular28: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;
  `,
  titleSemibold24: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
  `,
  bodyMedium20: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  `,
  boydRegular20: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
  `,
  bodySemibold18: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  `,
  bodyMedium16: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  `,
  bodyRegular16: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  `,
  captionRegular14: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  `,
  captionMedium12: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
  `,
  captionRegular12: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
  `,
  captionRegular10: `
    font-family: 'Poppins', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
  `,
};
