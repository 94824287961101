import { TimeUtil } from '@net0-calc/common/utils';
import { CalculatorFormValues, DistanceUnit, FuelKind, MainTransport } from 'common/types';
import { Co2FootprintVO } from 'vo';
import { calculateWeeksInYear } from './calculate-weeks-in-year';

// Take from https://docs.google.com/document/d/1vRW0wE66YF7N3S3nZKhcyoETl8eW3oNTRniCE3YGGR4/edit#

const NUMBER_OF_MOVEMENTS = 2;

const COEFFICIENT_BY_TRANSPORT_TYPE = {
  [MainTransport.WALKING]: 0,
  [MainTransport.BIKING]: 0,
  [MainTransport.DRIVING_ALONE]: 0,
  [MainTransport.CARPOOLING]: 0,
  [MainTransport.PUBLIC_TRANSPORT]: 0.10312,
};

const COEFFICIENT_BY_DISTANCE_TYPE = {
  [DistanceUnit.KILOMETERS]: 1,
  [DistanceUnit.MILES]: 1.60934,
};

const COEFFICIENT_BY_FUEL_TYPE_FOR_KILOMETERS = {
  [FuelKind.GASOLINE]: 0.1743,
  [FuelKind.DIESEL]: 0.16844,
  [FuelKind.HYBRID]: 0.11558,
  [FuelKind.ELECTRIC_VEHICLE]: 0.09712,
};

const COEFFICIENT_BY_FUEL_TYPE_FOR_MILES = {
  [FuelKind.GASOLINE]: 0.28052,
  [FuelKind.DIESEL]: 0.27108,
  [FuelKind.HYBRID]: 0.18601,
  [FuelKind.ELECTRIC_VEHICLE]: 0.1563,
};

const FUEL_COEFFICIENT_BY_DISTANCE_UNIT = {
  [DistanceUnit.KILOMETERS]: COEFFICIENT_BY_FUEL_TYPE_FOR_KILOMETERS,
  [DistanceUnit.MILES]: COEFFICIENT_BY_FUEL_TYPE_FOR_MILES,
};

type Props = Pick<
  CalculatorFormValues,
  | 'userReportYear'
  | 'numberOfAnnuallyVacationDays'
  | 'gettingToOfficeTransportType'
  | 'transportFuelKind'
  | 'numberOfCompanions'
  | 'numberOfDaysOfWeekWhenAnEmployeeCommuteToWork'
  | 'distanceFromHomeToOffice'
  | 'distanceFromHomeToOfficeUnit'
  | 'averageFuelEfficiencyOfYourVehicle'
  | 'averageFuelEfficiencyOfYourVehicleKind'
>;

type CalculatorParams = {
  totalDistancePerYear: number;
  distanceUnit: DistanceUnit;
  numberOfCompanions?: number;
  transportFuelKind?: FuelKind;
  averageFuelEfficiencyOfYourVehicle?: number;
};

type CalculateTotalDistancePerYearArgs = {
  distance: number;
  daysOfWeek: number;
  reportingYear: number;
  annualVacationDaysCount: number;
};

const calculateTotalDistancePerYear = ({
  reportingYear,
  distance,
  annualVacationDaysCount,
  daysOfWeek,
}: CalculateTotalDistancePerYearArgs) => {
  return (
    NUMBER_OF_MOVEMENTS *
    calculateWeeksInYear({
      year: reportingYear,
      annualVacationDaysCount,
    }) *
    distance *
    daysOfWeek
  );
};

const calculate: Record<MainTransport, (params: CalculatorParams) => Co2FootprintVO> = {
  [MainTransport.WALKING]: () => Co2FootprintVO.create(0, 'kg'),
  [MainTransport.BIKING]: () => Co2FootprintVO.create(0, 'kg'),
  [MainTransport.PUBLIC_TRANSPORT]: ({ totalDistancePerYear, distanceUnit }) => {
    const total =
      totalDistancePerYear *
      COEFFICIENT_BY_DISTANCE_TYPE[distanceUnit] *
      COEFFICIENT_BY_TRANSPORT_TYPE.PUBLIC_TRANSPORT;

    return Co2FootprintVO.create(total, 'kg');
  },
  [MainTransport.DRIVING_ALONE]: ({
    totalDistancePerYear,
    distanceUnit,
    transportFuelKind,
    averageFuelEfficiencyOfYourVehicle,
  }) => {
    if (!transportFuelKind) return Co2FootprintVO.create(0, 'kg');

    let total = 0;

    if (!averageFuelEfficiencyOfYourVehicle || !averageFuelEfficiencyOfYourVehicle) {
      total = totalDistancePerYear * FUEL_COEFFICIENT_BY_DISTANCE_UNIT[distanceUnit][transportFuelKind];

      return Co2FootprintVO.create(total, 'kg');
    }

    total =
      (totalDistancePerYear * FUEL_COEFFICIENT_BY_DISTANCE_UNIT[distanceUnit][transportFuelKind]) /
      averageFuelEfficiencyOfYourVehicle;

    return Co2FootprintVO.create(total, 'kg');
  },
  [MainTransport.CARPOOLING]: ({
    distanceUnit,
    totalDistancePerYear,
    averageFuelEfficiencyOfYourVehicle,
    transportFuelKind,
    numberOfCompanions,
  }) => {
    if (!transportFuelKind || !numberOfCompanions) return Co2FootprintVO.create(0, 'kg');
    let total = 0;

    if (averageFuelEfficiencyOfYourVehicle) {
      total =
        (totalDistancePerYear * FUEL_COEFFICIENT_BY_DISTANCE_UNIT[distanceUnit][transportFuelKind]) /
        (averageFuelEfficiencyOfYourVehicle * numberOfCompanions);

      return Co2FootprintVO.create(total, 'kg');
    }

    total =
      (totalDistancePerYear * FUEL_COEFFICIENT_BY_DISTANCE_UNIT[distanceUnit][transportFuelKind]) / numberOfCompanions;

    return Co2FootprintVO.create(total, 'kg');
  },
};

export default ({
  gettingToOfficeTransportType,
  numberOfCompanions,
  transportFuelKind,
  averageFuelEfficiencyOfYourVehicle,
  distanceFromHomeToOffice = 0,
  distanceFromHomeToOfficeUnit,
  numberOfDaysOfWeekWhenAnEmployeeCommuteToWork = 0,
  userReportYear = TimeUtil.getYear(TimeUtil.now().toDate()),
  numberOfAnnuallyVacationDays = 0,
}: Props): Co2FootprintVO => {
  if (!gettingToOfficeTransportType) return Co2FootprintVO.create(0, 'kg');

  if (
    distanceFromHomeToOffice <= 0 ||
    numberOfDaysOfWeekWhenAnEmployeeCommuteToWork <= 0 ||
    !distanceFromHomeToOfficeUnit
  ) {
    return Co2FootprintVO.create(0, 'kg');
  }

  const totalDistancePerYear = calculateTotalDistancePerYear({
    distance: distanceFromHomeToOffice,
    daysOfWeek: numberOfDaysOfWeekWhenAnEmployeeCommuteToWork,
    annualVacationDaysCount: numberOfAnnuallyVacationDays,
    reportingYear: userReportYear,
  });

  return calculate[gettingToOfficeTransportType]({
    distanceUnit: distanceFromHomeToOfficeUnit,
    totalDistancePerYear,
    transportFuelKind,
    numberOfCompanions,
    averageFuelEfficiencyOfYourVehicle,
  });
};
