import React, { FC, useMemo } from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { formatWeightValue } from 'utils';
import { Co2FootprintVO } from 'vo';
import * as utils from 'utils';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Co2FootprintsVOs } from 'common/types';

interface ChartDonutSectionProps {
  co2FootprintsVOs: Co2FootprintsVOs;
  className?: string;
}

const pageTranslationPrefix = 'net0-commute.pages.result';

export const ChartDonutSection: FC<ChartDonutSectionProps> = ({ co2FootprintsVOs, className }) => {
  const { t } = useTranslation();

  const co2FootprintPerYearVO = Co2FootprintVO.sum(Object.values(co2FootprintsVOs));
  const resultPerMonth = co2FootprintPerYearVO.kg / 12;

  const co2FootprintLevel = useMemo(() => {
    const co2FootprintPerMonthKg = co2FootprintPerYearVO.kg / 12;
    if (co2FootprintPerMonthKg <= 300) {
      return 'Low';
    }
    if (co2FootprintPerMonthKg >= 1500) {
      return 'High';
    }
    return 'Average';
  }, [co2FootprintPerYearVO.kg]);

  const co2footPrintLevelNames = {
    ['Low']: t(pageTranslationPrefix + '.donutChart.level.low'),
    ['Average']: t(pageTranslationPrefix + '.donutChart.level.average'),
    ['High']: t(pageTranslationPrefix + '.donutChart.level.hight'),
  };

  const results = [
    {
      markColor: 'var(--chart-color1)',
      text: t(pageTranslationPrefix + '.summary.values.gettingToOffice'),
      value: co2FootprintsVOs.gettingToOffice.stringifiedValuePerMonth,
    },
    {
      markColor: 'var(--chart-color3)',
      text: t(pageTranslationPrefix + '.summary.values.homeSetUp'),
      value: co2FootprintsVOs.workFromHome.stringifiedValuePerMonth,
    },
    {
      markColor: 'var(--chart-color5)',
      text: t(pageTranslationPrefix + '.summary.values.businessTravel'),
      value: co2FootprintsVOs.businessTravel.stringifiedValuePerMonth,
    },
  ];

  const renderResults = () => {
    return results.map(({ markColor, text, value }) => (
      <ResultLine key={text}>
        <ResultTitle>
          <Mark $color={markColor} />
          {text}
        </ResultTitle>
        <ResultValue>{value}</ResultValue>
      </ResultLine>
    ));
  };

  return (
    <Root className={className}>
      <StyledChart
        options={{
          states: {
            hover: {
              filter: {
                type: 'none',
              },
            },
          },
          stroke: { width: 0 },
          colors: ['var(--chart-color1)', 'var(--chart-color3)', 'var(--chart-color5)'],
          legend: {
            show: false,
          },
          tooltip: {
            enabled: true,
            y: {
              formatter: (value: number) => {
                return utils.formatWeightValue(value);
              },
            },
          },
          labels: [
            t(pageTranslationPrefix + '.summary.values.gettingToOffice'),
            t(pageTranslationPrefix + '.summary.values.homeSetUp'),
            t(pageTranslationPrefix + '.summary.values.businessTravel'),
          ],
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    offsetY: 1,
                    formatter: () => formatWeightValue(resultPerMonth),
                  },
                  value: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    offsetY: 10,
                    formatter: () => t(pageTranslationPrefix + '.donutChart.label'),
                  },
                  total: {
                    show: true,
                    fontFamily: 'Poppins',
                    color: 'var(--color6)',
                    label: formatWeightValue(resultPerMonth),
                    formatter: () => t(pageTranslationPrefix + '.donutChart.label'),
                  },
                },
              },
            },
          },
        }}
        series={[
          co2FootprintsVOs.gettingToOffice.formattedKg,
          co2FootprintsVOs.workFromHome.formattedKg,
          co2FootprintsVOs.businessTravel.formattedKg,
        ]}
        type="donut"
      />
      <Text>{t(pageTranslationPrefix + '.donutChart.levelDescription')}</Text>
      <FootprintLevel>{co2footPrintLevelNames[co2FootprintLevel]}</FootprintLevel>
      {renderResults()}
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  padding: 28px 0 18px;
  border-radius: 20px;
  border: 2px solid var(--color8);
  margin: 0 auto;

  @media ${DEVICE.tablet} {
    min-width: 300px;
    max-width: 340px;
  }
  @media ${DEVICE.laptop} {
    margin: 0;
  }
`;

const Text = styled.div`
  margin-bottom: 16px;
  padding: 0 30px;
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  text-align: center;
`;

const FootprintLevel = styled.div`
  ${TYPOGRAPHY.titleSemibold32}
  color: var(--color6);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
`;

const StyledChart = styled(Chart)`
  position: relative;
  margin-bottom: 17px;

  .apexcharts-datalabel-label {
    ${TYPOGRAPHY.titleSemibold24}
    color: var(--color6);
  }

  .apexcharts-datalabel-value {
    ${TYPOGRAPHY.bodyMedium16}
    fill: var(--color6);
  }
`;
const ResultLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0px 20px;
  &:last-child {
    margin-bottom: 0;
  }
  color: var(--color6);
`;

const Mark = styled.span<{ $color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 6px;
  background-color: ${({ $color }) => $color};
  margin-right: 5px;
`;

const ResultTitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  color: var(--color6);

  ${TYPOGRAPHY.captionRegular12};
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyRegular16};
  }
`;

const ResultValue = styled.div`
  ${TYPOGRAPHY.captionRegular12};
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyRegular16};
  }
`;
