import { StylesConfig } from 'react-select';
import { Option } from 'types';

export const customStyles: Partial<StylesConfig<Option, false>> = {
  container: (base, { isFocused }) => ({
    ...base,
    borderRadius: '14px',
    backgroundColor: 'var(--color5)',
    width: '100%',
    border: `2px solid ${isFocused ? 'var(--color2)' : 'var(--color10)'}`,
    transition: '0.2s ease-out',
  }),
  control: base => ({
    ...base,
    maxHeight: `64px`,
    boxShadow: 'none',
    border: 4,
    background: 'transparent',
    padding: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: base => ({
    ...base,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
  }),
  valueContainer: base => ({
    ...base,
    display: 'block',
    maxWidth: '100%',
    padding: 0,
    overflow: 'auto',
    ['::-webkit-scrollbar']: {
      display: 'none',
    },
  }),
  singleValue: base => ({
    ...base,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: base => ({
    ...base,
    height: '24px',
    padding: `0 2px`,
    transition: '0.2s ease-out',
  }),
  menu: base => ({
    ...base,
    top: `calc(100% + 8px)`,
    margin: 0,
    zIndex: 1000,
    padding: 0,
    borderRadius: `8px`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden',
  }),
  menuList: base => ({
    ...base,
    padding: `12px 0`,
    maxHeight: `203px`,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: `6px 20px`,
    minHeight: `36px`,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    color: 'var(--color6)',
    background: isSelected ? 'var(--color14)' : 'var(--color5)',
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '&:hover': {
      background: 'var(--color8)',
      width: '100%',
    },
  }),
};
