import { TYPOGRAPHY, DEVICE } from '../../../../../assets/styles';
import { RightArrowIcon } from '../../../../../assets/svg';
import Calendar from 'react-calendar';
import styled from 'styled-components/macro';
import { FC, useRef } from 'react';
import { FieldInputProps } from 'react-final-form';
import { TimeUtil } from '../../../../../utils';
import { SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER } from '../../../../../constants/date';
import { useTranslation } from 'react-i18next';
import { useOnOutsideClick } from '../../../../../hooks/useOnOutsideClick';

interface DesktopModalProps extends FieldInputProps<[Date, Date]> {
  isVisible: boolean;
  onCloseModal: () => void;
  locale: string;
}

export const DesktopModal: FC<DesktopModalProps> = ({
  isVisible,
  defaultValue,
  value,
  onChange,
  onCloseModal,
  locale,
}) => {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  useOnOutsideClick(ref, onCloseModal);

  return (
    <CalendarWrapper ref={ref} $isVisible={isVisible}>
      <TitleWrap>
        <DateTitleText $hasDate={value.length > 0 && !!value[0].toString()}>
          {(value.length > 0 && TimeUtil.getFormatted(value[0], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
            t('components.desktopModal.startDate')}
        </DateTitleText>
        <IconWrap>
          <RightArrowIcon />
        </IconWrap>
        <DateTitleText $hasDate={value.length === 2 && !!value.toString()}>
          {(value.length > 1 && TimeUtil.getFormatted(value[1], SHORT_DAY_AND_SHORT_MONTH_AND_DAY_NUMBER)) ||
            t('components.desktopModal.endDate')}
        </DateTitleText>
      </TitleWrap>
      <Calendar
        value={value}
        prev2Label={null}
        next2Label={null}
        locale={locale}
        minDetail="month"
        selectRange
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </CalendarWrapper>
  );
};

const CalendarWrapper = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  background-color: var(--color5);

  position: absolute;
  top: 0;
  z-index: 1;
  height: auto;
  padding: 16px 0px 12px;
  box-shadow: 0px 4px 20px 0px #201e3f1a;
  border-radius: 20px;
  width: 350px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;

  @media ${DEVICE.desktop} {
    top: calc(100% + 12px);
  }

  & .react-calendar {
    width: 100%;
    padding: 0px 48px 20px;
    margin-bottom: 12px;
    z-index: 200;

    &__navigation {
      display: flex;
      position: relative;
      margin-bottom: 10px;

      &__prev-button {
        position: absolute;
        right: 40px;
        width: 28px;
        height: 28px;
        align-self: center;
        order: 1;
        background-color: var(--color8);
        border-radius: 14px;
        cursor: pointer;
      }

      &__next-button {
        position: absolute;
        right: 0;
        width: 28px;
        height: 28px;
        background-color: var(--color8);
        border-radius: 14px;
        cursor: pointer;
        order: 2;
        align-self: center;
      }

      &__label {
        display: flex;
        justify-content: space-around;

        &__divider {
          display: none;
        }

        &__labelText {
          ${TYPOGRAPHY.bodyMedium16}
          color: var(--color6);
          align-self: center;
        }

        @media ${DEVICE.tablet} {
          top: -160%;
          justify-content: space-between;
        }
      }
    }

    &__viewContainer {
      display: flex;
      flex-direction: column;

      @media ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: center;
      }
    }

    &__month-view__weekdays {
      display: grid !important;
      grid-template-columns: repeat(7, 36px);
      grid-column-gap: 3px;
      justify-content: center;
      margin-bottom: 3px;

      &__weekday {
        ${TYPOGRAPHY.captionRegular12}
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 36px;
        height: 36px;
        & > abbr {
          text-decoration: none;
        }
      }
    }

    &__month-view__days {
      display: grid !important;
      grid-template-columns: repeat(7, 36px);
      grid-template-rows: repeat(5, 36px);
      justify-content: center;
      grid-gap: 4px;
    }

    &__tile {
      max-width: 36px;
      height: 36px;
      border: 1px solid var(--color10);
      border-radius: 4px;
      cursor: pointer;
      color: var(--color9) !important;

      &--range {
        border: 1px solid var(--color14);
        background-color: var(--color14);
        color: var(--color2) !important;
      }
      &--rangeStart {
        border: 1px solid var(--color2) !important;
        background-color: var(--color2) !important;
        color: var(--color5) !important;
      }
      &--rangeEnd {
        border: 1px solid var(--color2) !important;
        background-color: var(--color2) !important;
        color: var(--color5) !important;
      }
    }
  }
`;

const DateTitleText = styled.div<{ $hasDate: boolean }>`
  ${TYPOGRAPHY.bodyMedium20}

  color: ${({ $hasDate }) => ($hasDate ? 'var(--color6)' : 'var(--color9)')};
`;

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px;
  margin-bottom: 16px;

  margin-top: 20px;

  @media ${DEVICE.laptop} {
    margin-top: 0;
  }
`;

const IconWrap = styled.div`
  margin: 0 16px;
`;
