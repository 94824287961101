import * as React from 'react';
import { SVGProps } from 'react';
const SvgPolandFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#poland-flag-icon_svg__a)">
      <path fill="#AF010D" d="M0 3h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3h24v9.067H0V3Z" fill="#fff" />
    </g>
    <defs>
      <clipPath id="poland-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPolandFlagIcon;
