import * as React from 'react';
import { SVGProps } from 'react';
const SvgCanadaFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#canada-flag-icon_svg__a)">
      <path fill="#fff" d="M0 2.857h24v17H0z" />
      <path
        d="M12.235 16.134h-.47l.117-1.882c-.006-.099-.054-.125-.235-.118l-1.882.236s.353-.47.353-.706c0-.235-2.118-1.765-2.118-1.765s.47-.117.588-.235c.118-.118-.47-1.412-.47-1.412s1.195.47 1.294.353c.098-.118.235-.588.235-.588s.941 1.059 1.176 1.059c.236 0-.47-2.589-.47-2.589s.588.471.824.471c.235 0 .823-1.53.823-1.53s.588 1.53.706 1.53c.117 0 .941-.47.941-.47s-.588 2.47-.47 2.588c.117.117 1.176-1.06 1.176-1.06s.118.471.235.59c.118.117 1.294-.354 1.294-.354s-.588 1.294-.47 1.412c.117.117.588.235.588.235s-2.118 1.53-2.118 1.765c0 .235.236.706.236.706l-1.765-.236c-.142-.04-.191-.01-.235.118l.117 1.882ZM18.286 2.857H24V20h-5.714zM0 2.857h5.714V20H0z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="canada-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 2.857)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCanadaFlagIcon;
