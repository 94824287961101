import { MapPoint } from '../types';

/**
 * How to calculate the straight line distance from latitude and longitude
 * https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
 */
export function calculateKmDistanceBetweenMapPoints(mapPoint1: MapPoint, mapPoint2: MapPoint) {
  const R = 6371.071; // Radius of the Earth in km
  const rlat1 = mapPoint1.lat * (Math.PI / 180); // Convert degrees to radians
  const rlat2 = mapPoint2.lat * (Math.PI / 180); // Convert degrees to radians
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = (mapPoint2.lng - mapPoint1.lng) * (Math.PI / 180); // Radian difference (longitudes)

  const distance =
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2),
      ),
    );

  return distance;
}
