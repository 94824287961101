import * as React from 'react';
import { SVGProps } from 'react';
const SvgFileDownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={36} height={36} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.125 31.5H7.875a1.125 1.125 0 0 1-1.125-1.125V5.625A1.125 1.125 0 0 1 7.875 4.5h13.5l7.875 7.875v18a1.125 1.125 0 0 1-1.125 1.125Z"
      stroke="#079664"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.375 4.5v7.875h7.875M14.063 21.938 18 25.875l3.938-3.938M18 16.875v9"
      stroke="#079664"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFileDownloadIcon;
