import { toast } from 'services';
import { t } from 'i18next';

export const copyResultsLink = (answerId: string | null) => {
  if (answerId) {
    navigator.clipboard.writeText(`${window.location.origin}/results/${answerId}`).then(() => {
      toast.success(t('common.receiveResults.toastMessages.resultLinkIsCopied'));
    });
  }
};
