import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeTaxiIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.5 42.5H50a2.5 2.5 0 0 0 2.5-2.5v-8.05a2.5 2.5 0 0 0-1.9-2.5l-8.1-1.95-6.75-9a2.5 2.5 0 0 0-2-1h-14.7a2.5 2.5 0 0 0-2.25 1.375L12.5 27.5l-3.6 1.775a2.5 2.5 0 0 0-1.4 2.25V40a2.5 2.5 0 0 0 2.5 2.5h2.5"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.5 42.5h15-15Zm5-25v10-10Zm15 10h-30 30Zm-25 10a5 5 0 1 0 0 10 5 5 0 0 0 0-10v0Zm25 0a5 5 0 1 0 0 10 5 5 0 0 0 0-10v0ZM30 17.5h-5v-5h5v5Z"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLargeTaxiIcon;
