import { calculateKmDistanceBetweenMapPoints } from '@net0-calc/common/utils';
import {
  AirlineTicketClass,
  CalculatorFormValues,
  FlightDurationKind,
  TravelTransportType,
  TypeOfTrip,
} from 'common/types';
import { Co2FootprintVO } from 'vo';

//Take from https://docs.google.com/document/d/1vRW0wE66YF7N3S3nZKhcyoETl8eW3oNTRniCE3YGGR4/edit#

const SHORT_FLIGHT_DISTANCE_IN_KILOMETERS = 1500;
const MID_RANGE_FLIGHT_DISTANCE_IN_KILOMETERS = 3500;
const LONG_FLIGHT_DISTANCE_IN_KILOMETERS = 5000;

const AIR_TRANSPORT_COEFFICIENT = {
  [AirlineTicketClass.ECONOMY]: {
    [FlightDurationKind.SHORT]: 0.09245,
    [FlightDurationKind.MID_RANGE]: 0.08754,
    [FlightDurationKind.LONG_HAUL]: 0.08263,
  },
  [AirlineTicketClass.BUSINESS]: {
    [FlightDurationKind.SHORT]: 0.13867,
    [FlightDurationKind.MID_RANGE]: 0.18915,
    [FlightDurationKind.LONG_HAUL]: 0.23963,
  },
  [AirlineTicketClass.FIRST_CLASS]: {
    [FlightDurationKind.SHORT]: 0.13867,
    [FlightDurationKind.MID_RANGE]: 0.234595,
    [FlightDurationKind.LONG_HAUL]: 0.33052,
  },
  [AirlineTicketClass.PRIVATE_JET]: {
    [FlightDurationKind.SHORT]: 0.9215,
    [FlightDurationKind.MID_RANGE]: 0.9984025,
    [FlightDurationKind.LONG_HAUL]: 1.075305,
  },
};

export const TRANSPORT_COEFFICIENT = {
  [TravelTransportType.AIRPLANE]: AIR_TRANSPORT_COEFFICIENT,
  [TravelTransportType.TRAIN]: 0.101,
  [TravelTransportType.CAR]: 0.16844,
  [TravelTransportType.OTHER_TRANSPORT]: 0.13472,
};

const TRIP_OF_TYPE_COEFFICIENT = {
  [TypeOfTrip.ONE_WAY]: 1,
  [TypeOfTrip.RETURN]: 2,
};

type CalculateSimpleModeParams = Pick<CalculatorFormValues, 'simpleBusinessTravelValues'>;

type CalculationAdvancedModePArams = Pick<CalculatorFormValues, 'advancedBusinessTravelValues'>;

type Props = Pick<CalculatorFormValues, 'isDetailedBusinessTravel'> &
  CalculateSimpleModeParams &
  CalculationAdvancedModePArams;

const getFLightDurationKindByKMDistance = (distance: number) => {
  if (distance <= SHORT_FLIGHT_DISTANCE_IN_KILOMETERS) return FlightDurationKind.SHORT;
  if (distance <= MID_RANGE_FLIGHT_DISTANCE_IN_KILOMETERS) return FlightDurationKind.MID_RANGE;
  return FlightDurationKind.LONG_HAUL;
};

const calculateSimpleMode = ({ simpleBusinessTravelValues }: CalculateSimpleModeParams) => {
  if (!simpleBusinessTravelValues) return Co2FootprintVO.create(0, 'kg');

  const total = simpleBusinessTravelValues.reduce((acc, trip) => {
    if (!trip?.businessTravelTransportType) return acc;

    if (trip.businessTravelTransportType === TravelTransportType.AIRPLANE) {
      if (!trip?.flightServiceClass || !trip?.businessTravelTransportType) return acc;

      const airplaneCoefficient = TRANSPORT_COEFFICIENT[trip?.businessTravelTransportType][trip?.flightServiceClass];
      acc =
        acc +
        (trip?.numberOfSingleLongHaulFlight || 0) * airplaneCoefficient.LONG_HAUL * LONG_FLIGHT_DISTANCE_IN_KILOMETERS +
        (trip?.numberOfSingleMidRangeFlights || 0) *
          airplaneCoefficient.MID_RANGE *
          MID_RANGE_FLIGHT_DISTANCE_IN_KILOMETERS +
        (trip?.numberOfSingleShortFlights || 0) * airplaneCoefficient.SHORT * SHORT_FLIGHT_DISTANCE_IN_KILOMETERS;

      return acc;
    }

    if (!trip?.averageBusinessTripDistance || !trip?.numberOfBusinessTrip) return acc;

    acc =
      acc +
      trip.averageBusinessTripDistance *
        trip.numberOfBusinessTrip *
        TRANSPORT_COEFFICIENT[trip.businessTravelTransportType];

    return acc;
  }, 0);

  return Co2FootprintVO.create(total, 'kg');
};

const calculateAdvancedMode = ({ advancedBusinessTravelValues }: CalculationAdvancedModePArams) => {
  let total = 0;

  if (!advancedBusinessTravelValues) return Co2FootprintVO.create(total, 'kg');

  total = advancedBusinessTravelValues.reduce((acc, trip) => {
    if (
      !trip ||
      !trip?.businessTravelTransportType ||
      !trip?.placeOfDeparture?.mapPoint ||
      !trip?.placeOfArrival?.mapPoint
    )
      return acc;

    const distance = calculateKmDistanceBetweenMapPoints(trip.placeOfDeparture.mapPoint, trip.placeOfArrival.mapPoint);

    if (trip.businessTravelTransportType === TravelTransportType.AIRPLANE) {
      if (!trip?.flightServiceClass) return acc;

      const flightDurationKind = getFLightDurationKindByKMDistance(distance);

      return (
        acc +
        distance *
          TRANSPORT_COEFFICIENT[trip.businessTravelTransportType][trip.flightServiceClass][flightDurationKind] *
          TRIP_OF_TYPE_COEFFICIENT[trip?.typeOfTrip || TypeOfTrip.ONE_WAY]
      );
    }

    return (
      acc +
      distance *
        TRANSPORT_COEFFICIENT[trip.businessTravelTransportType] *
        TRIP_OF_TYPE_COEFFICIENT[trip?.typeOfTrip || TypeOfTrip.ONE_WAY]
    );
  }, 0);

  return Co2FootprintVO.create(total, 'kg');
};

export default ({ advancedBusinessTravelValues, isDetailedBusinessTravel, simpleBusinessTravelValues }: Props) => {
  if (isDetailedBusinessTravel) {
    return calculateAdvancedMode({
      advancedBusinessTravelValues,
    });
  }
  return calculateSimpleMode({
    simpleBusinessTravelValues,
  });
};
