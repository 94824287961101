import * as React from 'react';
import { SVGProps } from 'react';
const SvgCzeckFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#czeck-flag-icon_svg__a)">
      <path fill="#F93939" d="M0 3h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 3h24v7.933H0V3Z" fill="#fff" />
      <path fillRule="evenodd" clipRule="evenodd" d="m0 3 11.429 8.5L0 20V3Z" fill="#1A47B8" />
    </g>
    <defs>
      <clipPath id="czeck-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCzeckFlagIcon;
