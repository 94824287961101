import { DEVICE, TYPOGRAPHY } from '../../../assets/styles';
import styled from 'styled-components';

export const StepTitle = styled.h1`
  ${TYPOGRAPHY.titleSemibold28}
  color: var(--color6);
  margin-bottom: 16px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold36}

    margin-bottom: 20px;
  }
`;

export const StepDescription = styled.p`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

export const QuestionTitle = styled.h1`
  ${TYPOGRAPHY.titleSemibold24}

  margin-bottom: 20px;
  color: var(--color6);

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold36}

    margin-bottom: 20px;
  }
`;
