import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { copyResultsLink } from 'utils/copy-results-link';
import { Button } from '@net0-calc/common/components/ui';
import { CopyLinkIcon } from '@net0-calc/common/assets/svg';

type Props = {
  answerId: string | null;
  className?: string;
  text?: string;
};
export const CopyResultLinkButton: FC<Props> = ({ answerId, className, text }) => {
  const { t } = useTranslation();
  const buttonTitle = text ? text : t('general.copyResultsLink.title');

  return (
    <Button
      icon={<CopyLinkIcon stroke="var(--color1)" />}
      onClick={() => {
        copyResultsLink(answerId);
      }}
      variant="secondary"
      title={buttonTitle}
      type="button"
      className={className}
    />
  );
};
