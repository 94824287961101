import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorageService } from 'services';
import { actions, selectors, useAppDispatch, useAppSelector } from 'store';
import { Language } from 'common/types';
import {
  BosniaFlagIcon,
  CanadaFlagIcon,
  ChinaFlagIcon,
  CzeckFlagIcon,
  DutchFlagIcon,
  FranceFlagIcon,
  GermanyFlagIcon,
  HungaryFlagIcon,
  ItalyFlagIcon,
  PolandFlagIcon,
  PortugueseFlagIcon,
  RomaniaFlagIcon,
  SlovakFlagIcon,
  SloveniaFlagIcon,
  SpanishFlagIcon,
  SwedenFlagIcon,
  TaiwanFlagIcon,
  UkrainFlagIcon,
  UnitedStatesFlagIcon,
} from '@net0-calc/common/assets/svg';
import { LanguageDropdownLayout } from '@net0-calc/common/components';

interface LanguageOption<T = Language> {
  icon: JSX.Element;
  name: T;
}

export const languagesOptions: LanguageOption[] = [
  {
    icon: <UnitedStatesFlagIcon />,
    name: Language.ENGLISH,
  },
  {
    icon: <GermanyFlagIcon />,
    name: Language.DEUTSCH,
  },
  {
    icon: <FranceFlagIcon />,
    name: Language.FRANÇAIS,
  },
  {
    icon: <CanadaFlagIcon />,
    name: Language.FRENCH_CA,
  },
  {
    icon: <ItalyFlagIcon />,
    name: Language.ITALIANO,
  },
  {
    icon: <BosniaFlagIcon />,
    name: Language.BOSNIAN,
  },
  {
    icon: <ChinaFlagIcon />,
    name: Language.CHINE_CN,
  },
  {
    icon: <TaiwanFlagIcon />,
    name: Language.CHINE_TW,
  },
  {
    icon: <CzeckFlagIcon />,
    name: Language.CZECK,
  },
  {
    icon: <DutchFlagIcon />,
    name: Language.DUTCH,
  },
  {
    icon: <HungaryFlagIcon />,
    name: Language.HUNGARIAN,
  },
  {
    icon: <PolandFlagIcon />,
    name: Language.POLISH,
  },
  {
    icon: <PortugueseFlagIcon />,
    name: Language.PORTUGUESE,
  },
  {
    icon: <RomaniaFlagIcon />,
    name: Language.ROMANIAN,
  },
  {
    icon: <SlovakFlagIcon />,
    name: Language.SLOVAK,
  },
  {
    icon: <SloveniaFlagIcon />,
    name: Language.SLOVENIAN,
  },
  {
    icon: <SwedenFlagIcon />,
    name: Language.SWEDISH,
  },
  {
    icon: <UkrainFlagIcon />,
    name: Language.UKRAINIAN,
  },
  {
    icon: <SpanishFlagIcon />,
    name: Language.SPANISH_419,
  },
];

export const LanguageDropdown: FC = () => {
  const { i18n } = useTranslation();
  const language = useAppSelector(selectors.dictionary.selectLanguage);
  const [currentLanguage, setCurrentLanguage] = useState(
    languagesOptions.find(({ name }) => name === language) as LanguageOption,
  );
  const dispatch = useAppDispatch();

  const handleSwitchLanguage = async (item: LanguageOption) => {
    if (item.name === language) {
      return;
    }
    setCurrentLanguage(item);
    await i18n.changeLanguage(item.name);
    dispatch(actions.dictionary.setLanguage(item.name));
    StorageService.setItem('language', item.name);
  };

  return (
    <LanguageDropdownLayout<Language>
      currentLanguage={currentLanguage}
      languagesOptions={languagesOptions}
      onSwitchLanguage={handleSwitchLanguage}
    />
  );
};
