import { SelectField } from '@net0-calc/common/components/ui';
import React, { FC } from 'react';
import styled from 'styled-components';
import { StylesConfig } from 'react-select';
import { Option } from '@net0-calc/common/types';
import { AttentionIcon } from '@net0-calc/common/assets/svg';
import { Field } from 'react-final-form';
import * as validators from '@pwc-commute/validators';
import { Tooltip } from '@net0-calc/common/components/ui/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';
import { DEVICE } from '@net0-calc/common/assets/styles';
import { TimeUtil } from '@net0-calc/common/utils';

type Props = {
  hasDifficultySwitch: boolean;
};

const yearOptions = TimeUtil.getLastNYears(4).map(year => ({ label: year.toString(), value: year }));

export const ReportYearField: FC<Props> = ({ hasDifficultySwitch }) => {
  const { t } = useTranslation();

  return (
    <Root $alignDown={hasDifficultySwitch}>
      <Tooltip anchorId="reportYearField" place="top" content={t('net0-commute.components.reportYearField.tooltip')} />
      <StyledInfoIcon id="reportYearField" />
      <Field name="userReportYear" validate={validators.isRequired}>
        {field => (
          <StyledSelectField
            customStylesProps={customStyles}
            options={yearOptions}
            isSearchable={false}
            label=""
            {...field}
          />
        )}
      </Field>
    </Root>
  );
};

const Root = styled.div<{ $alignDown: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 9px;
  order: -1 !important;
  margin-bottom: 12px;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media screen and ${DEVICE.laptop} {
    position: absolute;
    right: max(-55%, -18vw);
    top: ${props => (props.$alignDown ? '76px' : 0)};
    margin-bottom: 0;
    flex-direction: row;
    justify-content: flex-start;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 100px;
  height: 40px;
  & svg {
    path {
      stroke: var(--color13);
    }
  }
`;

const StyledInfoIcon = styled(AttentionIcon)`
  width: 20px;
  height: 20px;
  transform: translateY(3px);
  @media screen and ${DEVICE.laptop} {
    width: 16px;
    height: 16px;
  }
`;

export const customStyles: Partial<StylesConfig<Option, false>> = {
  container: (base, { isFocused }) => ({
    ...base,
    borderRadius: '14px',
    backgroundColor: 'var(--color5)',
    width: '100%',
    border: `2px solid ${isFocused ? 'var(--color2)' : 'var(--color13)'}`,
    transition: '0.2s ease-out',
  }),
  control: base => ({
    ...base,
    maxHeight: `40px`,
    boxShadow: 'none',
    border: 4,
    background: 'transparent',
    padding: '8px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: base => ({
    ...base,
    fontWeight: 'normal',
    fontSize: '6px',
    lineHeight: '10px',
    fontFamily: 'Poppins',
  }),
  valueContainer: base => ({
    ...base,
    display: 'block',
    maxWidth: '100%',
    padding: 0,
    overflow: 'auto',
    ['::-webkit-scrollbar']: {
      display: 'none',
    },
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--color13)',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: base => ({
    ...base,
    height: '24px',
    padding: `0 2px`,
    transition: '0.2s ease-out',
  }),
  menu: base => ({
    ...base,
    top: `calc(100% + 8px)`,
    margin: 0,
    zIndex: 1000,
    padding: 0,
    borderRadius: `8px`,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
    overflow: 'hidden',
  }),
  menuList: base => ({
    ...base,
    padding: `12px 0`,
    maxHeight: `203px`,
  }),
  option: (base, { isSelected }) => ({
    ...base,
    padding: `6px 20px`,
    minHeight: `36px`,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Poppins',
    color: 'var(--color13)',
    background: isSelected ? 'var(--color14)' : 'var(--color5)',
    transition: 'all cubic-bezier(0.4, 0, 0.2, 1) 0.15s',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    '&:hover': {
      background: isSelected ? 'rgba(125, 188, 106, 0.1)' : 'rgba(217, 217, 217, 0.1)',
      width: '100%',
    },
  }),
};
