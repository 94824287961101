import React, { FC } from 'react';
import { FieldArrayWrapper } from '@net0-calc/common/components/';
import { Field } from 'react-final-form';
import { Input, SelectField } from '@net0-calc/common/components/ui';
import styled from 'styled-components';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';
import * as validators from 'validators';
import { useTranslation } from 'react-i18next';
import { Option } from '@net0-calc/common/types';
import { DevicesFieldArrayValues } from 'common/types';
import { DEVICE } from '@net0-calc/common/assets/styles';

type Props = {
  fieldName: string;
  deviceOptions: Option[];
  maxFields?: number;
  values?: (DevicesFieldArrayValues | undefined)[];
};

const translationPrefix = 'net0-commute.pages.devices.fields';

export const FieldsArrayBlock: FC<Props> = ({ fieldName, deviceOptions, maxFields, values }) => {
  const { t } = useTranslation();

  return (
    <FieldArrayWrapper
      fieldsArrayName={fieldName}
      maxFieldsCount={maxFields}
      addBtnTitle={t(translationPrefix + '.addFieldsButton')}
      renderItem={(fieldName, index) => {
        const options = filterUniqueDevices(deviceOptions, index, values);

        return (
          <Root key={index}>
            <Field
              name={`${fieldName}.device`}
              label={t(translationPrefix + '.device.label')}
              options={options}
              component={StyledSelectField}
            />
            <FieldRow>
              <Field
                name={`${fieldName}.numberOfDevices`}
                type="text"
                label={t(translationPrefix + '.numberOfDevices.label')}
                parse={formParsers.toNumber}
                validate={combineValidators(validators.isNumber, validators.isNegativeNumber)}>
                {field => <StyledInput placeholder="0" {...field} />}
              </Field>
              <Field
                name={`${fieldName}.usageTimePerWeek`}
                type="text"
                label={t(translationPrefix + '.usageTimePerWeek.label')}
                parse={formParsers.toNumber}
                validate={combineValidators(
                  validators.isNumber,
                  validators.isNegativeNumber,
                  validators.isMaxValue(168),
                )}>
                {field => <StyledInput placeholder="0" {...field} />}
              </Field>
            </FieldRow>
          </Root>
        );
      }}
    />
  );
};

const filterUniqueDevices = (options: Option[], index: number, values?: (DevicesFieldArrayValues | undefined)[]) => {
  if (!values) {
    return options;
  }

  if (values.length === 1 && !values[0]?.device) {
    return options;
  }

  const uniqueOptions = options.filter(({ value }) => {
    if (value === values[index]?.device) return true;

    return !values.find(device => {
      return device?.device === value;
    });
  });

  return uniqueOptions;
};

const Root = styled.div`
  width: 100%;
`;

const FieldRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and ${DEVICE.laptop} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 24px;
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  & > div {
    margin-bottom: 0;
  }
`;
