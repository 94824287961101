import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeCarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.275 7.075h-17.55c-6.225 0-7.6 3.1-8.4 6.9L10.5 27.5h40l-2.825-13.525c-.8-3.8-2.175-6.9-8.4-6.9ZM55.475 49.55C55.75 52.475 53.4 55 50.4 55h-4.7c-2.7 0-3.075-1.15-3.55-2.575l-.5-1.5c-.7-2.05-1.15-3.425-4.75-3.425H24.1c-3.6 0-4.125 1.55-4.75 3.425l-.5 1.5C18.375 53.85 18 55 15.3 55h-4.7c-3 0-5.35-2.525-5.075-5.45l1.4-15.225C7.275 30.575 8 27.5 14.55 27.5h31.9c6.55 0 7.275 3.075 7.625 6.825l1.4 15.225ZM10.5 20H8M53 20h-2.5M30.5 7.5v5M26.75 12.5h7.5"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15.5 37.5H23M38 37.5h7.5" stroke="#B11425" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgLargeCarIcon;
