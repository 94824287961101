const Storage = window.localStorage;

class StorageService {
  getItem = (key: string) => {
    const item = Storage.getItem(key);
    return item;
  };
  setItem = (key: string, value: any) => {
    Storage.setItem(key, value.toString());
  };
  removeItem = (key: string) => {
    Storage.removeItem(key);
  };
}

export default new StorageService();
