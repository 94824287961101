import { TimeUtil } from '@net0-calc/common/utils';
import {
  CalculatorFormValues,
  DistanceUnit,
  FuelKind,
  TravelTransportType,
  TypeOfTrip,
  VehicleFuelEfficiencyKInd,
} from 'common/types';
import { useState } from 'react';

const initialFormValues: CalculatorFormValues = {
  transportFuelKind: FuelKind.GASOLINE,
  userReportYear: TimeUtil.getYear(new Date()),
  distanceFromHomeToOfficeUnit: DistanceUnit.MILES,
  averageFuelEfficiencyOfYourVehicleKind: VehicleFuelEfficiencyKInd.MILES_PER_GALLON,
  devicesForYourWorkFromHome: [undefined],
  additionalDevicesForYourWorkFromHome: [undefined],
  simpleBusinessTravelValues: [undefined],
  advancedBusinessTravelValues: [
    {
      businessTravelTransportType: TravelTransportType.AIRPLANE,
      typeOfTrip: TypeOfTrip.ONE_WAY,
    },
  ],
};

export const useFormValues = () => {
  const [formValues, setFormValues] = useState<CalculatorFormValues>(initialFormValues);
  return { initialFormValues, formValues, setFormValues } as const;
};
