import * as React from 'react';
import { SVGProps } from 'react';
const SvgSwedenFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#sweden-flag-icon_svg__a)">
      <path fill="#3A99FF" d="M0 3h24v17H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.857 13.2H0V9.8h6.857V3h3.429v6.8H24v3.4H10.286V20H6.857v-6.8Z"
        fill="#FFDA2C"
      />
    </g>
    <defs>
      <clipPath id="sweden-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSwedenFlagIcon;
