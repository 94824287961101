import { ReactElement, ReactNode } from 'react';
import { FormApi } from 'final-form';
import { Id } from 'react-toastify';

export enum Language {
  ENGLISH = 'en',
  DEUTSCH = 'de',
  FRANÇAIS = 'fr',
  ITALIANO = 'it',
}

export interface LanguageOption<T = Language> {
  icon: JSX.Element;
  name: T;
}

export interface Option<T = string> {
  value: T;
  label: string;
}

export interface WizardPageProps<T> {
  form?: FormApi<T, Partial<T>>;
  values?: T;
}

export interface StepItemProps<T, K> {
  name: K;
  title: string;
  icon: ReactNode;
  page: ReactElement<WizardPageProps<T>>;
}

export enum LoadingStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export type ReceiveResultByMailFormValues = {
  email: string;
  name?: string;
};

export type FormErrorValues = Pick<ReceiveResultByMailFormValues, 'email'>;

export type MapPoint = {
  lat: number;
  lng: number;
};

export interface ToastService {
  success: (content: string, title?: string) => Id;
  info: (content: string, title?: string) => Id;
  error: (content: string, title?: string) => Id;
  message: (content: string, title?: string, onClick?: () => void) => Id;
}
