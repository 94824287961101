import * as React from 'react';
import { SVGProps } from 'react';
const SvgDeviceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 16v.5h11v-11h-11V16Zm.5 1.5h-.5v4h11v-4H7ZM6.5 4v.5h11v-2h-11V4Zm7 15.5c.001 0 .001 0 0 0h.001-.001Zm-6.5 3c-.416 0-.76-.142-1.058-.44A1.427 1.427 0 0 1 5.5 21V3c0-.416.142-.76.442-1.06.298-.298.642-.44 1.058-.44h10c.416 0 .76.142 1.06.44.298.3.44.644.44 1.06v18c0 .416-.142.76-.44 1.06a1.43 1.43 0 0 1-1.06.44H7Z"
      fill="#079664"
      stroke="#BCBBBB"
    />
  </svg>
);
export default SvgDeviceIcon;
