import * as React from 'react';
import { SVGProps } from 'react';
const SvgWayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.5 20.5h8.034c.176.343.37.677.581 1H6.5c-2.205 0-4-1.795-4-4s1.795-4 4-4h7c1.103 0 2-.897 2-2s-.897-2-2-2H8.315c.227-.321.436-.655.625-1h4.56c1.654 0 3 1.346 3 3s-1.346 3-3 3h-7c-1.655 0-3 1.345-3 3s1.345 3 3 3ZM5.16 9.273a6.475 6.475 0 0 1-.16.122 7.228 7.228 0 0 1-1.125-1.03C3.172 7.567 2.5 6.429 2.5 5c0-1.378 1.122-2.5 2.5-2.5S7.5 3.622 7.5 5c0 1.429-.672 2.566-1.375 3.365-.35.397-.701.703-.964.908Zm-.926-2.426a2 2 0 1 0 1.533-3.696 2 2 0 0 0-1.533 3.696ZM19.16 21.273a6.598 6.598 0 0 1-.16.122 7.222 7.222 0 0 1-1.125-1.03C17.172 19.567 16.5 18.43 16.5 17c0-1.378 1.122-2.5 2.5-2.5s2.5 1.122 2.5 2.5c0 1.429-.672 2.566-1.375 3.365-.35.397-.701.703-.964.908Zm-.926-2.425a2.001 2.001 0 1 0 1.533-3.698 2.001 2.001 0 0 0-1.533 3.697Z"
      fill="#BCBBBB"
      stroke="#BCBBBB"
    />
  </svg>
);
export default SvgWayIcon;
