import * as React from 'react';
import { SVGProps } from 'react';

const SvgItalyFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={21} height={15} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <mask
      id="italy-flag-icon_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={21}
      height={15}>
      <path d="M19 0H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h17a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z" fill="#fff" />
    </mask>
    <g mask="url(#italy-flag-icon_svg__a)">
      <path d="M19 0H2a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h17a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z" fill="#fff" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0h7v15H0V0Z" fill="#009342" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14 0h7v15h-7V0Z" fill="#D02832" />
      <path
        d="M19 .5H2A1.5 1.5 0 0 0 .5 2v11A1.5 1.5 0 0 0 2 14.5h17a1.5 1.5 0 0 0 1.5-1.5V2A1.5 1.5 0 0 0 19 .5Z"
        stroke="#000"
        strokeOpacity={0.1}
      />
    </g>
  </svg>
);

export default SvgItalyFlagIcon;
