import * as React from 'react';
import { SVGProps } from 'react';
const SvgSloveniaFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#slovenia-flag-icon_svg__a)">
      <g clipPath="url(#slovenia-flag-icon_svg__b)">
        <path fill="#1A47B8" d="M.2 3h24v17H.2z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M.2 14.333h24V20H.2v-5.667Z" fill="#F93939" />
        <path fillRule="evenodd" clipRule="evenodd" d="M.2 3h24v5.667H.2V3Z" fill="#fff" />
        <path
          d="M7.629 6.4H5.343c-.631 0-1.143.507-1.143 1.133v2.834c0 .626.512 1.133 1.143 1.133h2.286c.63 0 1.142-.507 1.142-1.133V7.533c0-.626-.511-1.133-1.142-1.133Z"
          fill="#F93939"
        />
        <path
          d="M7.057 6.4H5.914c-.63 0-1.143.507-1.143 1.133V9.8c0 .626.512 1.133 1.143 1.133h1.143c.631 0 1.143-.507 1.143-1.133V7.533c0-.626-.512-1.133-1.143-1.133Z"
          fill="#1A47B8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m4.771 9.8 1.715-.567L8.2 9.8l-1.143 1.133H5.914L4.771 9.8Z"
          fill="#fff"
        />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.914 7.533h1.143v1.134H5.914V7.533Z" fill="#FFDA2C" />
      </g>
    </g>
    <defs>
      <clipPath id="slovenia-flag-icon_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
      <clipPath id="slovenia-flag-icon_svg__b">
        <path fill="#fff" transform="translate(.2 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSloveniaFlagIcon;
