import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useRef } from 'react';
import { CopyResultLinkButton } from './components';
import { gsap } from 'gsap';
import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';

type Props = {
  answerId: string | null;
};

const translationPrefix = 'net0-commute.pages.result.tips';

export const ActionButtons: FC<Props> = ({ answerId }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 700px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <ActionButtonsWrapper ref={containerRef}>
      <ButtonsGroup>
        <CopyResultLinkButton answerId={answerId} />
      </ButtonsGroup>
      <ActionsDescription>{t(translationPrefix + '.footnote')}</ActionsDescription>
    </ActionButtonsWrapper>
  );
};
const ActionButtonsWrapper = styled.div`
  margin-top: 35px;
  margin-bottom: 0px;
  order: 6;
  @media ${DEVICE.laptop} {
    margin-top: 0;
    margin-bottom: 50px;
    order: initial;
  }
`;

const ActionsDescription = styled.p`
  ${TYPOGRAPHY.captionRegular14}
  color: var(--color9);
`;

const ButtonsGroup = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  align-items: stretch;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  @media ${DEVICE.laptop} {
    grid-auto-flow: column;
    max-width: 700px;
  }

  & > button {
    padding: 3px 10px;
    max-width: auto;
    @media ${DEVICE.laptop} {
      max-width: 291px;
      min-height: 70px;
    }
  }
`;
