import { useEffect } from 'react';
import { gsap } from 'gsap';
import { StepItemProps } from '@net0-calc/common/types';
import { useGetWindowSize } from '@net0-calc/common/hooks';
import { SIZE } from '@net0-calc/common/assets/styles';

export function useAnimateStepBar<T, K>(steps: StepItemProps<T, K>[]) {
  const stepsWithoutIconCount = (idx: number) => steps.slice(0, idx + 1).filter(step => !step.icon).length;
  useEffect(() => {
    const { width } = useGetWindowSize();

    if (width >= SIZE.laptop) {
      steps.forEach((_, index) => {
        gsap.fromTo(
          '#step-' + index,
          {
            top: 0,
          },
          {
            top: (index + 1 - stepsWithoutIconCount(index)) * 100,
            // duration: 1.05,
            duration: 0,
            ease: 'power2.inOut',
          },
        );
        gsap.fromTo(
          '#step-text-' + index,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            // duration: 1.25,
            duration: 0,
            ease: 'power2.inOut',
          },
        );
      });
    } else {
      steps.forEach((_, index) => {
        gsap.fromTo(
          '#step-' + index,
          {
            left: 0,
          },
          {
            left: (index + 1 - stepsWithoutIconCount(index)) * 100 * 44,
            // duration: 0.95,
            duration: 0,
          },
        );
      });
    }
  }, [steps]);
}
