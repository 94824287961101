import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { TYPOGRAPHY, DEVICE } from '../../../../../assets/styles';
import { FC } from 'react';
import Calendar from 'react-calendar';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components/macro';

interface MobileModalProps extends FieldInputProps<[Date, Date]> {
  isVisible: boolean;
  onCloseModal: () => void;
  selectedInputLabel: string;
  locale: string;
}

export const MobileModal: FC<MobileModalProps> = ({
  isVisible,
  onCloseModal,
  selectedInputLabel,
  defaultValue,
  value,
  locale,
  onChange,
}) => {
  const container = document.body;

  return (
    <StyledDrawer container={container} anchor="bottom" onClose={onCloseModal} onOpen={() => {}} open={isVisible}>
      <Puller />
      <CalendarWrapper $isVisible={isVisible}>
        <Title>{selectedInputLabel}</Title>
        <Calendar
          prev2Label={null}
          next2Label={null}
          locale={locale}
          minDetail="month"
          selectRange
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </CalendarWrapper>
    </StyledDrawer>
  );
};

const StyledDrawer = styled(SwipeableDrawer)`
  && {
    .MuiPaper-root {
      border-radius: 16px 16px 0px 0px;
      background: var(--color5);
    }
  }
`;

const Puller = styled.div`
  position: absolute;
  top: 8px;
  left: calc(50% - 40px);
  width: 80px;
  height: 6px;
  border-radius: 5px;
  background: var(--color10);
`;

const CalendarWrapper = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};

  width: 100%;
  height: 100%;
  z-index: 500;
  overflow-y: scroll;
  padding: 16px;

  & .react-calendar {
    width: 100%;
    padding: 0px 20px 20px;
    margin-bottom: 12px;
    z-index: 200;

    &__navigation {
      display: flex;
      justify-content: space-between;

      &__prev-button {
        width: 32px;
        height: 32px;
        background-color: var(--color8);
        border-radius: 16px;
        cursor: pointer;
      }

      &__next-button {
        width: 32px;
        height: 32px;
        background-color: var(--color8);
        border-radius: 16px;
        cursor: pointer;
      }

      &__label {
        display: flex;
        justify-content: space-around;

        &__divider {
          display: none;
        }

        &__labelText {
          ${TYPOGRAPHY.bodyMedium16}
          color: var(--color6);
        }

        @media ${DEVICE.tablet} {
          justify-content: space-between;
          margin: 0 80px;
        }
      }
    }

    &__viewContainer {
      display: flex;
      flex-direction: column;

      @media ${DEVICE.tablet} {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &__month-view__weekdays {
      display: grid !important;
      grid-template-columns: repeat(7, 40px);
      grid-column-gap: 4px;
      justify-content: center;
      margin-bottom: 4px;

      &__weekday {
        ${TYPOGRAPHY.captionRegular12}
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 40px;
        height: 40px;
        & > abbr {
          text-decoration: none;
        }
      }
    }

    &__month-view__days {
      display: grid !important;
      grid-template-columns: repeat(7, 40px);
      grid-template-rows: repeat(6, 40px);
      justify-content: center;
      grid-gap: 4px;
    }

    &__tile {
      max-width: 40px;
      height: 40px;
      border: 1px solid var(--color10);
      border-radius: 4px;
      cursor: pointer;
      color: var(--color9) !important;

      &--range {
        border: 1px solid var(--color14);
        background-color: var(--color14);
        color: var(--color2) !important;
      }
      &--rangeStart {
        border: 1px solid var(--color2) !important;
        background-color: var(--color2) !important;
        color: var(--color5) !important;
      }
      &--rangeEnd {
        border: 1px solid var(--color2) !important;
        background-color: var(--color2) !important;
        color: var(--color5) !important;
      }
    }
  }
`;

const Title = styled.div`
  font-family: 'Poppins', 'Arial', sans-serif;
  ${TYPOGRAPHY.bodyMedium20}
  color: var(--color6);
  text-align: center;

  width: 100%;
  margin-top: 30px;
  margin-bottom: 32px;
`;
