import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeBusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={60} height={60} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M47.725 42.5H50a2.5 2.5 0 0 0 2.5-2.5V27.125L48.075 14.2a2.5 2.5 0 0 0-2.5-1.7H10A2.5 2.5 0 0 0 7.5 15v25a2.5 2.5 0 0 0 2.5 2.5h2.275"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M52.5 27.5h-45 45Zm-30 15a5 5 0 1 1-10 0 5 5 0 0 1 10 0Zm25 0a5 5 0 1 1-10 0 5 5 0 0 1 10 0v0Zm-24.8 0h14.65H22.7Zm-.2-30h15v15h-15v-15Z"
      stroke="#B11425"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLargeBusIcon;
