import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import styled, { css } from 'styled-components/macro';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { GreenCar, GreenEarth, GreenHospital, RightArrowIcon } from '@net0-calc/common/assets/svg';
// import { GreenCar, GreenEarth, GreenHospital, RightArrowIcon } from '@net0-calc/common/assets/svg';

gsap.registerPlugin(ScrollTrigger);

const translationPrefix = 'net0-commute.pages.result.tips';

type Props = {
  className?: string;
};

export const TipsToReduce: FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const blocksAnimationTimeLine = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        scrub: 1,
        start: 'top 900px',
        end: 'bottom bottom',
      },
    });
    blocksAnimationTimeLine
      .from(containerRef.current, {
        opacity: 0,
      })
      .to(containerRef.current, {
        opacity: 1,
        duration: 1,
      });
  }, []);

  return (
    <Root className={className} ref={containerRef}>
      <LinksWrapper>
        <Link href="https://www.lkqcorp.com/corporate-responsibility/" target="_blank">
          <StyledGreenEarth fill="var(--color2)" />
          <LinkText>{t(translationPrefix + '.links.sustainableBusinesses')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
        <Link href="https://www.lkqcorp.com/corporate-responsibility/" target="_blank">
          <StyledGreenCar />
          <LinkText>{t(translationPrefix + '.links.consciousConsumption')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
        <Link href="https://www.lkqcorp.com/corporate-responsibility/" target="_blank">
          <StyledGreenHospital />
          <LinkText>{t(translationPrefix + '.links.sustainableDevelopmentGoals')}</LinkText>
          <StyledRightArrowIcon />
        </Link>
      </LinksWrapper>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  max-width: 845px;
  margin-bottom: 40px;

  @media ${DEVICE.laptop} {
    margin-bottom: 50px;
  }
`;

const LinksWrapper = styled.div`
  max-width: 1117px;
  display: flex;
  flex-direction: column;
  @media ${DEVICE.laptop} {
    flex-direction: row;
  }
`;

const Link = styled.a`
  ${TYPOGRAPHY.bodyMedium16}

  min-width: 345px;
  max-width: 345px;
  padding: 12px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--color14);
  color: var(--color2);
  text-decoration: none;
  transition: 0.3s;

  &:not(:last-child) {
    margin: 0 0 20px 0;
  }

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.bodyMedium16}

    width: auto;
    padding: 20px;

    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  }

  &:hover {
    border: 2px solid var(--color2);
  }
`;

const LinkText = styled.p`
  flex: 1;
  width: 100%;
`;

const iconsCommonCSS = css`
  margin-right: 16px;

  path {
    fill: var(--color2);
  }

  circle {
    fill: var(--color2);
  }
`;

const StyledGreenCar = styled(GreenCar)`
  ${iconsCommonCSS}
`;

const StyledGreenHospital = styled(GreenHospital)`
  ${iconsCommonCSS}
`;

const StyledGreenEarth = styled(GreenEarth)`
  ${iconsCommonCSS}
`;

const StyledRightArrowIcon = styled(RightArrowIcon)`
  margin-left: 16px;
  stroke: var(--color3);
`;
