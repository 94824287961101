import { FieldArrayWrapper } from '@net0-calc/common/components';
import {
  BusinessTravelAdvancedFiledArrayValues,
  CalculatorFormValues,
  TravelTransportType,
  TypeOfTrip,
} from 'common/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { selectors, useAppSelector } from 'store';
import { TripItem } from './components';
import { css } from 'styled-components/macro';
import { DEVICE } from '@net0-calc/common/assets/styles';

export const AdvancedForm: FC<Pick<CalculatorFormValues, 'advancedBusinessTravelValues'>> = ({
  advancedBusinessTravelValues,
}) => {
  const { t } = useTranslation();
  const locale = useAppSelector(selectors.dictionary.selectLanguage);

  return (
    <FieldArrayWrapper<BusinessTravelAdvancedFiledArrayValues>
      closeButtonCSS={DeleteCSS}
      fieldsArrayName="advancedBusinessTravelValues"
      addBtnTitle={t('net0-commute.pages.businessTravel.advancedForm.addFieldsButton')}
      isAddButtonAlwaysRendered
      defaultValue={{
        businessTravelTransportType: TravelTransportType.AIRPLANE,
        typeOfTrip: TypeOfTrip.ONE_WAY,
      }}
      renderItem={(fieldsName, index) => {
        return (
          <TripItem
            fieldsName={fieldsName}
            fieldIndex={index}
            locale={locale}
            values={advancedBusinessTravelValues?.[index]}
          />
        );
      }}
    />
  );
};
const DeleteCSS = css`
  margin-top: 95px;
  @media screen and ${DEVICE.laptop} {
    margin-top: 95px;
  }
`;
