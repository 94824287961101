import { DEVICE } from '@net0-calc/common/assets/styles';
import { Input, QuestionTitle, StepInStepItem } from '@net0-calc/common/components/ui';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';
import * as validators from 'validators';
import { CalculatorFormValues } from 'common/types';

const translationPrefix = 'net0-commute.pages.workFormHome.daysPerWeekWorkFromHome';

export const DaysPerWeek: FC<Pick<CalculatorFormValues, 'numberOfDaysOfWeekWhenAnEmployeeCommuteToWork'>> = ({
  numberOfDaysOfWeekWhenAnEmployeeCommuteToWork = 0,
}) => {
  const { t } = useTranslation();

  return (
    <Root>
      <StyledStepInStepItem stepNumber={1} />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <Field
        name="daysPerWeekWorkFromHome"
        type="text"
        parse={formParsers.toNumber}
        format={formFormatters.numberToString}
        validate={combineValidators(
          validators.isNumber,
          validators.isNegativeNumber,
          validators.isRequired,
          validators.isMaxValue(7 - numberOfDaysOfWeekWhenAnEmployeeCommuteToWork),
        )}>
        {field => (
          <StyledInput
            label={t(translationPrefix + '.fields.daysPerWeekWorkFromHome.label')}
            placeholder="0"
            {...field}
          />
        )}
      </Field>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(100% - 12px);

  border-left: 2px solid var(--color14);

  padding: 0 0 40px 19px;
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    width: calc(100% - 22px);
    padding: 0 0 70px 37px;
    margin-left: 22px;
  }
`;

const StyledStepInStepItem = styled(StepInStepItem)`
  background-color: var(--color14);
`;

const StyledInput = styled(Input)`
  white-space: nowrap;

  & > div {
    margin-bottom: 0;
  }
`;
