import * as React from 'react';
import { SVGProps } from 'react';

const SvgRightArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" stroke="#202020" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5 12h14M12 5l7 7-7 7" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgRightArrowIcon;
