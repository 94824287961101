import * as React from 'react';
import { SVGProps } from 'react';

const SvgGreenCar = (props: SVGProps<SVGSVGElement>) => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#green-car_svg__a)">
      <circle cx={20} cy={20} r={20} fill="#62C962" />
      <path
        d="M18.485 13.306c-2.046.662-3.817 2.273-4.766 4.007l-.733 1.339-5.286.88a3 3 0 0 0-2.507 2.96V24a2 2 0 0 0 2 2h26.483c.731 0 1.324-.593 1.324-1.325v-2.039c0-1.924-1.617-3.528-3.497-3.86a1.482 1.482 0 0 1-1.02-.713c-.59-1.037-1.765-2.458-2.611-2.956"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12} cy={26} r={2.875} fill="#62C962" stroke="#fff" />
      <circle cx={12} cy={26} r={0.948} fill="#62C962" stroke="#fff" />
      <circle cx={28.86} cy={26} r={2.875} fill="#62C962" stroke="#fff" />
      <circle cx={28.86} cy={26} r={0.948} fill="#62C962" stroke="#fff" />
      <path
        d="M20.293 15.765c-.842-3.553 1.397-6.905 5-7.487l1.632-.264.382 1.608c.842 3.553-1.397 6.905-5.001 7.487l-1.632.264-.38-1.608Z"
        fill="#62C962"
        stroke="#fff"
        strokeLinejoin="round"
      />
      <path d="M20.204 19.852c.707-3.86 1.684-5.64 4.144-8.343" stroke="#fff" strokeLinecap="round" />
      <path
        d="M18.736 19.227a.5.5 0 0 0 0-1v1Zm-6.26 0h6.26v-1h-6.26v1ZM22.234 18.227a.5.5 0 1 0 0 1v-1Zm0 1h9.133v-1h-9.133v1Z"
        fill="#fff"
      />
      <path stroke="#fff" strokeLinecap="round" d="M17.586 20.732h.829M5.693 22.125h1.441" />
    </g>
    <defs>
      <clipPath id="green-car_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgGreenCar;
