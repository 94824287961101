import * as React from 'react';
import { SVGProps } from 'react';

const SvgLargeWalkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    stroke="#B11425"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M25.5629 33.788C24.8951 32.8341 24.4594 32.1277 24.4594 31.9082C24.4594 30.9682 24.9294 23.9188 24.4594 23.9188C23.9894 23.9188 22.5795 25.7986 22.5795 25.7986L20.2297 33.3181C20.2297 33.3181 18.8198 34.728 17.4099 34.258C16 33.788 16 31.9082 16 31.9082L18.8198 22.0389C18.8198 22.0389 26.3393 16.3993 26.8092 16.3993C27.2792 16.3993 31.0389 16.8693 31.5089 16.8693C31.9789 16.8693 37.6185 25.3287 37.6185 25.3287L42.7881 28.6184C42.7881 28.6184 43.728 29.5583 42.7881 31.4382C41.8481 33.3181 40.4383 32.3781 40.4383 32.3781L33.8587 28.6184C33.8587 28.6184 32.4488 25.7986 31.9789 25.7986C31.5089 25.7986 31.9789 32.8481 31.9789 32.8481C31.9789 32.8481 37.6185 39.4276 38.0884 40.3676C38.5584 41.3075 39.4983 50.7068 39.4983 50.7068C39.4983 50.7068 39.4983 53.0567 37.1485 53.5266C34.7987 53.9966 33.8587 52.1167 33.8587 52.1167L32.4488 42.7174C32.4488 42.7174 27.7551 36.9192 25.5629 33.788ZM25.5629 33.788L23.9894 40.3676L17.4099 50.7068C17.4099 50.7068 17.4099 52.5867 18.8198 53.5266C20.2297 54.4666 22.1096 53.5266 22.1096 53.5266C22.1096 53.5266 29.1591 43.6573 29.629 42.7174C30.099 41.7775 29.629 39.1737 29.629 39.1737"
      strokeWidth="2"
    />
    <path
      d="M26.548 7.79204C27.3209 7.27563 28.2295 7 29.159 7C30.4055 7 31.6008 7.49514 32.4822 8.3765C33.3636 9.25786 33.8587 10.4532 33.8587 11.6997C33.8587 12.6292 33.5831 13.5378 33.0667 14.3107C32.5503 15.0835 31.8163 15.6859 30.9575 16.0416C30.0988 16.3973 29.1538 16.4904 28.2422 16.309C27.3305 16.1277 26.4931 15.6801 25.8359 15.0228C25.1786 14.3656 24.731 13.5282 24.5497 12.6165C24.3683 11.7049 24.4614 10.7599 24.8171 9.90118C25.1728 9.04243 25.7752 8.30844 26.548 7.79204Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgLargeWalkIcon;
