import { Option } from 'types';
import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { SelectInput } from '../SelectInput';
import { TYPOGRAPHY } from '../../../assets/styles';

export interface SelectFieldProps extends FieldRenderProps<string> {
  label?: string;
  className?: string;
  options?: Option[];
  disabled?: boolean;
  onCloseMenu?: () => void;
  onAfterChange?: (value: string | string[]) => void;
  withDropdownIndicator?: boolean;
  customStylesProps?: any;
}

const SelectField: FC<SelectFieldProps> = ({
  label,
  input,
  meta,
  className,
  options,
  disabled,
  onCloseMenu,
  autoFocus,
  placeholder,
  isSearchable = false,
  withDropdownIndicator = true,
  onAfterChange,
  customStylesProps,
}) => {
  const { error, submitError, touched, dirtySinceLastSubmit, data } = meta;
  const hasError = ((error || submitError) && touched && !dirtySinceLastSubmit) || data?.error;

  return (
    <Root className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <SelectInput
        {...input}
        className="select-input"
        options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={options?.find(({ value }) => value === input.value)}
        isSearchable={isSearchable}
        onCloseMenu={onCloseMenu}
        hasError={hasError}
        customStylesProps={customStylesProps}
        withDropdownIndicator={withDropdownIndicator}
        value={input.value}
        onAfterChange={onAfterChange}
      />
      {hasError && <ErrorText>{meta.error || submitError || data?.error}</ErrorText>}
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  position: relative;
`;

const StyledLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;

const ErrorText = styled.span`
  ${TYPOGRAPHY.captionRegular12}
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  color: var(--color12);
`;

export default SelectField;
