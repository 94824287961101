export interface ButtonStyles {
  primary: (isLoading?: boolean) => string;
  secondary: (isLoading?: boolean) => string;
  ternary: (isLoading?: boolean) => string;
}
export const buttonStyles: ButtonStyles = {
  primary: isLoading => `
    color: var(--color5);
    border: none;
    background-color: var(--color2);

    &:hover {
      background-color: var(--color1);
    }


    ${
      isLoading
        ? ``
        : `
            &:disabled {
              color: var(--color7);
              background-color: var(--color8);
            }
          `
    }
  `,
  secondary: isLoading => `
    color: var(--color1);
    border: 2px solid var(--color4);
    background-color: var(--color5);

    &:hover {
      border-color: var(--color4);
      background-color: var(--color14);
    }


    ${
      isLoading
        ? ``
        : `
          &:disabled {
            color: var(--color7);
            border: 2px solid var(--color9);
            background-color: var(--color8);
        }
    }`
    }
  `,
  ternary: () => `
    color: var(--color9);
      border: none;
      background-color: var(--color5);
  
      &:hover {
        border-color: var(--color);
      }
  
      &:disabled {
        color: var(--color7);
        border: 2px solid var(--color9);
        background-color: var(--color8);
      }
  `,
};
