import * as React from 'react';
import { SVGProps } from 'react';

const SvgBadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 29.334c7.364 0 13.333-5.97 13.333-13.334 0-7.363-5.97-13.333-13.333-13.333C8.636 2.667 2.667 8.637 2.667 16c0 7.364 5.97 13.334 13.333 13.334ZM16.167 12v8.333"
      stroke="#62C962"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20.333 16.167-4.166 4.167L12 16.167"
      stroke="#62C962"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgBadIcon;
