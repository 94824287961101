import { SVGProps } from 'react';

const CopyLinkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    stroke="#B11425"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.5 9.5C15.1619 10.1636 15.5337 11.0627 15.5337 12C15.5337 12.9373 15.1619 13.8364 14.5 14.5L9.5 19.5C8.83638 20.1619 7.93732 20.5337 7 20.5337C6.06268 20.5337 5.16362 20.1619 4.5 19.5C3.83805 18.8364 3.46631 17.9373 3.46631 17C3.46631 16.0627 3.83805 15.1636 4.5 14.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 9.5C20.1619 8.83638 20.5337 7.93732 20.5337 7C20.5337 6.06268 20.1619 5.16362 19.5 4.5C18.8364 3.83805 17.9373 3.46631 17 3.46631C16.0627 3.46631 15.1636 3.83805 14.5 4.5L9.5 9.5C8.83805 10.1636 8.46631 11.0627 8.46631 12C8.46631 12.9373 8.83805 13.8364 9.5 14.5V14.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CopyLinkIcon;
