import { TYPOGRAPHY, DEVICE } from '../../../assets/styles';
import { CrossIcon } from '../../../assets/svg';
import React, { FC, ReactNode, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components/macro';

interface InputProps extends FieldRenderProps<string | number | undefined> {
  className?: string;
  label?: ReactNode;
  disabled?: boolean;
  placeholder?: string;
  patter?: string;
  hideError?: boolean;
}

export const Input: FC<InputProps> = ({ className, label, disabled, placeholder, input, meta, pattern, hideError }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputWrapRef = useRef<HTMLDivElement>(null);

  const hasError = meta.touched && meta.error && !hideError;

  const handleInputRootClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  const handleClear = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    input.onChange('');
  };

  const onWheel = (e: React.WheelEvent<HTMLInputElement>) => {
    if (input.type === 'number') {
      e.currentTarget.blur();
    }
  };

  return (
    <Root className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <InputWrap ref={inputWrapRef} $hasError={hasError} onClick={handleInputRootClick}>
        <StyledInput
          ref={inputRef}
          pattern={pattern}
          {...input}
          disabled={disabled}
          placeholder={placeholder}
          onWheel={onWheel}
        />
        {!!input.value && !disabled && (
          <ClearButton tabIndex={-1} type="button" onClick={handleClear}>
            <CrossIcon />
          </ClearButton>
        )}
        {hasError && <ErrorText>{meta.error}</ErrorText>}
      </InputWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  ${TYPOGRAPHY.bodyRegular16}
  width: 80%;
  display: block;
  color: var(--color6);
  outline: none;
`;

const InputWrap = styled.div<{ $hasError: boolean }>`
  position: relative;
  width: 100%;
  height: 64px;
  padding: 20px;

  border-radius: 15px;
  border: 2px solid var(--color10);
  ${({ $hasError }) =>
    $hasError &&
    `border: 2px solid var(--color12);
  `}

  background-color: var(--color5);
  cursor: text;
  transition: all 0.2s;
  margin-bottom: 20px;

  &:focus-within {
    border: 2px solid var(--color2);
  }

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const StyledLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
  white-space: pre-line;
`;

const ClearButton = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;

const ErrorText = styled.span`
  ${TYPOGRAPHY.captionRegular12}
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  color: var(--color12);
`;
