import { DEVICE, TYPOGRAPHY } from '../../../assets/styles';
import React from 'react';
import styled from 'styled-components';

type Props = {
  stepNumber: number;
  className?: string;
  isLastStep?: boolean;
};

export const StepInStepItem: React.FC<Props> = ({ stepNumber, className, isLastStep }) => {
  return (
    <Root $isLastStep={isLastStep} className={className}>
      {stepNumber}
    </Root>
  );
};

const Root = styled.div<{ $isLastStep?: boolean }>`
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color13);
  border-radius: 100%;
  background-color: var(--color15);
  overflow: hidden;
  z-index: 202;
  left: ${({ $isLastStep }) => ($isLastStep ? '-11px' : '-13px')};

  ${TYPOGRAPHY.captionMedium12}

  @media ${DEVICE.laptop} {
    width: 44px;
    height: 44px;
    left: ${({ $isLastStep }) => ($isLastStep ? '-21px' : '-23px')};
    ${TYPOGRAPHY.bodySemibold18}
  }
`;
