import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { FC } from 'react';
import { FieldInputProps } from 'react-final-form';
import styled from 'styled-components';

interface DropdownOption {
  title: string;
  value: string;
}

interface DropdownInterface extends FieldInputProps<string> {
  className?: string;
  label: string;
  options: DropdownOption[];
}

export const Dropdown: FC<DropdownInterface> = ({ label, className, options, inputRef, ...rest }) => {
  return (
    <Root className={className}>
      <StyledLabel>{label}</StyledLabel>
      <InputWrap>
        <StyledSelect ref={inputRef} {...rest}>
          {options.map(({ title, value }) => (
            <option key={value} value={value}>
              {title}
            </option>
          ))}
        </StyledSelect>
      </InputWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-top: 24px;

  @media ${DEVICE.laptop} {
    margin-top: 8px;
  }
`;

const StyledLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;

const StyledSelect = styled.select`
  ${TYPOGRAPHY.bodyRegular16}
  color: var(--color6);
  outline: none;

  position: relative;
  width: 100%;
  height: 64px;
  padding: 18px;

  border-radius: 15px;
  border: 2px solid var(--color10);

  background-color: var(--color5);

  transition: all 0.2s;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }

  &:focus-within {
    border: 2px solid var(--color2);
  }
`;

const InputWrap = styled.div``;
