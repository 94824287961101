import { DEVICE } from '@net0-calc/common/assets/styles';
import { Hints, Input, QuestionTitle, RadioButton, StepInStepItem } from '@net0-calc/common/components/ui';
import { CalculatorFormValues, RenewableAtHomeAnswer } from 'common/types';
import React, { FC } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as formFormatters from '@net0-calc/common/form-utils/formatters';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { combineValidators } from 'validators/combineValidators';
import * as validators from 'validators';

const translationPrefix = 'net0-commute.pages.workFormHome.renewableElectricity';
const optionsTranslationPrefix = translationPrefix + '.fields.renewableElectricityAtYourHome.radioOption';

export const RenewableElectricity: FC<Pick<CalculatorFormValues, 'haveRenewableElectricityAtYourHome'>> = ({
  haveRenewableElectricityAtYourHome,
}) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t(optionsTranslationPrefix + '.yes'),
      value: RenewableAtHomeAnswer.YES,
    },
    {
      label: t(optionsTranslationPrefix + '.yesPartially'),
      value: RenewableAtHomeAnswer.YES_PARTIALLY,
    },
    {
      label: t(optionsTranslationPrefix + '.no'),
      value: RenewableAtHomeAnswer.NO,
    },
    {
      label: t(optionsTranslationPrefix + '.dontNow'),
      value: RenewableAtHomeAnswer.DONT_NOW,
    },
  ];

  return (
    <Root>
      <StyledStepInStepItem isLastStep stepNumber={2} />
      <QuestionTitle>{t(translationPrefix + '.title')}</QuestionTitle>
      <RadioGroup>
        {options.map(({ value, label }) => {
          return (
            <Field
              key={value}
              name="haveRenewableElectricityAtYourHome"
              type="radio"
              validate={validators.isRequired}
              value={value}>
              {({ input }) => {
                if (
                  haveRenewableElectricityAtYourHome &&
                  haveRenewableElectricityAtYourHome === RenewableAtHomeAnswer.YES_PARTIALLY &&
                  value === RenewableAtHomeAnswer.YES_PARTIALLY
                ) {
                  return (
                    <>
                      <RadioButton
                        key={value}
                        text={label}
                        {...input}
                        type="checkbox"
                        onChange={event => {
                          const { value } = event.currentTarget;
                          input.onChange(value);
                        }}
                      />

                      <Field
                        name="percentageOfRenewableElectricityAtYourHome"
                        type="text"
                        parse={formParsers.toNumber}
                        format={formFormatters.numberToString}
                        validate={combineValidators(
                          validators.isNumber,
                          validators.isNegativeNumber,
                          validators.isRequired,
                          validators.isMaxValue(100),
                        )}>
                        {field => (
                          <Input
                            label={t(translationPrefix + '.fields.percentageOfRenewableElectricity.label')}
                            placeholder="0%"
                            {...field}
                          />
                        )}
                      </Field>
                    </>
                  );
                }

                return (
                  <RadioButton
                    key={value}
                    text={label}
                    {...input}
                    type="checkbox"
                    onChange={event => {
                      const { value } = event.currentTarget;
                      input.onChange(value);
                    }}
                  />
                );
              }}
            </Field>
          );
        })}
      </RadioGroup>
      <Hints hints={[t(translationPrefix + '.hint')]} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  width: calc(100% - 12px);

  padding-left: 19px;
  margin-left: 12px;

  @media ${DEVICE.laptop} {
    padding-left: 37px;
    margin-left: 22px;
    width: calc(100% - 22px);
  }
`;

const StyledStepInStepItem = styled(StepInStepItem)`
  top: 0;
  left: -3.4%;
  background-color: var(--color14);
`;

const RadioGroup = styled.div`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
