import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { CrossIcon } from '@net0-calc/common/assets/svg';
import { MapPoint, ToastService } from '../../types';
import React, { FC, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import styled from 'styled-components/macro';
import usePlacesAutocomplete, { getGeocode, getLatLng, Suggestion } from 'use-places-autocomplete';

export type SearchAddressInputValue = { mainAddress: string; fullAddress: string; mapPoint: MapPoint };

interface SearchAddressInputProps extends FieldRenderProps<SearchAddressInputValue> {
  className?: string;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  toast: ToastService;
}

export const SearchAddressInput: FC<SearchAddressInputProps> = ({ className, label, placeholder, input, toast }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const placesAutocompleter = usePlacesAutocomplete({
    defaultValue: input.value.fullAddress,
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.currentTarget.value;
    placesAutocompleter.setValue(targetValue);
  };

  const handleInputRootClick = () => {
    inputRef.current && inputRef.current.focus();
  };

  const onSelectItem = (suggestion: Suggestion) => async () => {
    inputRef.current && inputRef.current.blur();

    const value = suggestion.description;

    placesAutocompleter.setValue(suggestion.description, false);
    placesAutocompleter.clearSuggestions();

    try {
      const [addressGeocode] = await getGeocode({ address: value });
      const mapPoint = await getLatLng(addressGeocode);

      input.onChange({
        ...input.value,
        mainAddress: suggestion.structured_formatting.main_text,
        fullAddress: addressGeocode.formatted_address,
        mapPoint: mapPoint,
      });
    } catch (error) {
      toast.error((error as Error)?.message);
    }
  };

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    placesAutocompleter.setValue('');
    input.onChange({ adderss: undefined, mapPoint: undefined });
  };

  const { suggestions } = placesAutocompleter;
  return (
    <Root className={className}>
      <StyledLabel>{label}</StyledLabel>
      <InputWrap onClick={handleInputRootClick}>
        <StyledInput
          ref={inputRef}
          value={placesAutocompleter.value}
          onChange={handleChange}
          disabled={!placesAutocompleter.ready}
          placeholder={placeholder}
          autoComplete="off"
        />
        {placesAutocompleter.value && (
          <ClearButton tabIndex={-1} type="button" onClick={handleClear}>
            <CrossIcon />
          </ClearButton>
        )}
        {placesAutocompleter.suggestions.status === 'OK' && (
          <SearchResultList>
            {suggestions.data.length === 0 && <SearchEmptyResultItem>No results</SearchEmptyResultItem>}
            {suggestions.data.map(suggestion => {
              const {
                place_id,
                structured_formatting: { main_text, secondary_text },
              } = suggestion;

              return (
                <SearchResultListItem key={place_id} onClick={onSelectItem(suggestion)}>
                  <strong>{main_text}</strong> <small>{secondary_text}</small>
                </SearchResultListItem>
              );
            })}
          </SearchResultList>
        )}
      </InputWrap>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  ${TYPOGRAPHY.bodyRegular16}
  width: 80%;
  color: var(--color6);
  outline: none;
`;

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  height: 64px;
  padding: 18px;

  border-radius: 15px;
  border: 2px solid var(--color10);

  background-color: var(--color5);

  cursor: text;
  transition: all 0.2s;
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    margin-bottom: 0;
  }

  &:focus-within {
    border: 2px solid var(--color2);
  }
`;

const SearchResultList = styled.ul`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  z-index: 500;
  border-radius: 8px;
  box-shadow: 0px 5px 10px var(--color10);

  width: 100%;
  max-height: 230px;
  background-color: var(--color5);

  margin: 0;
  padding: 0;
  overflow: auto;
`;

const SearchResultListItem = styled.li`
  ${TYPOGRAPHY.bodyMedium20};
  color: var(--color6);

  width: 100%;
  padding: 16px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color10);
  }

  &:hover {
    background-color: var(--color8);
  }
`;

const SearchEmptyResultItem = styled.li`
  ${TYPOGRAPHY.bodyMedium20};
  color: var(--color6);
  width: 100%;
  padding: 16px;
  text-align: center;
`;

const StyledLabel = styled.label`
  ${TYPOGRAPHY.captionRegular14};
  display: block;
  margin-bottom: 8px;
  color: var(--color9);
`;

const ClearButton = styled.button`
  position: absolute;
  top: 18px;
  right: 18px;
  cursor: pointer;
`;
