import { DEVICE } from '@net0-calc/common/assets/styles';
import { WizardPageProps } from '@net0-calc/common/types';
import { CalculatorFormValues } from 'common/types';
import React, { FC } from 'react';
import styled from 'styled-components';
import { DaysPerWeek, RenewableElectricity } from './components';

export const WorkFormHome: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  return (
    <Root>
      <DaysPerWeek
        numberOfDaysOfWeekWhenAnEmployeeCommuteToWork={values?.numberOfDaysOfWeekWhenAnEmployeeCommuteToWork}
      />
      <RenewableElectricity haveRenewableElectricityAtYourHome={values?.haveRenewableElectricityAtYourHome} />
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${DEVICE.tablet} {
    margin-bottom: 70px;
  }
`;
