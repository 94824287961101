import styled from 'styled-components/macro';
import React, { FC, ReactNode, useEffect } from 'react';
import gsap from 'gsap';
import { TYPOGRAPHY } from '@net0-calc/common/assets/styles';

interface ChipRadioButton extends React.InputHTMLAttributes<HTMLInputElement> {
  children: ReactNode;
}

export const ChipRadioButton: FC<ChipRadioButton> = ({ id, className, checked, children, ...props }) => {
  useEffect(() => {
    if (checked) {
      gsap.fromTo(
        '#chip-radio-anim-element-' + id,
        {
          transform: 'translate(-10%, -25%)',
          scaleX: 1,
          scaleY: 1,
        },
        {
          transform: 'translate(-100%, -50%)',
          scaleX: 0,
          scaleY: 0,
          duration: 1,
        },
      );
    }
  }, [checked]);

  return (
    <Root className={className} $isChecked={checked}>
      <AnimationElement id={'chip-radio-anim-element-' + id} />
      <Label tabIndex={0}>
        <StyledInput tabIndex={-1} id={id} type="radio" {...props} />
        <Title $isChecked={checked}>{children}</Title>
      </Label>
    </Root>
  );
};

const Root = styled.div<{ $isChecked?: boolean }>`
  position: relative;
  height: 44px;
  cursor: pointer;

  border-radius: 20px;
  border: 1px solid ${({ $isChecked }) => ($isChecked ? 'var(--color2)' : 'var(--color14)')};
  z-index: 0;
  background-color: ${({ $isChecked }) => ($isChecked ? 'var(--color2)' : 'var(--color5)')};
  overflow: hidden;
  transition: border-color 1.5s;
`;

const AnimationElement = styled.div`
  position: absolute;
  transform: translate(-25%, -25%);
  width: 100px;
  height: 70px;
  background-color: var(--color5);
  border-radius: 50%;
`;

const Label = styled.label`
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 8px 23px;
`;

const StyledInput = styled.input`
  position: absolute !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip-path: inset(100%) !important;
  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
  outline: none;
`;

const Title = styled.div<{ $isChecked?: boolean }>`
  ${TYPOGRAPHY.bodyMedium16}
  display: flex;
  align-items: center;
  color: ${({ $isChecked }) => ($isChecked ? 'var(--color5)' : 'var(--color1)')};

  & svg path {
    stroke: ${({ $isChecked }) => ($isChecked ? 'var(--color5)' : 'var(--color1)')};
    transition: stroke 1s;
  }

  transition: color 1s;
`;
