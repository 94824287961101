import 'dayjs/locale/de';
import 'dayjs/locale/it';
import 'dayjs/locale/fr';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';
import { StorageService } from '../services';
import { Language } from '../types';

export class TimeUtil {
  static validate(param: string, format: string) {
    const currentDate = dayjs(param, format);
    const isValid = currentDate.format(format) === param;
    if (!isValid) {
      throw new Error(
        `Invalid date string ${param} for format ${format}. Date to compare: ${currentDate.toISOString()}`,
      );
    }
  }
  static getCurrentLocale() {
    return StorageService.getItem('language') || Language.ENGLISH;
  }

  static getStartOFNowDay() {
    const locale = this.getCurrentLocale();
    return dayjs().locale(locale).startOf('date');
  }

  static parse(param: string, format: string) {
    this.validate(param, format);
    const locale = this.getCurrentLocale();
    return dayjs(param).locale(locale);
  }

  static now() {
    const locale = this.getCurrentLocale();
    return dayjs().locale(locale);
  }

  static getYear(date: string | Date) {
    return dayjs(date).year();
  }

  static getMonth(date: string | Date) {
    return dayjs(date).month();
  }

  static getDate(date: string | Date) {
    return dayjs(date).date();
  }

  static getDay(date: string | Date) {
    return dayjs(date).day();
  }

  static getFormatted(date: string | Date, format?: string) {
    const locale = this.getCurrentLocale();
    return dayjs(date).locale(locale).format(format);
  }

  static getDiffFromNow(date: string | Date, unit?: QUnitType | OpUnitType) {
    const now = new Date();
    return dayjs(date).diff(now, unit);
  }

  static getLastNYears(n: number) {
    const currentYear = dayjs().year();
    const lastNYears = [];

    for (let i = n - 1; i >= 0; i--) {
      lastNYears.push(currentYear - i);
    }

    return lastNYears;
  }

  static getDaysCountInYear(year: number): number {
    const yearStart = dayjs(`${year}-01-01`);
    const yearEnd = dayjs(`${year}-12-31`);

    return yearEnd.diff(yearStart, 'day') + 1;
  }
}
