import { DEVICE, TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Input, SelectField, StepTitle } from '@net0-calc/common/components/ui';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Field, FieldRenderProps } from 'react-final-form';
import * as validators from 'validators';
import { SearchAddressInput, SearchAddressInputValue } from '@net0-calc/common/components/SearchAddressInput';
import { toast } from 'services';
import { combineValidators } from 'validators/combineValidators';
import * as formParsers from '@net0-calc/common/form-utils/parsers';
import { TimeUtil } from '@net0-calc/common/utils';
import { WizardPageProps } from '@net0-calc/common/types';
import { CalculatorFormValues } from 'common/types';

const translationPrefix = 'net0-commute.pages.informationPage';
const yearOptions = TimeUtil.getLastNYears(4).map(year => ({ label: year.toString(), value: year }));

export const InformationPage: FC<WizardPageProps<CalculatorFormValues>> = ({ values }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <StyledStepTitle>{t(translationPrefix + '.title')}</StyledStepTitle>
      <Subtitle>{t(translationPrefix + '.subtitle')}</Subtitle>
      <Field name="userEmail" validate={combineValidators(validators.isEmail, validators.isRequired)}>
        {field => (
          <StyledInput
            label={t(translationPrefix + '.fields.email.label')}
            placeholder={t(translationPrefix + '.fields.email.placeholder')}
            {...field}
          />
        )}
      </Field>
      <Field<SearchAddressInputValue> name="userLocation" validate={validators.isRequired}>
        {(field: FieldRenderProps<SearchAddressInputValue>) => (
          <StyledAddressInput
            label={t(translationPrefix + '.fields.country.label')}
            placeholder={t(translationPrefix + '.fields.country.placeholder')}
            toast={toast}
            {...field}
          />
        )}
      </Field>
      <Field name="userReportYear" validate={validators.isRequired}>
        {field => (
          <StyledSelectField
            {...field}
            options={yearOptions}
            label={t(translationPrefix + '.fields.reportYear.label')}
          />
        )}
      </Field>
      <Field
        name="numberOfAnnuallyVacationDays"
        type="text"
        parse={formParsers.toNumber}
        validate={combineValidators(
          validators.isNumber,
          validators.isRequired,
          validators.isNegativeNumber,
          validators.isMaxValue(values?.userReportYear ? TimeUtil.getDaysCountInYear(values.userReportYear) : 365),
        )}>
        {field => (
          <StyledInput
            label={t(translationPrefix + '.fields.numberOfVacationDays.label')}
            placeholder={t(translationPrefix + '.fields.numberOfVacationDays.placeholder')}
            {...field}
          />
        )}
      </Field>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
    height: auto;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const StyledStepTitle = styled(StepTitle)`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 70px;
  }
`;

const Subtitle = styled.h2`
  ${TYPOGRAPHY.titleSemibold24}
  color: var(--color6);
  margin-bottom: 24px;

  @media ${DEVICE.laptop} {
    ${TYPOGRAPHY.titleSemibold32}

    margin-bottom: 32px;
  }
`;

const StyledInput = styled(Input)`
  white-space: nowrap;
`;

const StyledAddressInput = styled(SearchAddressInput)`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;

const StyledSelectField = styled(SelectField)`
  margin-bottom: 20px;

  @media ${DEVICE.laptop} {
    margin-bottom: 24px;
  }
`;
