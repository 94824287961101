import * as React from 'react';
import { SVGProps } from 'react';
const SvgTaiwanFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#taiwan-flag-icon_svg__a)">
      <mask
        id="taiwan-flag-icon_svg__b"
        style={{
          maskType: 'luminance',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={3}
        width={24}
        height={17}>
        <path d="M0 3h24v17H0V3Z" fill="#fff" />
      </mask>
      <g mask="url(#taiwan-flag-icon_svg__b)">
        <path d="M0 3h27v17H0V3Z" fill="red" />
        <path d="M0 3h13.5v8.5H0V3Z" fill="#000095" />
        <path
          d="m5.775 7.494-.094.34.353.092-.068-.251-.191-.18Zm1.759.181-.068.251.353-.092-.094-.34-.191.181Zm-1.568-.85-.191.18.071.245.12-.425Z"
          fill="#fff"
        />
        <path d="m5.846 7.25-.071.244.191.181-.12-.425Z" fill="#fff" />
        <path
          d="m5.775 7.006-.259.244.259.244.071-.244-.071-.244Zm.525.984.191.18.259-.066-.45-.114Zm.709.18.36.09.097-.334-.266.064-.191.18Z"
          fill="#fff"
        />
        <path
          d="m7.2 7.99.266-.064.068-.251-.334.315Zm-1.166-.064.097.333.36-.088-.191-.18-.266-.065Zm.716.178.259.067.191-.18-.45.113Z"
          fill="#fff"
        />
        <path
          d="m6.491 8.17.259.245.259-.244-.259-.067-.259.067Zm-.457-1.596-.353.092.094.34.191-.181.068-.251Zm-.068 1.101.068.251.266.064-.334-.315Zm1.688-.425.071-.244-.191-.181.12.425ZM6.3 6.51l-.266.064-.068.251.334-.315Zm1.234.315-.068-.251L7.2 6.51l.334.315Zm.191.67.259-.245-.259-.244-.071.244.071.244Z"
          fill="#fff"
        />
        <path
          d="M7.534 6.825 7.2 6.51l-.45-.114-.45.114-.334.315-.12.425.12.425.334.315.45.114.45-.114.334-.315.12-.425-.12-.425Z"
          fill="#fff"
        />
        <path
          d="m7.534 7.675.191-.18-.071-.245-.12.425Zm.191-.67.094-.34-.353-.091.068.251.191.18Zm-.259-.431L7.37 6.24l-.36.088.191.18.266.065Zm-.975-.245-.36-.088-.097.333.266-.064.191-.18Zm.259.067-.259-.067-.191.18.45-.113Z"
          fill="#fff"
        />
        <path d="m7.009 6.33-.259-.245-.259.244.259.067.259-.067Z" fill="#fff" />
        <path
          d="m7.2 6.51-.191-.18-.259.066.45.114Zm-.709 1.66-.36.09.169.587.45-.432-.259-.244Zm-.191.677.45 1.59.45-1.59-.45-.432-.45.432Zm-.266-.92-.353-.093-.165.581.615-.156-.097-.333Z"
          fill="#fff"
        />
        <path
          d="m5.516 8.415-.45 1.598L6.3 8.846l-.169-.588-.615.156Zm-.45-.74L3.825 8.847l1.691-.432.165-.58-.615-.16Z"
          fill="#fff"
        />
        <path
          d="m5.775 7.494-.259-.244-.45.425.615.16.094-.34Zm0-.488-.094-.34-.615.159.45.425.259-.244Z"
          fill="#fff"
        />
        <path
          d="m5.066 6.825-1.691.425 1.691.425.45-.425-.45-.425Zm3.375.85-.622.16.165.58 1.691.432-1.234-1.172Z"
          fill="#fff"
        />
        <path
          d="m7.466 7.926-.097.333.615.156-.165-.58-.353.091Zm-.457.245-.259.244.45.432.169-.588-.36-.088Zm.716-.677.094.34.622-.159-.457-.425-.259.244Z"
          fill="#fff"
        />
        <path
          d="m7.2 8.847 1.241 1.165-.457-1.597-.615-.156-.169.588Zm.266-2.273.353.092.165-.581-.615.156.097.333Z"
          fill="#fff"
        />
        <path
          d="M8.441 6.825 9.675 5.66l-1.691.425-.165.58.622.16Zm-.457-.74.457-1.598L7.2 5.66l.169.58.615-.155Zm.457 1.59 1.684-.425-1.684-.425-.457.425.457.425Z"
          fill="#fff"
        />
        <path
          d="m7.725 7.006.259.244.457-.425-.622-.16-.094.34ZM6.3 5.66 5.066 4.487l.45 1.598.615.156.169-.581Z"
          fill="#fff"
        />
        <path d="m6.034 6.574.097-.333-.615-.156.165.58.353-.091Z" fill="#fff" />
        <path
          d="M5.516 6.085 3.825 5.66l1.241 1.165.615-.16-.165-.58Zm1.493.244.36-.088L7.2 5.66l-.45.425.259.244Z"
          fill="#fff"
        />
        <path d="m7.2 5.66-.45-1.598L6.3 5.66l.45.425.45-.425Z" fill="#fff" />
        <path d="m6.491 6.33.259-.245-.45-.425-.169.58.36.09Z" fill="#fff" />
        <path
          d="M6.75 9.06c1.058 0 1.916-.81 1.916-1.81S7.808 5.44 6.75 5.44c-1.058 0-1.916.81-1.916 1.81s.858 1.81 1.916 1.81Z"
          fill="#000095"
        />
        <path
          d="M6.75 8.847c.934 0 1.691-.715 1.691-1.597S7.684 5.653 6.75 5.653s-1.691.715-1.691 1.597.757 1.597 1.691 1.597Z"
          fill="#fff"
        />
      </g>
    </g>
    <defs>
      <clipPath id="taiwan-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTaiwanFlagIcon;
