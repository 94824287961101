import { CarIcon, DeviceIcon, HomeIcon, PersonIcon, PlaneIcon, WayIcon } from '@net0-calc/common/assets/svg';

import { CalculatorFormValues, StepItemProps, StepName, UseSAPConcurAnswer } from 'common/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BusinessTravel,
  Devices,
  GettingToOffice,
  InformationPage,
  UseOfSAPConcur,
  WelcomePage,
  WorkFormHome,
  WorkWayInfo,
} from '../components/wizard-form-pages';

const translationPrefix = 'net0-commute.steps';

type Args = {
  formValues: CalculatorFormValues;
};
export const useSteps = ({ formValues }: Args) => {
  const { t } = useTranslation();
  const [currentStepIndex, changeCurrentStepIndex] = useState(0);
  const isUsingSAPTotally = formValues.useSAPConcur === UseSAPConcurAnswer.YES;

  const stepsMap: Record<StepName, StepItemProps<CalculatorFormValues>> = {
    [StepName.WELCOME]: {
      name: StepName.WELCOME,
      title: '',
      icon: null,
      page: <WelcomePage />,
    },
    [StepName.INFORMATION]: {
      name: StepName.INFORMATION,
      title: t(translationPrefix + '.personalInformation'),
      icon: <PersonIcon />,
      page: <InformationPage />,
    },
    [StepName.GETTING_TO_OFFICE]: {
      name: StepName.GETTING_TO_OFFICE,
      title: t(translationPrefix + '.gettingToOffice'),
      icon: <CarIcon />,
      page: <GettingToOffice />,
    },
    [StepName.WORK_WAY_INFO]: {
      name: StepName.WORK_WAY_INFO,
      title: t(translationPrefix + '.workWayInfo'),
      icon: <WayIcon />,
      page: <WorkWayInfo />,
    },
    [StepName.WORK_FROM_HOME]: {
      name: StepName.WORK_FROM_HOME,
      title: t(translationPrefix + '.workFormeHome'),
      icon: <HomeIcon />,
      page: <WorkFormHome />,
    },
    [StepName.DEVICES]: {
      name: StepName.DEVICES,
      title: t(translationPrefix + '.devices'),
      icon: <DeviceIcon />,
      page: <Devices />,
    },
    [StepName.USE_OF_SAP_CONCUR]: {
      name: StepName.USE_OF_SAP_CONCUR,
      title: '',
      icon: null,
      page: <UseOfSAPConcur />,
    },
    [StepName.BUSINESS_TRAVEL]: {
      name: StepName.BUSINESS_TRAVEL,
      title: t(translationPrefix + '.businessTravel'),
      icon: <PlaneIcon />,
      page: <BusinessTravel />,
    },
  };

  const stepsNames = [
    StepName.WELCOME,
    StepName.INFORMATION,
    StepName.GETTING_TO_OFFICE,
    StepName.WORK_WAY_INFO,
    StepName.WORK_FROM_HOME,
    StepName.DEVICES,
    StepName.USE_OF_SAP_CONCUR,
    !isUsingSAPTotally && StepName.BUSINESS_TRAVEL,
  ].filter(Boolean) as StepName[];

  const steps = stepsNames.map(stepName => stepsMap[stepName]);

  const currentStep = steps[currentStepIndex];
  const currentStepName = currentStep.name;
  const isLastStep = currentStepIndex === steps.length - 1;

  return [steps, { changeCurrentStepIndex, currentStepIndex, currentStep, currentStepName, isLastStep }] as const;
};
