import { TriangleIcon } from '../../../assets/svg';
import { Option } from '../../../types';
import React, { FC, FocusEventHandler, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { customStyles } from './utils/custom-styles';
import styled, { css } from 'styled-components';
import * as utils from '../../../utils';

export interface SelectInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'defaultValue' | 'onBlur'> {
  options?: Option[];
  onChange: (value: string | string[]) => void;
  onAfterChange?: (value: string | string[]) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  defaultValue?: Option | Option[] | null;
  isSearchable?: boolean;
  hasError: boolean;
  onCloseMenu?: () => void;
  withDropdownIndicator?: boolean;
  customStylesProps?: any;
}

const SelectInput: FC<SelectInputProps> = ({
  onChange,
  onAfterChange = utils.noop,
  isSearchable = true,
  options,
  disabled,
  className,
  placeholder,
  onBlur,
  onCloseMenu,
  defaultValue = null,
  hasError = false,
  withDropdownIndicator = true,
  value,
  customStylesProps,
}) => {
  const [optionsState, setOptionsState] = useState<Option[]>(options || []);

  useEffect(() => {
    if (options?.length) {
      setOptionsState(options || []);
    }
  }, [options]);

  const handleChange = useCallback(
    (valueType: SingleValue<Option<string>>) => {
      const isSearchedOption = !optionsState.some(({ value }) => valueType?.value === value);
      isSearchedOption && setOptionsState([...optionsState, valueType as Option]);

      const newValue = (valueType as Option).value;

      onChange(newValue);
      onAfterChange(newValue);
    },
    [onAfterChange, onChange, optionsState],
  );

  const selectedOptionValue = optionsState.find(option => option.value === value);

  return (
    <Root className={className} $hasError={hasError}>
      <Select<Option, false>
        components={{
          DropdownIndicator: () => (withDropdownIndicator ? <TriangleIcon /> : null),
          ClearIndicator: () => null,
        }}
        value={selectedOptionValue}
        onChange={handleChange}
        options={options}
        styles={{ ...customStyles, ...customStylesProps }}
        placeholder={placeholder}
        isDisabled={disabled}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        onBlur={onBlur}
        onMenuClose={onCloseMenu}
        classNamePrefix="react-select"
        backspaceRemovesValue={false}
      />
    </Root>
  );
};

export default SelectInput;

const Root = styled.div<{ $hasError: boolean }>`
  ${({ $hasError }) =>
    $hasError &&
    css`
      & > [class$='container'] {
        border: 2px solid #f00;
        transition: 0.2s ease-out;
      }
    `}
`;
