import * as React from 'react';
import { SVGProps } from 'react';

const SvgClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" stroke="#B11425" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 7v5l2 3" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgClockIcon;
