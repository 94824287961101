import * as React from 'react';
import { SVGProps } from 'react';
const SvgSlovakFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#slovak-flag-icon_svg__a)">
      <path fill="#1A47B8" d="M0 3h24v17H0z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0 14.333h24V20H0v-5.667Z" fill="#F93939" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3h24v5.667H0V3Zm11.349 11.333h.08V8.667h-8v5.666h.08c-.053.184-.08.374-.08.567 0 1.564 4 2.833 4 2.833s4-1.269 4-2.833a2.06 2.06 0 0 0-.08-.567Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.286 14.333v-6.8H4.57v6.8c0 1.253 2.858 2.267 2.858 2.267s2.857-1.014 2.857-2.267Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.857 12.067v-1.134H5.714V9.8h1.143V8.667H8V9.8h1.143v1.133H8v1.134h1.714V13.2H8v1.133H6.857V13.2H5.143v-1.133h1.714Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.429 16.6s2.857-1.014 2.857-2.267c0-1.252-1.143-1.133-2.857 0-1.715-1.133-2.858-1.252-2.858 0 0 1.253 2.858 2.267 2.858 2.267Z"
        fill="#1A47B8"
      />
    </g>
    <defs>
      <clipPath id="slovak-flag-icon_svg__a">
        <path fill="#fff" transform="translate(0 3)" d="M0 0h24v17H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSlovakFlagIcon;
