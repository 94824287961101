import { UseSAPConcurAnswer } from '@pwc-commute/common/types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TYPOGRAPHY } from '@net0-calc/common/assets/styles';
import { Field } from 'react-final-form';
import React from 'react';
import { RadioButton } from '@net0-calc/common/components/ui';
import * as validators from 'validators';

export const UseOfSAPConcur = () => {
  const { t } = useTranslation();

  const usingSAPConcurOrNoOptions = [
    { label: t('net0-commute.pages.useOfSAPConcur.options.yes'), value: UseSAPConcurAnswer.YES },
    { label: t('net0-commute.pages.useOfSAPConcur.options.no'), value: UseSAPConcurAnswer.NO },
    { label: t('net0-commute.pages.useOfSAPConcur.options.partially'), value: UseSAPConcurAnswer.PARTIALLY },
  ];

  return (
    <div>
      <Label>{t('net0-commute.pages.useOfSAPConcur.title')}</Label>
      <ChipsGroup>
        {usingSAPConcurOrNoOptions.map(({ label, value }) => {
          return (
            <Field validate={validators.isRequired} key={value} name="useSAPConcur" type="radio" value={value}>
              {({ input }) => <RadioButton text={label} id={`${value}-option`} {...input}></RadioButton>}
            </Field>
          );
        })}
      </ChipsGroup>
    </div>
  );
};

const ChipsGroup = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 20px;
  margin-bottom: 70px;
`;

const Label = styled.label`
  ${TYPOGRAPHY.titleSemibold36};
  display: block;
  margin-bottom: 70px;
  color: var(--color6);
`;
